import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SalesEntryApproveEvent, SearchSalesInvoice } from '../../../types/training.type';

@Component({
  selector: 'alimento-ipv-frontend-approve-sales-invoice-dialog',
  templateUrl: './approve-sales-invoice-dialog.component.html'
})
export class ApproveSalesInvoiceDialogComponent {
  @Input()
  salesInvoice: SearchSalesInvoice;

  @Output()
  approveSalesInvoice = new EventEmitter<SalesEntryApproveEvent>();

  dialogVisible = false;
  loading = false;

  open() {
    this.dialogVisible = true;
    this.loading = false;
  }

  cancelTraining() {
    this.approveSalesInvoice.emit({
      salesInvoice: this.salesInvoice,
      setLoading: state => this.loading = state
    });
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogVisible = false;
  }
}

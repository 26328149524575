import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  FilterType,
  InfiniteScrollDataAdapter,
  PaginatedResponse,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { BranchesMapper } from '../../../utils/mapper/branches.mapper';
import { SearchEnterpriseGroupItem } from '../../../types/enterprise-group.type';
import { SearchesService } from '../../../services/searches.service';

@Component({
  selector: 'alimento-ipv-frontend-enterprise-group-list',
  templateUrl: './enterprise-group-list.component.html'
})
export class EnterpriseGroupListComponent implements OnInit, OnDestroy {
  searchData$: InfiniteScrollDataAdapter<SearchEnterpriseGroupItem>;
  searchFilters: SearchFilter[];
  private _subscription: Subscription;

  constructor(private searchesService: SearchesService,
              private branchesMapper: BranchesMapper,
              private translationService: TranslationService) {
  }

  ngOnInit(): void {
    this._subscription = this.translationService.languageChange$.subscribe(() =>
      this._createSearch()
    );
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 10,
      filters: []
    };

    this.searchData$ = new InfiniteScrollDataAdapter<SearchEnterpriseGroupItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<SearchEnterpriseGroupItem>> => {
      return this.searchesService.searchEnterpriseGroups(searchRequest);
    }, searchRequest, true);

    this.searchFilters = [
      {
        type: SearchFilterType.multiselect,
        label: 'branches.numberOfEmployees',
        key: FilterType.filterNumberEmployees,
        data: this.branchesMapper.getNumberOfEmployeesOptions()
      }
    ];
  }
}

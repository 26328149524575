import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormComponent } from '@alimento-ipv-frontend/ui-lib';
import { defaultIfEmpty, first, forkJoin, Observable, tap } from 'rxjs';
import { ReimbursementRequest } from '../../../types/reimbursement-request.type';
import { Branch, ContactPerson } from '../../../types/branch.type';
import { PersonService } from '../../../services/person.service';
import { BranchService } from '../../../services/branch.service';
import { EnterprisesService } from '../../../services/enterprises.service';
import { EnterpriseGroupService } from '../../../services/enterprise-group.service';

@Component({
  selector: 'alimento-ipv-frontend-reimbursement-request',
  templateUrl: './reimbursement-request.component.html',
  styleUrls: ['./reimbursement-request.component.scss'],
  providers: [
    { provide: FormComponent, useExisting: ReimbursementRequestComponent }
  ]
})
export class ReimbursementRequestComponent extends FormComponent implements OnChanges {
  @Input()
  reimbursementRequest: ReimbursementRequest;

  @Input()
  branch: Branch;

  contactPersonsData: ContactPerson[];
  contactPersonsReadOnly: string[] = [];

  loadingContactPersonsDone = false;
  showMoreAdministrativeRules = false;

  constructor(private formBuilder: FormBuilder,
              private branchService: BranchService,
              private personService: PersonService,
              private enterpriseService: EnterprisesService,
              private enterpriseGroupService: EnterpriseGroupService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reimbursementRequest']?.currentValue || changes['branch']?.currentValue) {
      if (!this.reimbursementRequest?.branchId || this.branch) {
        this._createForm();
      }

      if (this.formGroup && this.branch) {
        this.formGroup.get('branch').setValue(this.branch, { emitEvent: false });

        if (this.branch) {
          this._getContactPersons();
        }
      }
    }
  }

  override getData(): any {
    const data = super.getData();
    if (data.branch) {
      data.branchId = data.branch.branchId;
      delete data.branch;
    }
    data.year = new Date(data.year).getFullYear();

    data.contactPersons = [];
    if (data.contactPerson) {
      data.contactPersons.push(data.contactPerson);
    }
    delete data.contactPerson;

    if (data.contactPerson2) {
      data.contactPersons.push(data.contactPerson2);
    }
    delete data.contactPerson2;

    return data;
  }

  private _createForm(): void {
    const year = new Date();
    if (this.reimbursementRequest?.year) {
      year.setFullYear(this.reimbursementRequest?.year);
    }

    this.formGroup = this.formBuilder.group({
      year: [year, Validators.required],
      branch: [this.branch ? this.branch : this.reimbursementRequest?.branchId, Validators.required],
      contactPerson: [this.reimbursementRequest?.contactPersons?.length > 0 ? this.reimbursementRequest.contactPersons[0] : undefined],
      contactPerson2: [this.reimbursementRequest?.contactPersons?.length > 1 ? this.reimbursementRequest.contactPersons[1] : undefined],
    });

    this.subscriptions.push(
      this.formGroup.get('branch').valueChanges.subscribe(branch => {
        if (branch) {
          this._getBranchData(branch?.branchId);
        }
        else {
          this.branch = undefined;
        }
      })
    );

    super._addChangeListener();
  }

  private _getBranchData(branchId: string): void {
    this.branchService.getBranch(branchId).pipe(first())
      .subscribe({
        next: branch => {
          this.branch = branch;
          this._getContactPersons();
        }
      });
  }

  private _getContactPersons(): void {
    if (this.branch) {
      this.enterpriseService.getEnterprise(this.branch.enterpriseId).pipe(first())
        .subscribe(enterprise => {
          let contactPersonRequest;
          if (enterprise.enterpriseGroupId) {
            contactPersonRequest = this.enterpriseGroupService.getContactPersons(enterprise.enterpriseGroupId)
          }
          else {
            contactPersonRequest = this.branchService.getContactPersons(this.branch.branchId);
          }

          contactPersonRequest
            .pipe(first())
            .subscribe((contactPersons) => {
              this.contactPersonsData = contactPersons;

              const extraRequests: Observable<any>[] = [];
              this.reimbursementRequest?.contactPersons?.forEach(contactPersonId => {
                const matchingContact = this.contactPersonsData
                  .filter(contact => contact.personId === contactPersonId)[0];
                if (matchingContact) {
                  this.contactPersonsReadOnly.push(matchingContact.lastName + ' ' + matchingContact.firstName);
                }
                else {
                  extraRequests.push(
                    this.personService.getPerson(contactPersonId).pipe(
                      first(),
                      tap(person => {
                        this.contactPersonsData.push({
                          personId: person.personId,
                          firstName: person.firstName,
                          lastName: person.lastName,
                          phoneNumber: person.phoneNumber,
                          email: person.email,
                          responsibilities: []
                        });

                        this.contactPersonsReadOnly.push(person.lastName + ' ' + person.firstName);
                      })
                    )
                  );
                }
              });

              forkJoin(extraRequests).pipe(defaultIfEmpty(null))
                .subscribe(() => {
                  this.loadingContactPersonsDone = true;
                });
            });
        });
    }
  }

  toggleShowMoreAdministrativeRules(): void {
    this.showMoreAdministrativeRules = !this.showMoreAdministrativeRules;
  }

  hasOverflow(element: HTMLDivElement): boolean {
    return element.scrollHeight > element.clientHeight;
  }

  selectedContactPerson(formFieldId: string): ContactPerson {
    return this.contactPersonsData
      .filter(contactPerson => contactPerson.personId === this.formGroup.get(formFieldId).value)[0];
  }
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG_TOKEN, AppConfig } from '@alimento-ipv-frontend/ui-lib';
import { map, Observable, retry } from 'rxjs';
import { DOCUMENT_SET_TYPE } from '../types/reference-data.enum';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getDocumentCount(id: string, type: DOCUMENT_SET_TYPE): Observable<{ count: number }> {
    let count = 0;
    return this.http.post<{ count: number }>(`${this.config.readApiUrl}/documentset/document/count`, {
      entityId: id,
      type: type
    }).pipe(
      map(response => {
        if (response.count === -1 && count < 3) {
          count++;
          throw new Error();
        }
        return response;
      }),
      retry({delay: 3000}),
    );
  }

  createDocumentSet(id: string, type: DOCUMENT_SET_TYPE): Observable<{ url: string}> {
    return this.http.post<{ url: string}>(`${this.config.writeApiUrl}/documentset`, {
      entityId: id,
      type: type
    });
  }
}

import {
  AfterContentInit,
  Component,
  effect,
  HostListener,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {
  AuthorizationService,
  CanComponentDeactivate,
  ExtraMenuItem,
  FEATURE,
  FeatureFlagService,
  LeaveConfirmService,
  NOTES_TYPE,
  NotesSidebarComponent,
  Role,
  TabMenuItem,
  TitleService,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map, mergeMap, Observable, Subscription, tap } from 'rxjs';
import { PersonComponent } from '../../components/person/person.component';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { Person } from '../../../types/person.type';
import { PersonService } from '../../../services/person.service';
import { MessageService } from 'primeng/api';
import { EmploymentService } from '../../../services/employment.service';
import { DOCUMENT_SET_TYPE } from '../../../types/reference-data.enum';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'alimento-ipv-frontend-person-detail',
  templateUrl: './person-detail.component.html',
  styleUrls: ['./person-detail.component.scss']
})
export class PersonDetailComponent implements OnInit, OnDestroy, AfterContentInit, CanComponentDeactivate {
  person: WritableSignal<Person> = signal(undefined);
  personId?: string;
  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  activeTabIndex = 0;
  savingPerson = false;
  documentCount: WritableSignal<number> = signal(0);

  personFormGroup!: FormGroup;
  isNewPerson = false;
  readOnly = false;
  showNotes = false;
  PersonNote = NOTES_TYPE.PersonNote;

  private _subscriptions: Subscription[] = [];

  protected readonly FEATURE = FEATURE;

  @ViewChild(PersonComponent)
  personComponent?: PersonComponent;
  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;

  @HostListener('window:beforeunload')
  beforeUnloadHandler() {
    return !this.personComponent?.formGroup?.dirty;
  }

  constructor(
    private translateService: TranslateService,
    private translationService: TranslationService,
    private personService: PersonService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private messageService: MessageService,
    private leaveConfirmationService: LeaveConfirmService,
    private employmentService: EmploymentService,
    private authorizationService: AuthorizationService,
    private titleService: TitleService,
    private featureService: FeatureFlagService,
    private documentService: DocumentService
  ) {
    this.featureService.initialize().subscribe(() => {
      this._setMenuItems();
    });
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);

    effect(() => {
      if (this.person()) {
        this.titleService.setTitle(
          [`${this.translateService.instant('titles.persons.detail')} - ${this.person().firstName || ''} ${this.person().lastName}`]);
      }
      this._setMenuItems();
    });
  }

  ngOnInit() {
    this.route.params
      .pipe(
        map((params) => params['personId']),
        tap((personId) => (this.isNewPerson = !personId)),
        filter((personId) => !!personId),
        tap((personId) => (this.personId = personId)),
        mergeMap((personId) => this.personService.getPerson(personId))
      )
      .subscribe({
        next: (person) => {
          this.person.set(person);
          this._loadDocuments();
          this.employmentService.loadEmployments(person.personId);
        },
        error: () => {
          this.router.navigate(['error'], {
            state: {
              message: this.translateService.instant('error.itemWithIdDoesNotExist', {
                item: this.translateService.instant('persons.person'),
                id: this.personId
              }),
              redirectUrl: '/persons'
            }
          });
        }
      });

    this.route.queryParams.subscribe(
      (queryParams) => (this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0)
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.personFormGroup?.dirty) {
      return true;
    }

    return this.leaveConfirmationService.leaveDialog(() => this._savePerson());
  }

  ngAfterContentInit() {
    this.personFormGroup = this.personComponent?.formGroup;
  }

  onPersonFormChanges(formGroup: FormGroup) {
    this.personFormGroup = formGroup;
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem): void {
    if (tabMenuItem.name === 'notes') {
      this.showNotes = !this.showNotes;
    }
    else {
      this.activeTabIndex = tabMenuItem.index;
    }
    this.setDetailUrl();
  }

  private _savePerson(): Observable<{ id: string }> {
    if (this.personComponent?.isValid()) {
      const personData = this.personComponent.getData();

      const createOrUpdatePerson$ = this.personId
        ? this.personService.updatePerson(this.personId, personData)
        : this.personService.createPerson(personData);

      return createOrUpdatePerson$.pipe(first(),
        tap((response: { id: string }) => {
          this.personId = response.id;
          this.personComponent?.formGroup.markAsPristine();
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('persons.saved') + '!'
          });
          this.setDetailUrl();

          this._updatePersonInfo(this.personId);
        }));
    }
    else {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors')
      });
      return new Observable<{ id: string }>((observable) => observable.error());
    }
  }

  savePerson(): void {
    if (!this.personComponent) {
      return;
    }
    this.savingPerson = true;
    this._savePerson().subscribe({
      next: () => this.savingPerson = false,
      error: () => this.savingPerson = false
    });
  }

  private setDetailUrl(): void {
    if (this.personId) {
      this.location.replaceState(
        this.router
          .createUrlTree(['/persons', this.personId, 'detail'], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _updatePersonInfo(personId: string): void {
    if (personId) {
      this.personService.getPerson(personId).subscribe((person: Person) => {
        this.person.set(person);
        if (!this.personComponent?.formGroup?.value?.personAlimentoId) {
          this.personComponent?.formGroup.patchValue({ personAlimentoId: person.personAlimentoId });
        }
      });
    }
  }

  private _setMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.tabMenuItems = [
          { name: 'personData', index: 0, title: this.translateService.instant('persons.personDataTitle') },
          {
            name: 'employments',
            index: 1,
            title: this.translateService.instant('persons.employmentsTitle'),
            count: this.employmentService.nrOfActiveEmployments
          }
          // { name: 'trainings', index: 3, title: this.translateService.instant('persons.trainingsTitle'), disabled: true },
        ];

        if (this.featureService.featureOn(FEATURE.TRANSITION)) {
          this.tabMenuItems.push({
            name: 'transitions',
            index: 2,
            title: this.translateService.instant('persons.transitionsTitle')
            // count: this.personService.nrOfTransitions
          });
        }

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('persons.notesTitle'),
            disabled: !this.person()?.personId,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          },
          {
            name: 'documents',
            title: this.translateService.instant('documents'),
            disabled: !this.person()?.personId || !this.person()?.documentSetUrl,
            count: this.documentCount,
            command: () => {
              window.open(this.person()?.documentSetUrl, '_blank');
            }
          }
          // { name: 'history', title: this.translateService.instant('persons.historyTitle'), disabled: true }
        ];
      })
    );
  }

  private _loadDocuments(): void {
    if (this.person()?.documentSetUrl) {
      this.documentService.getDocumentCount(this.personId, DOCUMENT_SET_TYPE.PERSON).pipe(first())
        .subscribe(count => {
          this.documentCount.set(count.count);
          if (count.count === -1) {
            this.documentService.createDocumentSet(this.personId, DOCUMENT_SET_TYPE.PERSON).pipe(first())
              .subscribe(documentSet => {
                this.person.update(person => {
                  person.documentSetUrl = documentSet.url;
                  return person;
                });
                this.documentCount.set(0);
              });
          }
        });
    }
  }
}

<lib-lazy-dropdown
  *ngIf='formControl.enabled'
  [data$]='data$'
  [template]='itemTemplate'
  [formControl]='formControl'
  [showSearchIcon]='false'
  [showClear]='true'
  [displayValue]='getSelectedString'
  dropdownIcon='pi pi-search'
  placeholder="{{ placeholder | translate }}"
  field='trainingAllowanceApplicationId'
>
  <ng-template #itemTemplate let-item="item">
    <ng-container *ngTemplateOutlet='requestTemplate; context:{request: item}'></ng-container>
  </ng-template>
</lib-lazy-dropdown>
<div *ngIf='formControl.disabled && formControl?.value'>
  <ng-container *ngTemplateOutlet='requestTemplate; context:{request: formControl.value}'></ng-container>
</div>

<ng-template #requestTemplate let-request="request">
  <div class='flex items-center gap-2'>
    <span>{{request.year}}</span>
    <span>-</span>
    <span>{{request.branchName}}</span>
  </div>
</ng-template>

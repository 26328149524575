import { computed, Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first, map, Observable } from 'rxjs';
import {
  CancelEnrollment,
  CertificatesEligibleResponse,
  EnrollmentBody,
  EnrollmentDetail,
  EnrollmentListItem, EnrollmentParticipantAbsence,
  EnrollmentRequest,
  EnrollmentRequestParticipant,
  ExtraMailResponse,
  ValidationRequest
} from '../types/enrollment.type';
import { EnrollmentStatusKey } from '../types/reference-data.enum';
import { APP_CONFIG_TOKEN, AppConfig } from '@alimento-ipv-frontend/ui-lib';
import { ENROLLMENT_RESULT_ID } from '../types/enrollment.enum';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  enrollments: WritableSignal<EnrollmentListItem[]> = signal([]);
  activeEnrollments = computed(() => this.enrollments()
    .filter(enrollment => enrollment.statusId === EnrollmentStatusKey.Enrolled));
  nrOfParticipants = computed(() =>
    this.enrollments().filter(enrollment => enrollment.statusId === EnrollmentStatusKey.Enrolled).length
  );
  enrollmentRequests: WritableSignal<EnrollmentRequest[]> = signal([]);

  getEnrollment(enrollmentId: string): Observable<EnrollmentDetail> {
    return this.http.get<EnrollmentDetail>(`${this.config.readApiUrl}/enrollments/${enrollmentId}`);
  }

  createEnrollment(trainingId: string, enrollment: EnrollmentBody): Observable<{ id: string }> {
    (enrollment as any).trainingId = trainingId;
    return this.http.post<{ id: string }>(
      `${this.config.writeApiUrl}/enrollments/`,
      enrollment
    );
  }

  updateEnrollment(trainingId: string, enrollmentId: string, enrollment: EnrollmentBody): Observable<{ id: string }> {
    (enrollment as any).trainingId = trainingId;
    return this.http.put<{ id: string }>(
      `${this.config.writeApiUrl}/enrollments/${enrollmentId}`,
      enrollment
    );
  }

  addEnrollmentAsReserve(trainingId: string, enrollment: EnrollmentBody): Observable<{ id: string }> {
    (enrollment as any).trainingId = trainingId;
    return this.http.post<{ id: string }>(
      `${this.config.writeApiUrl}/enrollments/reserves`,
      enrollment
    );
  }

  reEnroll(trainingId: string, enrollmentId: string, enrollment: EnrollmentBody): Observable<{ id: string }> {
    (enrollment as any).trainingId = trainingId;
    return this.http.put<{ id: string }>(
      `${this.config.writeApiUrl}/enrollments/${enrollmentId}/reenroll`,
      enrollment
    );
  }

  deleteEnrollment(trainingId: string, enrollmentId: string): Observable<any> {
    return this.http.delete(`${this.config.writeApiUrl}/enrollments/${enrollmentId}`);
  }

  cancelEnrollment(trainingId: string, cancelEnrollment: CancelEnrollment) {
    (cancelEnrollment as any).trainingId = trainingId;
    return this.http.post<{ id: string }>(
      `${this.config.writeApiUrl}/enrollments/${cancelEnrollment.enrollmentId}/cancel`,
      cancelEnrollment
    );
  }

  getEnrollments(trainingId: string): Observable<EnrollmentListItem[]> {
    return this.http.get<EnrollmentListItem[]>(`${this.config.readApiUrl}/enrollments`,
      {
        params: {
          trainingId: trainingId
        }
      })
      .pipe(
        map((enrollments) =>
          enrollments.sort((a, b) => {
            return (a.lastName + ' ' + a.firstName).localeCompare(b.lastName + ' ' + b.firstName);
          })
        )
      );
  }

  getEnrollmentRequests(trainingId: string): Observable<EnrollmentRequest[]> {
    return this.http.get<EnrollmentRequest[]>(`${this.config.readApiUrl}/enrollmentrequests`,
      {
        params: {
          trainingId: trainingId
        }
      });
  }

  createEnrollmentRequest(trainingId: string, enrollmentRequest: EnrollmentRequest): Observable<{ id: string }> {
    enrollmentRequest.trainingId = trainingId;
    return this.http.post<{
      id: string
    }>(`${this.config.writeApiUrl}/enrollmentrequests/`, this.parseEnrollmentRequest(enrollmentRequest));
  }

  validateEnrollmentRequest(enrollmentRequestId: string): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      `${this.config.writeApiUrl}/enrollmentrequests/${enrollmentRequestId}/validate`, {});
  }

  verifyEnrollmentRequest(enrollmentRequestId: string): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      `${this.config.writeApiUrl}/enrollmentrequests/${enrollmentRequestId}/confirmforvalidation`, {});
  }

  updateEnrollmentRequest(enrollmentRequest: EnrollmentRequest): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(
      `${this.config.writeApiUrl}/enrollmentrequests/${enrollmentRequest.id}`, this.parseEnrollmentRequest(enrollmentRequest));
  }

  deleteEnrollmentRequest(enrollmentRequestId: string): Observable<any> {
    return this.http.delete(`${this.config.writeApiUrl}/enrollmentrequests/${enrollmentRequestId}`);
  }

  cancelEnrollmentRequest(enrollmentRequestId: string): Observable<any> {
    return this.http.put(`${this.config.writeApiUrl}/enrollmentrequests/${enrollmentRequestId}/cancel`, {});
  }

  addParticipant(trainingId: string, participant: EnrollmentRequestParticipant) {
    participant.trainingId = trainingId;
    return this.http.post<{
      id: string
    }>(`${this.config.writeApiUrl}/enrollmentrequestparticipants`, this.parseEnrollmentRequestParticipant(participant));
  }

  updateParticipant(participant: EnrollmentRequestParticipant) {
    return this.http.put<{
      id: string
    }>(`${this.config.writeApiUrl}/enrollmentrequestparticipants/${participant.id}`, this.parseEnrollmentRequestParticipant(participant));
  }

  deleteEnrollmentRequestParticipant(participant: EnrollmentRequestParticipant): Observable<any> {
    participant.validationResultId = ENROLLMENT_RESULT_ID.CANCEL;
    return this.updateParticipant(participant);
  }

  cancelEnrollmentRequestParticipant(enrollmentRequestParticipantId: string): Observable<any> {
    return this.http.put(`${this.config.writeApiUrl}/enrollmentrequestparticipants/${enrollmentRequestParticipantId}/cancel`, {});
  }

  getEnrollmentRequestParticipantAbsences(enrollmentRequestParticipantId: string): Observable<EnrollmentParticipantAbsence[]> {
    return this.http.get<{ id: string, trainingSessionId: string, reasonForAbsenceId: string }[]>
      (`${this.config.readApiUrl}/enrollmentrequestparticipants/${enrollmentRequestParticipantId}/absences`, {})
      .pipe(map(response => {
        return response.map(absence => ({sessionId: absence.trainingSessionId, reasonForAbsenceId: absence.reasonForAbsenceId}))
      }));
  }

  updateEnrollmentRequestParticipantAbsences(enrollmentParticipantId: string, participant: EnrollmentRequestParticipant): Observable<any> {
    return this.http.put<{
      id: string
    }>(`${this.config.writeApiUrl}/enrollmentrequestparticipants/${enrollmentParticipantId}/absences`, {absences: participant.absences});
  }

  loadEnrollments(trainingId: string) {
    this.getEnrollments(trainingId).subscribe((enrollments: EnrollmentListItem[]) => {
      this.enrollments.set(enrollments);
    });
  }

  loadEnrollmentRequests(trainingId: string): void {
    this.getEnrollmentRequests(trainingId).pipe(first())
      .subscribe(requests => this.enrollmentRequests.set(requests));
  }

  clearEnrollments() {
    this.enrollments.set([]);
    this.enrollmentRequests.set([]);
  }

  willTriggerExtraMail(trainingId: string,
                       workStatusId: string,
                       dateOfBirth: Date,
                       employmentId: string,
                       branchId: string,
                       personId: string
  ): Observable<ExtraMailResponse> {
    const params: any = {};
    if (workStatusId) {
      params.workStatusId = workStatusId;
    }
    if (dateOfBirth) {
      params.dateOfBirth = dateOfBirth;
    }
    if (employmentId) {
      params.employmentId = employmentId;
    }
    if (branchId) {
      params.branchId = branchId;
    }
    if (personId) {
      params.personId = personId;
    }
    params.trainingId = trainingId;

    return this.http.get<ExtraMailResponse>(
      `${this.config.readApiUrl}/enrollments/willtriggerextratrainingbudgetmail`, { params: params });
  }

  getCertificateParticipants(trainingId: string): Observable<CertificatesEligibleResponse> {
    return this.http.get<CertificatesEligibleResponse>(
      `${this.config.readApiUrl}/opentraining/${trainingId}/certificates`)
      .pipe(map(response => {
        response.eligibleParticipants = response.eligibleParticipants.sort((a, b) => {
          return (a.lastName + ' ' + a.firstName).localeCompare(b.lastName + ' ' + b.firstName);
        });
        return response;
      }));
  }

  getValidationRequest(trainingId: string): Observable<ValidationRequest> {
    return this.http.get<ValidationRequest>(`${this.config.readApiUrl}/trainings/${trainingId}/enrollmentrequesttoken`);
  }

  sendExtranetLink(trainingId: string): Observable<any> {
    return this.http.post<any>(`${this.config.writeApiUrl}/trainings/${trainingId}/sendextranetlink`, {});
  }

  requestToVerify(trainingId: string) {
    return this.http.put<ValidationRequest>(`${this.config.writeApiUrl}/trainings/${trainingId}/enrollmentrequesttoken`, {});
  }

  private parseEnrollmentRequestParticipant(participant: EnrollmentRequestParticipant) {
    return {
      ...participant,
      nationalIdentificationNumber: participant.nationalIdentificationNumber
        ? participant.nationalIdentificationNumber.replace(/[.-]/g, '')
        : null
    };
  }

  private parseEnrollmentRequest(request: EnrollmentRequest) {
    request.participants?.forEach(participant => participant.nationalIdentificationNumber = participant.nationalIdentificationNumber
      ? participant.nationalIdentificationNumber.replace(/[.-]/g, '')
      : null);

    return request;
  }
}

import { Injectable } from '@angular/core';
import { FilterType, SearchParam } from '@alimento-ipv-frontend/ui-lib';
import {
  isEventType,
  isPupilProjectType, isTeacherProjectType,
  PUPIL_TRAINING_TYPE,
  PupilTrainingTypeKey,
  TEACHER_TRAINING_TYPE,
  TeacherTrainingTypeKey, TRAINING_TYPE
} from '../../types/reference-data.enum';

@Injectable({
  providedIn: 'root'
})
export class TrainingMapper {

  changeTrainingTypeFilter(params: SearchParam): void {
    if (params[FilterType.filterType]?.length > 0) {
      if (params[FilterType.filterType].includes(PupilTrainingTypeKey)) {
        params[FilterType.filterType] = [
          ...Object.values(PUPIL_TRAINING_TYPE),
          ...params[FilterType.filterType].filter((value: string) => value !== PupilTrainingTypeKey)
        ];
      }
      if (params[FilterType.filterType].includes(TeacherTrainingTypeKey)) {
        params[FilterType.filterType] = [
          ...Object.values(TEACHER_TRAINING_TYPE),
          ...params[FilterType.filterType].filter((value: string) => value !== TeacherTrainingTypeKey)
        ];
      }
    }
  }

  getNavigationPath(trainingId: string, typeId: TRAINING_TYPE): string[] {
    let path;
    if (typeId === TRAINING_TYPE.OPEN_TRAINING) {
      path = ['/trainings', 'open', trainingId, 'detail'];
    }
    else if (typeId === TRAINING_TYPE.OPEN_EXTERN) {
      path = ['/trainings', 'openextern', trainingId];
    }
    else if (typeId === TRAINING_TYPE.CUSTOM) {
      path = ['/trainings', 'custom', trainingId];
    }
    else if (typeId === TRAINING_TYPE.CEVORA) {
      path = ['/trainings', 'cevora', trainingId, 'detail'];
    }
    else if (isPupilProjectType(typeId)) {
      path = ['/trainings', 'training-project-pupil', trainingId, 'detail'];
    }
    else if (isTeacherProjectType(typeId)) {
      path = ['/trainings', 'training-project-teacher', trainingId, 'detail'];
    }
    else if (isEventType(typeId)) {
      path = ['/trainings', 'training-event', trainingId, 'detail'];
    }

    return path;
  }
}

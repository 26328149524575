import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { debounceTime, Subscription } from 'rxjs';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { InfiniteScrollDataAdapter } from '../../utils/InfiniteScrollDataAdapter';
import { CardViewComponent } from '../card-view/card-view.component';
import { FilterType, SearchRequest } from '../../types/search.type';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-lazy-dropdown',
  templateUrl: './lazy-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LazyDropdownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LazyDropdownComponent),
      multi: true
    }
  ]
})
export class LazyDropdownComponent implements OnDestroy, OnInit, OnChanges, ControlValueAccessor, Validator {
  @Input()
  data$: InfiniteScrollDataAdapter<any>;
  @Input()
  field = 'label';
  @Input()
  displayValue?: (item: any) => string;
  @Input()
  minHeight = 48;
  @Input()
  clearOnSelect = false;
  @Input()
  template: TemplateRef<any>;
  @Input()
  headerTemplate: TemplateRef<any>;
  @Input()
  showSearchIcon = false;
  @Input()
  dropdownIcon = 'fa fa-chevron-down';
  @Input()
  showClear = false;
  @Input()
  placeholder: string;
  @Input()
  width: number;

  @Input()
  filterType: FilterType = FilterType.search;
  @Input()
  addEmptyValue = true;
  @Input()
  emptyValue = '0';
  @Input()
  emptyLabelKey = 'noValue';
  @Input()
  selectedItems: string[] = [];

  @Output()
  itemSelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputContainer')
  inputContainer: ElementRef;
  @ViewChild('inputField')
  inputField: ElementRef;
  @ViewChild('resultList')
  resultList: ElementRef;
  @ViewChild('dropdownButton')
  dropdownButton: ElementRef;
  @ViewChild('cardViewComponent')
  cardViewComponent: CardViewComponent;
  @ViewChild('resultListContainer')
  resultListContainer: ElementRef;

  formControl: FormControl;
  resultListVisible = false;
  resultListStyle: { [key: string]: any } = {};
  inputControl: FormControl = new FormControl('');
  disabled = false;
  private _itemSearch: string;
  private _subscriptions: Subscription[] = [];

  constructor() {
    this.formControl = new FormControl();
    this._subscriptions.push(
      this.formControl.valueChanges.subscribe(newValue => {
        this._setDisplayValue(this.formControl.value);
        this.itemSelected.emit(newValue);
        this.onChange(newValue);
        this.onTouched();
      })
    );

    this._subscriptions.push(this.inputControl.valueChanges.pipe(debounceTime(500))
      .subscribe(newValue => {
        this.search(newValue);
      })
    );
  }

  scroll = (event: any) => {
    if (this.resultListContainer &&
      this.resultListVisible &&
      !this.resultListContainer?.nativeElement.contains(event.target)) {
      this.resultListVisible = false;
    }
  };

  ngOnInit(): void {
    document.addEventListener('scroll', this.scroll, true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data$']?.currentValue) {
      this.data$.totalCount$.subscribe(() => this._setResultListStyle());
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    document.removeEventListener('scroll', this.scroll);
  }

  clear() {
    this.formControl.setValue(undefined);
    this.inputControl.setValue(undefined, { emitEvent: false });
  }

  setFocus(): void {
    this.inputField.nativeElement.focus();
  }

  search(searchValue: string) {
    const searchRequest = { filters: [] } as SearchRequest;
    if (!searchValue || this._itemSearch !== searchValue) {
      this._itemSearch = searchValue;
      searchRequest.filters.push({ type: this.filterType, values: [searchValue?.trim() || ''] });
      searchRequest.first = 0;
      searchRequest.rows = 9;

      this.data$?.query(searchRequest);
    }
    this._openResultLst();
  }

  onSelect(event: any[]) {
    this.formControl.setValue(event[0]);
    this._closeResultList(false);
  }

  onInputBlur(event: FocusEvent): void {
    if (event.relatedTarget === this.dropdownButton.nativeElement) {
      return;
    }

    this._closeResultList(true);
    event.stopImmediatePropagation();
  }

  onButtonBlur(event: FocusEvent): void {
    if (event.relatedTarget === this.inputField.nativeElement) {
      return;
    }

    this._closeResultList(true);
    event.stopImmediatePropagation();
  }

  onKeyDown(event: Event): void {
    if (!this.resultListVisible) {
      this.search(this._itemSearch);
    }
    else {
      if (this.cardViewComponent?.hasSelection()) {
        setTimeout(() => {
          this.resultList.nativeElement.scrollTo({
            top: this.resultList.nativeElement.scrollTop + this.minHeight,
            behavior: 'smooth'
          });
        });
      }
      this.cardViewComponent.selectNextItem();
    }
    event.stopImmediatePropagation();
  }

  onKeyUp(event: Event): void {
    if (!this.resultListVisible) {
      this.search(this._itemSearch);
    }
    else {
      this.cardViewComponent.selectPreviousItem();
      if (this.cardViewComponent.hasSelection()) {
        setTimeout(() => {
          this.resultList.nativeElement.scrollTo({
            top: this.resultList.nativeElement.scrollTop - this.minHeight,
            behavior: 'smooth'
          });
        });
      }
    }
    event.stopImmediatePropagation();
  }

  onKeyEnter(event: Event): void {
    if (this.resultListVisible && this.cardViewComponent?.hasSelection()) {
      this.cardViewComponent.selectItem();
    }
    event.stopImmediatePropagation();
  }

  onEscape(event: Event): void {
    this._setDisplayValue(this.formControl.value);
    this._closeResultList(true);
    event.stopImmediatePropagation();
  }

  dropdownButtonClicked(event: Event): void {
    if (this.resultListVisible) {
      this._closeResultList(false);
    }
    else {
      this.search('');
      this.setFocus();
    }
    event.stopImmediatePropagation();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  writeValue(value: any) {
    if (value) {
      this.formControl.setValue(value, { emitEvent: false });
    }
    else {
      this.formControl.reset('', { emitEvent: false });
    }
    this._setDisplayValue(value);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return control.valid ? null : { lazyDropdownInvalid: true };
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.inputControl.disable({ emitEvent: false }) : this.inputControl.enable({ emitEvent: false });
  }

  private _setDisplayValue(value: any): void {
    this.inputControl.setValue(this._getDisplayValue(value) || '', { emitEvent: false });
  }

  private _getDisplayValue(value?: any): string {
    let displayValue;
    if (!value) {
      value = this.formControl.value;
    }
    if (value) {
      displayValue = this.displayValue ? this.displayValue(value) : value[this.field];
    }

    return displayValue;
  }

  private _closeResultList(emptyValueIfVisible: boolean): void {
    if (emptyValueIfVisible && this.resultListVisible && this._getDisplayValue() !== this.inputControl.value) {
      this.formControl.setValue('');
    }
    this.resultListVisible = false;
    if (this.clearOnSelect) {
      this.inputControl.setValue('', { emitEvent: false });
    }
    else {
      this._setDisplayValue(this.formControl.value);
    }
  }

  private _setResultListStyle(): void {
    let height;
    if (this.data$?.totalCount$.value < 5) {
      height = ((((this.headerTemplate ? 1 : 0) + this.data$.totalCount$.value) * this.minHeight + 2) || this.minHeight) + 'px';
    }
    const inputBoundingRect = this.inputContainer?.nativeElement.getBoundingClientRect();
    this.resultListStyle = {
      top: (window.scrollY + inputBoundingRect?.top + inputBoundingRect?.height) + 'px',
      left: (window.scrollX + inputBoundingRect?.left) + 'px',
      width: (this.width ? this.width : inputBoundingRect?.width) + 'px',
      height: height,
      "z-index": this._getHighestZIndex() + 1
    };
  }

  private _openResultLst(): void {
    this._setResultListStyle();
    this.resultListVisible = true;
    // setTimeout(() => {
    //   this.resultList.nativeElement.scrollTo(0, 0);
    //   this.resultList.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    // });
  }

  private _getHighestZIndex(elem = "*"): number {
    const elems = document.getElementsByTagName(elem);
    let highest = Number.MIN_SAFE_INTEGER || -(Math.pow(2, 53) - 1);
    for (let i = 0; i < elems.length; i++) {
      const zIndex = Number.parseInt(
        document.defaultView.getComputedStyle(elems[i], null).getPropertyValue('z-index'),
        10
      );
      if (zIndex > highest) {
        highest = zIndex;
      }
    }
    return highest;
  }
}

<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                     [readonly]='readOnly'
                                     [linkedEntity]='trainingId'
                                     [type]='TrainingNote'
                                     (hasChanges)='notesHasChanges = $event'
></alimento-ipv-frontend-notes-sidebar>

<div [formGroup]="trainingForm">
  <alimento-ipv-frontend-detail-page-layout
    title="{{ 'trainings.cevoraTraining' | translate }}"
    [navigationUrl]="['/trainings']"
    [relatedForm]="trainingForm"
  >
    <ng-container top-action>
      <div class="flex items-center">
        <alimento-ipv-frontend-form-field
          [formGroup]='trainingForm'
          [errorTemplate]='customTitleError'
          formGroupClass='!pb-0'
          formField='customTitle'
          labelClass='hidden'
        >
          <span class='text-xl' *ngIf='readOnly'>{{trainingForm.get("customTitle")?.value}}</span>
          <input
            *ngIf='!readOnly'
            id="customTitle"
            formControlName="customTitle"
            type="text"
            class="min-w-[500px] max-w-[1000px]"
            pInputText
            placeholder="{{ 'trainings.titlePlaceholder' | translate }}"
            data-test="custom-title-input"
            autoWidth
          />
          <ng-template #customTitleError>
            <small *ngIf="trainingForm.get('customTitle')?.hasError('required')">
              {{ 'validation.isRequired' | translate : { field: 'trainings.title' | translate | lowercase } }}
            </small>
            <small *ngIf="trainingForm.get('customTitle')?.hasError('maxlength')">
              {{ 'validation.maxLength' | translate : { number: 200 } }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>

        <span *ngIf="training()?.trainingAlimentoId" class="text-xl mx-2 whitespace-nowrap text-text">
          - {{ training().trainingAlimentoId }}</span>
      </div>

      <div class="flex gap-4" *ngIf='!readOnly'>
        <span
          [pTooltip]="!trainingForm.get('customTitle')?.value ? ('trainings.saveNotPossibleTooltip' | translate) : ''"
        >
          <button
            pButton
            type="button"
            label="{{ 'trainings.actions.save' | translate }}"
            class="secondary-button uppercase"
            icon="pi pi-save"
            loadingIcon="pi pi-spin pi-spinner"
            (click)="saveTraining()"
            [loading]="savingTraining"
            [disabled]="isActivateDisabled"
            data-test="save-training-btn"
          ></button>
        </span>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
        [activeTabIndex]="activeTabIndex"
        (activeTabChanged)="setActiveTabIndex($event)"
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <alimento-ipv-frontend-basic-info
        [hidden]="activeTabIndex !== 0"
        [type]='TRAINING_TYPE.CEVORA'
        [nrOfSessionsWithDates]='sessionService.nrOfSessionsWithDates'
        [showRequiredFieldErrors]='true'
        formControlName="basicDataForm"
        #basicInfoComponent
      >
      </alimento-ipv-frontend-basic-info>

      <alimento-ipv-frontend-sessions
        [hidden]="activeTabIndex !== 2"
        [trainingId]="trainingId"
        [trainingStatus]="trainingStatus"
        [trainingMethodId]="basicInfoComponent.formGroup.get('methodId')?.value"
        [trainingType]='TRAINING_TYPE.CEVORA'
        (sessionUpdated)='loadTrainingData()'
        (hasChanges)='onSessionChange($event)'
      >
      </alimento-ipv-frontend-sessions>

      <alimento-ipv-frontend-enrollments
        [hidden]="activeTabIndex !== 3"
        [training]="training"
        [trainingStatus]="trainingStatus"
        [trainingType]='TRAINING_TYPE.CEVORA'
        (hasChange)='onEnrollmentChange($event)'
      ></alimento-ipv-frontend-enrollments>

      <alimento-ipv-frontend-training-communications
        *ngIf='activeTabIndex === 4'
        [hidden]='activeTabIndex !== 4'
        [readOnly]='readOnly'
        [training]='training()'
      ></alimento-ipv-frontend-training-communications>

      <alimento-ipv-frontend-invoices-overview
        *ngIf='activeTabIndex === 5'
        [readOnly]='readOnly'
        [trainingId]='trainingId'
        [hasCounselor]='!!training()?.counselorId'
        [purchaseEntries]='purchaseEntries'
        [salesInvoices]='salesInvoices'
        (purchaseEntryChanges)='getPurchaseEntries()'
        (salesInvoiceChanges)='getSalesInvoices()'
      >
      </alimento-ipv-frontend-invoices-overview>
    </ng-container>

    <ng-container metadata>
      <alimento-ipv-frontend-training-status-chip
        [status]="getTrainingStatusKey(trainingStatus)"
        [trainingOngoing]="isOngoing()"
        [isCancelled]='training()?.isCancelled'
      ></alimento-ipv-frontend-training-status-chip>

<!--      <div *ngIf='!readOnly && trainingStatus === trainingStatusKeys.Cancelled' class='flex flex-col gap-4'>-->
<!--        <alimento-ipv-frontend-reactivate-training-dialog-->
<!--          #reactivateTrainingDialog-->
<!--          [training]='training'-->
<!--          [firstSessionDate]='firstSessionDate'-->
<!--          (reactivateTrainingConfirmed)="reactivateTraining($event)"-->
<!--        >-->
<!--        </alimento-ipv-frontend-reactivate-training-dialog>-->

<!--        <button-->
<!--          pButton-->
<!--          pRipple-->
<!--          type="button"-->
<!--          label="{{ 'trainings.actions.reactivate' | translate }}"-->
<!--          class="p-button-primary p-button-outlined uppercase"-->
<!--          loadingIcon="pi pi-spin pi-spinner"-->
<!--          [loading]="reactivatingTraining"-->
<!--          (click)="reactivateTrainingDialog.open()"-->
<!--        ></button>-->

<!--      </div>-->

      <div *ngIf='!readOnly && trainingStatus !== trainingStatusKeys.Draft' class='flex flex-col gap-4'>
        <alimento-ipv-frontend-close-training-dialog
          #closeTrainingDialog
          [training]='training'
          [firstSessionDate]='firstSessionDate'
          (closeTrainingConfirmed)="closeTraining($event)"
        >
        </alimento-ipv-frontend-close-training-dialog>

        <button
          *ngIf='trainingStatus !== trainingStatusKeys.Completed'
          pButton
          pRipple
          type="button"
          label="{{ 'trainings.actions.close' | translate }}"
          class="p-button-primary p-button-outlined uppercase"
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="closingTraining"
          (click)="closeTrainingDialog.open()"
        ></button>

        <div *ngIf='sessionService.nrOfSessions() > 0 && enrollmentService.enrollments()?.length > 0'>
          <div class="link-button link-button-with-icon justify-between flex-row-reverse">
            <p-inputSwitch #completeAllAbsences
                           [formControl]="completeAllAbsencesToggle"
            ></p-inputSwitch>
            <span (click)="!completeAllAbsences.disabled ? completeAllAbsences.writeValue(!completeAllAbsences.checked()) : undefined"
                  (keyup.enter)='!completeAllAbsences.disabled ? completeAllAbsences.writeValue(!completeAllAbsences.checked()) : undefined'
                  class='focus-visible:outline-0 focus-visible:underline whitespace-nowrap'
                  tabindex='0'
            >
              {{ 'trainings.actions.completeAllAbsences' | translate }}
            </span>
          </div>
        </div>

        <div *ngIf='trainingStatus === trainingStatusKeys.Active && trainingId && !isOngoing()'>
          <div class="cursor-pointer hover:underline focus-visible:outline-0 focus-visible:underline link-button--danger"
               tabindex='0'
               (click)="cancelTrainingDialog.open()"
               (keyup.enter)="cancelTrainingDialog.open()"
               [pTooltip]="isOngoing() ? ('trainings.firstSessionAlreadyOver' | translate) : ''"
          >
            <alimento-ipv-frontend-cancel-training-dialog
              #cancelTrainingDialog
              [training]='training()'
              [firstSessionDate]='firstSessionDate'
              (cancelTrainingConfirmed)="cancelTraining($event, cancelTrainingDialog)"
            >
            </alimento-ipv-frontend-cancel-training-dialog>
            <span>{{ 'trainings.cancelDialog.buttons.cancelTraining' | translate }}</span>
          </div>
        </div>
      </div>

      <span class="h-[1px] bg-grey-2" *ngIf="trainingId"></span>

      <ng-container *ngIf="reasonCancelled">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'trainings.reasonCancelled' | translate }}"
          value="{{ reasonCancelled }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.cancelledComment">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'trainings.cancelledComment' | translate }}"
          value="{{ training().cancelledComment }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.createdBy">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'trainings.createdBy' | translate }}"
          value="{{ training().createdBy }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.createdOn">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'trainings.createdOn' | translate }}"
          value="{{ training().createdOn | date : 'dd/MM/yyyy' }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG_TOKEN, AppConfig, PaginatedResponse } from '@alimento-ipv-frontend/ui-lib';
import { map, Observable } from 'rxjs';
import { AccountNumber, Enterprise, SearchAccountNumber } from '../types/enterprise.type';

@Injectable({
  providedIn: 'root'
})
export class EnterprisesService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getEnterprise(enterpriseId: string): Observable<Enterprise> {
    return this.http.get<Enterprise>(`${this.config.readApiUrl}/enterprises/${enterpriseId}`);
  }

  createEnterprise(enterprise: Enterprise): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/enterprises`, enterprise);
  }

  updateEnterprise(enterpriseId: string, enterprise: Enterprise): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/enterprises/${enterpriseId}`, enterprise);
  }

  deleteEnterprise(enterpriseId: string): Observable<any> {
    return this.http.delete(`${this.config.writeApiUrl}/enterprises/${enterpriseId}`);
  }

  getAccountNumbers(enterpriseId: string): Observable<SearchAccountNumber[]> {
    return this.http.get<PaginatedResponse<SearchAccountNumber>>
    (`${this.config.readApiUrl}/accountnumbers`, {
      params: {
        enterpriseId: enterpriseId,
        off: 0,
        l: 999
      }
    })
      .pipe(map(response => response.data
        .sort((a, b) => {
          if (a.default) return -1;
          if (b.default) return 1;
          return 0;
        })));
  }

  getAccountNumber(accountNumberId: string): Observable<AccountNumber> {
    return this.http.get<AccountNumber>(`${this.config.readApiUrl}/accountnumbers/${accountNumberId}`);
  }

  createAccountNumber(enterpriseId: string, accountNumber: AccountNumber): Observable<{ id: string }> {
    accountNumber.enterpriseId = enterpriseId;
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/accountnumbers`, accountNumber);
  }

  updateAccountNumber(enterpriseId: string, accountNumber: AccountNumber): Observable<{ id: string }> {
    accountNumber.enterpriseId = enterpriseId;
    return this.http.put<{
      id: string
    }>(`${this.config.writeApiUrl}/accountnumbers/${accountNumber.accountNumberId}`, accountNumber);
  }

  removeAccountNumber(accountNumberId: string): Observable<{ id: string }> {
    return this.http.delete<{ id: string }>(`${this.config.writeApiUrl}/accountnumbers/${accountNumberId}`);
  }

  approveAccountNumber(accountNumberId: string, data: any): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/accountnumbers/${accountNumberId}/approve`, data);
  }

  rejectAccountNumber(accountNumberId: string, data: any): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/accountnumbers/${accountNumberId}/reject`, data);
  }

  setAccountNumberAsDefault(accountNumberId: string) {
    return this.http.put<void>(`${this.config.writeApiUrl}/accountnumbers/${accountNumberId}/default`, {});
  }
}

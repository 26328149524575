import { Component, Input } from '@angular/core';
import { SearchEnterpriseItem } from '../../../types/enterprise.type';

@Component({
  selector: 'alimento-ipv-frontend-basic-enterprise-card',
  templateUrl: './basic-enterprise-card.component.html'
})
export class BasicEnterpriseCardComponent {
  @Input()
  enterprise?: SearchEnterpriseItem;

  @Input()
  showHasGroup: boolean;
}

import { computed, Inject, Injectable, signal, WritableSignal } from '@angular/core';
import {
  CancelSession,
  CancelSessionReason,
  RegisterAbsenceBody,
  Session,
  SessionListItem,
  SessionParticipant
} from '../types/session.type';
import { map, Observable, of, shareReplay, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TRAINING_TYPE } from '../types/reference-data.enum';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG_TOKEN, AppConfig, DateMapper } from '@alimento-ipv-frontend/ui-lib';
import { DataLabelType } from '../types/reference-data.type';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  trainingType: WritableSignal<TRAINING_TYPE> = signal(undefined);
  sessions: WritableSignal<SessionListItem[]> = signal([]);
  isOngoing: WritableSignal<boolean> = signal(false);
  currentTrainingSession: WritableSignal<Session> = signal(undefined);
  totalDuration = computed(() => {
    const timeMinutes = this.sessions().filter(session => session.duration)
      .reduce((timeMinutes, session) => {
        function getTimeMinutes(duration: string) {
          const split = duration.split(':');
          return Number(split[1]) + (Number(split[0]) * 60)
        }
        return timeMinutes + getTimeMinutes(session.duration);
      }, 0);

    return ("" + Math.floor(timeMinutes / 60)).padStart(2, "0") + ':' +
      ("" + Math.floor(timeMinutes % 60)).padStart(2, "0");
  });

  nrOfSessions = computed(() =>
    this.sessions().filter(session => !session.sessionCancelled).length);
  nrOfSessionsWithDates = computed(() =>
    this.sessions().filter(session => !session.sessionCancelled)
      .filter(session => session.date).length);

  constructor(private http: HttpClient,
              private translateService: TranslateService,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  private _getUrl(trainingId: string): string {
    if (this.trainingType() === TRAINING_TYPE.TEMPLATE) {
      return `/trainingtemplate/${trainingId}/sessions/`;
    }
    else {
      return `/sessions/`;
    }
  }

  setTrainingType(trainingType: TRAINING_TYPE): void {
    this.trainingType.set(trainingType);
  }

  loadSessions(trainingId: string, trainingType?: TRAINING_TYPE) {
    if (trainingType) {
      this.setTrainingType(trainingType);
    }

    let url = `${this.config.readApiUrl}${this._getUrl(trainingId)}`;
    if (this.trainingType() !== TRAINING_TYPE.TEMPLATE) {
      url += `?trainingId=${trainingId}`;
    }
    this.http
      .get<SessionListItem[]>(url)
      .pipe(map((sessions: SessionListItem[]) => this.sortSessionsOnDateWhenForTraining(sessions)))
      .pipe(map((sessions: SessionListItem[]) => this.setSessionsTitle(sessions)))
      .subscribe((sessions: SessionListItem[]) => {
        this.sessions.set(sessions);
        this.setFirstSessionBeforeTodayWhenForTraining(sessions);
      });
  }

  getSession(trainingId: string, sessionId: string): Observable<Session> {
    return this.http.get<Session>(`${this.config.readApiUrl}${this._getUrl(trainingId)}${sessionId}`);
  }

  createSession(trainingId: string, session: Session): Observable<{ id: string }> {
    session.trainingId = trainingId;
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}${this._getUrl(trainingId)}`, session);
  }

  updateSession(trainingId: string, sessionId: string, session: Session): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}${this._getUrl(trainingId)}${sessionId}`, session);
  }

  deleteSession(trainingId: string, sessionId: string): Observable<{ id: string }> {
    return this.http.delete<{ id: string }>(`${this.config.writeApiUrl}${this._getUrl(trainingId)}${sessionId}`);
  }

  addNewSession(trainingMethodId?: string, defaultTeacherBranchId?: DataLabelType) {
    return this.getNewSession(trainingMethodId, defaultTeacherBranchId).pipe(
      tap((session: Session) => this.currentTrainingSession.set(session))
    );
  }

  editSession(trainingId: string, sessionId: string): Observable<Session> {
    return this.getSession(trainingId, sessionId).pipe(
      tap((session: Session) => this.currentTrainingSession.set(session))
    );
  }

  copySession(trainingId: string, sessionId: string): Observable<Session> {
    return this.getSession(trainingId, sessionId).pipe(
      map((session) => {
        session.id = undefined;
        session.date = '';
        return session;
      }),
      tap((session: Session) => this.currentTrainingSession.set(session))
    );
  }

  getCancelSessionReasons(): Observable<CancelSessionReason[]> {
    return this.http
      .get<CancelSessionReason[]>(`${this.config.readApiUrl}/referencedata/reasonsessioncancelled`)
      .pipe(shareReplay());
  }

  cancelSession(trainingId: string, sessionId: string, cancelSession: CancelSession): Observable<void> {
    return this.http.put<void>(`${this.config.writeApiUrl}${this._getUrl(trainingId)}${sessionId}/cancel`, cancelSession);
  }

  private getNewSession(trainingMethodId?: string, defaultTeacherBranchId?: DataLabelType): Observable<Session> {
    return of({
      date: '',
      title: '',
      endTime: '',
      startTime: '',
      includedSessionOptionIds: [],
      sessionMethodId: trainingMethodId ?? '',
      id: undefined,
      location: undefined,
      sessionComments: '',
      teacherBranches: defaultTeacherBranchId ? [defaultTeacherBranchId] : [],
      teacherEmployments: []
    });
  }

  private sortSessionsOnDateWhenForTraining(sessions: SessionListItem[]): SessionListItem[] {
    if (this.trainingType() !== TRAINING_TYPE.TEMPLATE) {
      return sessions.sort(
        (session1, session2) => new Date(session1.date).valueOf() - new Date(session2.date).valueOf()
      );
    }

    return sessions;
  }

  private setSessionsTitle(sessions: SessionListItem[]): SessionListItem[] {
    return sessions.map((session, index) => {
      if (!session.title && session.date) {
        session.title = this.translateService.instant('trainings.sessions.sessionOf',
          { date: session.date ? DateMapper.getDateFromDateTimeAsString(new Date(session.date)) : '' });
      }
      else if (!session.title) {
        session.title = this.translateService.instant('trainings.sessions.sessionNr', { number: index + 1 });
      }
      return session;
    });
  }

  private setFirstSessionBeforeTodayWhenForTraining(sessions: SessionListItem[]) {
    const sessionsNotCancelled = sessions
      .filter(session => !session.sessionCancelled)
      .filter(session => !!session.date);

    if (this.trainingType() !== TRAINING_TYPE.TEMPLATE && sessionsNotCancelled.length !== 0) {
      const sortedDates: SessionListItem[] = sessionsNotCancelled
        .sort((a, b) => {
          const aDate = new Date(a.date);
          const bDate = new Date(b.date);
          return aDate > bDate || (aDate === bDate && a.endTime > b.endTime) ? 1 : -1;
        });

      const startDate = new Date(sortedDates[0].date + 'T' + (sortedDates[0].startTime || '00:00'));
      const endDate = new Date(sortedDates[sortedDates.length - 1].date + 'T' +
        (sortedDates[sortedDates.length - 1].endTime || '23:59'));

      this.isOngoing.set(startDate < new Date() && new Date() < endDate);
    }
    else {
      this.isOngoing.set(false);
    }
  }

  clearSessions() {
    this.sessions.set([]);
    this.isOngoing.set(false);
  }

  getParticipantsAndAbsences(trainingId: string, sessionId: string): Observable<SessionParticipant[]> {
    return this.http.get<SessionParticipant[]>(`${this.config.readApiUrl}${this._getUrl(trainingId)}${sessionId}/participants`);
  }

  registerAbsence(trainingId: string, sessionId: string, registerAbsenceBody: RegisterAbsenceBody): Observable<void> {
    return this.http.put<void>(`${this.config.writeApiUrl}${this._getUrl(trainingId)}${sessionId}/absences`, registerAbsenceBody);
  }
}

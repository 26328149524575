<div *ngIf="trainingPlan" class="group flex flex-col border-b border-b-grey-2 py-2">
  <div
    (click)="toggleShowExtraInfo()"
    class="flex gap-4 cursor-pointer group-hover:bg-primary group-hover:bg-opacity-[7%]"
  >
    <div>
      <div
        (keyup.enter)="toggleShowExtraInfo()"
        class="flex items-center p-2 m-2 text-primary outline-primary"
        tabindex="0"
      >
        <i class="pi pi-angle-{{ showExtraInfo ? 'up' : 'down' }}"></i>
      </div>
    </div>

    <div class="flex-grow mb-4">
      <div class="flex justify-between items-center mt-2 mr-2">
        <div class='flex items-center gap-4'>
          <span class='text-title' *ngIf='!trainingPlan.validFrom'>
            {{'branches.trainingPlans.requestCurrent' | translate}}
          </span>
          <span class="text-title" *ngIf='trainingPlan.validFrom'>
              {{ trainingPlan.validFrom | date : 'dd/MM/yyyy' }} - {{ trainingPlan.validTo | date : 'dd/MM/yyyy' }}
          </span>
          <span class='text-title' *ngIf='showDue'>
            {{'branches.trainingPlans.due' | translate}} {{ trainingPlan.due | date : 'dd/MM/yyyy' }}
          </span>
        </div>

        <div class="flex items-center gap-4">
          <div class='flex gap-1 items-center'>
            <span class='font-bold' [ngClass]='getStateColor()'>{{trainingPlanState}}</span>
            <span>
              {{'branches.trainingPlans.byOn' | translate:
              {by: trainingPlan.actionExecutedBy || ('branches.trainingPlans.noName' | translate),
                on: trainingPlan.actionExecutedOn ? (trainingPlan.actionExecutedOn | date : 'dd/MM/yyyy') : ('branches.trainingPlans.noName' | translate)} }}</span>
          </div>

          <div
            (click)="toggleAction(primaryAction.action, $event)"
            (keyup.enter)="toggleAction(primaryAction.action, $event)"
            *ngIf="primaryAction"
            class="p-button inverted-button h-8 w-8 bg-white justify-center hover:bg-primary focus:bg-primary hover:bg-opacity-[7%] focus:bg-opacity-[7%]"
            tabindex="0"
            [pTooltip]="primaryActionTooltip | translate"
          >
            <span class="material-symbols-outlined text-base">
              {{ primaryAction.icon }}
            </span>
          </div>

          <alimento-ipv-frontend-more-actions [actions]="actions"></alimento-ipv-frontend-more-actions>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showExtraInfo" class="session-extra-info grid grid-cols-2 bg-grey-0 mt-4 p-2 pl-12">
    <div>
      <div class='flex flex-col gap-1 border-r border-r-grey-2'
           *ngIf='trainingPlan.stateId !== TRAINING_PLAN_STATE.REQUESTED'>
        <ng-container *ngTemplateOutlet='checkIcon; context: {value: "moreThan20Employees"}'></ng-container>
        <ng-container *ngTemplateOutlet='checkIcon; context: {value: "validConsultativeBody"}'></ng-container>
        <div *ngIf='trainingPlan.validConsultativeBody'>
          <ng-container *ngTemplateOutlet='checkIcon; context: {value: "validReceptionPolicy"}'></ng-container>
        </div>
        <div *ngIf='trainingPlan.validConsultativeBody'>
          <ng-container *ngTemplateOutlet='checkIcon; context: {value: "consultedSyndic"}'></ng-container>
        </div>
        <ng-container *ngTemplateOutlet='checkIcon; context: {value: "completePlanReceived"}'></ng-container>
        <ng-container *ngTemplateOutlet='checkIcon; context: {value: "statementReceived"}'></ng-container>
      </div>
    </div>

    <div class='ml-4'>
      <div class='flex justify-between'>
        <div>
          <span *ngIf='trainingPlan.reference'>
            {{'branches.trainingPlans.reference' | translate}}: {{trainingPlan.reference}}
          </span>
          <lib-documents [files]='trainingPlan.files'
                         [showFilesAsList]='true'
                         [canAdd]='false'
                         [canDelete]='false'>
          </lib-documents>
        </div>
        <div class='flex items-center gap-2'>
<!--          <p-button-->
<!--            *ngIf="!this.readOnly && canRegister"-->
<!--            (onClick)="toggleAction(TrainingPlanActionEnum.sendReminder, $event)"-->
<!--            label="{{ 'branches.trainingPlans.sendReminder' | translate | uppercase }}"-->
<!--          ></p-button>-->
          <p-button
            *ngIf="!this.readOnly && canRegister"
            (onClick)="toggleAction(TrainingPlanActionEnum.register, $event)"
            label="{{ 'branches.trainingPlans.register' | translate | uppercase }}"
          ></p-button>
          <p-button
            *ngIf="!this.readOnly && canReject"
            (onClick)="toggleAction(TrainingPlanActionEnum.reject, $event)"
            label="{{ 'branches.trainingPlans.reject' | translate | uppercase }}"
          ></p-button>
          <p-button
            *ngIf="!this.readOnly && canApprove"
            (onClick)="toggleAction(TrainingPlanActionEnum.approve, $event)"
            label="{{ 'branches.trainingPlans.approve' | translate | uppercase }}"
          ></p-button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #checkIcon let-value="value" >
  <span class='flex items-center gap-2'>
    <i *ngIf='$any(trainingPlan)[value] === null' class="w-4 pi pi-minus"></i>
    <i *ngIf='$any(trainingPlan)[value] === true' class="w-4 pi pi-check"></i>
    <i *ngIf='$any(trainingPlan)[value] === false' class="w-4 pi pi-times"></i>
    {{ ('branches.trainingPlans.' + value) | translate }}
  </span>
</ng-template>

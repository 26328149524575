import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  FilterType,
  InfiniteScrollDataAdapter,
  MyMessageService,
  PaginatedResponse,
  SearchFilter,
  SearchFilterType,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { first, Observable } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchesService } from '../../../services/searches.service';
import { EnterpriseGroupComponent } from '../enterprise-group/enterprise-group.component';
import { EnterpriseGroup, SearchEnterpriseGroupItem } from '../../../types/enterprise-group.type';
import { EnterpriseGroupService } from '../../../services/enterprise-group.service';

@Component({
  selector: 'alimento-ipv-frontend-select-enterprise-group',
  templateUrl: './select-enterprise-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectEnterpriseGroupComponent),
      multi: true
    }
  ]
})
export class SelectEnterpriseGroupComponent implements ControlValueAccessor {

  @Input()
  enterpriseId?: string;

  @Input()
  enterpriseGroupId?: string;

  search$: InfiniteScrollDataAdapter<SearchEnterpriseGroupItem>;
  searchFilters: SearchFilter[] = [];
  activeIndex = 0;
  newEnterpriseGroup: EnterpriseGroup;
  dialogVisible = false;
  enterpriseGroup: SearchEnterpriseGroupItem;
  currentSelection: SearchEnterpriseGroupItem;

  @ViewChild(EnterpriseGroupComponent)
  enterpriseGroupComponent: EnterpriseGroupComponent;

  loading = false;
  disabled = false;

  constructor(private enterpriseGroupService: EnterpriseGroupService,
              private searchesService: SearchesService,
              private messageService: MyMessageService) {
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterBranch,
      values: [this.enterpriseId]
    });

    this.search$ = new InfiniteScrollDataAdapter<SearchEnterpriseGroupItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<SearchEnterpriseGroupItem>> => {
      return this.searchesService.searchEnterpriseGroups(searchRequest);
    }, searchRequest, true);

    this.searchFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      }
    ];
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value?.enterpriseGroupId) {
      if (this.enterpriseGroup?.enterpriseGroupId !== value.enterpriseGroupId) {
        this.enterpriseGroupService.getEnterpriseGroup(value.enterpriseGroupId)
          .pipe(first())
          .subscribe(enterpriseGroup => this.enterpriseGroup = this._getSearchEnterpriseGroupItem(enterpriseGroup));
      }
    }
    else {
      this.enterpriseGroup = undefined;
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.currentSelection = undefined;
    this.activeIndex = 0;
  }

  selectEnterpriseGroup() {
    if (this.activeIndex === 0) {
      this.enterpriseGroup = this.currentSelection;
      this.onChange({ enterpriseGroupId: this.enterpriseGroup.enterpriseGroupId });
      this.onTouched();
      this.closeDialog();
    }
    else {
      if (this.enterpriseGroupComponent.isValid()) {
        const data = this.enterpriseGroupComponent.getData();
        this.enterpriseGroup = { groupName: data.name } as SearchEnterpriseGroupItem;
        this.onChange(data);
        this.onTouched();
        this.closeDialog();
      }
      else {
        this.messageService.notAllFieldsValid();
      }
    }
  }

  private _getSearchEnterpriseGroupItem(enterpriseGroup: EnterpriseGroup): SearchEnterpriseGroupItem {
    return {
      mainEnterpriseId: enterpriseGroup.mainEnterprise.enterpriseId,
      enterpriseGroupId: enterpriseGroup.enterpriseGroupId,
      groupAlimentoId: enterpriseGroup.enterpriseGroupAlimentoId,
      enterpriseNumber: enterpriseGroup.mainEnterprise.companyNumber,
      amountOfEnterprises: enterpriseGroup.amountOfEnterprises,
      amountOfEmployees: enterpriseGroup.numberOfEmployees,
      groupName: enterpriseGroup.name,
      mainEnterpriseRszNumber: enterpriseGroup.mainEnterprise.rszNumber,
      mainEnterpriseVatNumber: enterpriseGroup.mainEnterprise.vatNumber,
      groupId: enterpriseGroup.enterpriseGroupId,
      groupApprovedOn: enterpriseGroup.groupApprovedOn
    };
  }

  addEnterpriseGroup() {
    this.dialogVisible = true;
    this.newEnterpriseGroup = {
      mainEnterprise: {
        enterpriseId: this.enterpriseId || 'NEW_ENTERPRISE'
      }
    } as EnterpriseGroup;
    this._createSearch();
  }

  selectionChange(trainingPrograms: any[]) {
    this.currentSelection = trainingPrograms[0];
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = true //isDisabled;
  }

  removeEnterpriseGroup(): void {
    this.enterpriseGroup = undefined;
    this.onChange(undefined);
    this.onTouched();
  }
}

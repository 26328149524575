<p-dialog
  *ngIf='dialogVisible'
  (blur)="dialogVisible"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.moveDialog.title' | translate }}"
>
  <div class="flex bg-grey-0 p-4 mb-4" *ngIf="training">
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        title='{{"trainings.cancelDialog.labels.trainingName" | translate}}'
        [value]='training.customTitle'
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        title='{{"trainings.cancelDialog.labels.firstSessionDate" | translate}}'
        [value]='firstSessionDate ? (firstSessionDate | date:"dd/MM/yyyy") : "/"'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div [formGroup]="moveTrainingForm" class='flex flex-col gap-4 mb-4'>
    <span>{{'trainings.moveDialog.info' | translate}}</span>

    <alimento-ipv-frontend-form-field [formGroup]='moveTrainingForm'
                                      [required]='true'
                                      formField='trainingAllowanceApplication'
                                      fieldKey='trainings.moveDIalog.reimbursementApplication'
    >
      <alimento-ipv-frontend-reimbursement-request-select formControlName='trainingAllowanceApplication'
                                                          [extraFilters]='groupIdFilter'
      ></alimento-ipv-frontend-reimbursement-request-select>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="clone()"
        [loading]='loading'
        label="{{ 'trainings.moveDialog.buttons.moveTraining' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

<div *ngIf='formGroup && enterpriseGroup' [formGroup]='formGroup'>
  <div class='max-w-[650px]'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      formField='name'
                                      fieldKey='enterpriseGroups.name'
                                      [required]='true'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{formGroup.get('name').value}}</span>
      <input *ngIf='formGroup.enabled'
             class="w-full"
             formControlName="name"
             id="name"
             pInputText
             type="text"
      />
    </alimento-ipv-frontend-form-field>

    <div class='grid grid-cols-3 gap-4' *ngIf='enterpriseGroup.enterpriseGroupId'>
      <alimento-ipv-frontend-form-field fieldKey='branches.numberOfEmployees'
                                        [required]='true'>
        <span class='read-only-field'>{{ enterpriseGroup.numberOfEmployees }}</span>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field fieldKey='branches.numberOfClerks'
                                        [required]='true'>
        <span class='read-only-field'>{{ enterpriseGroup.numberOfClerksPC220 }}</span>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field fieldKey='branches.numberOfWorkers'
                                        [required]='true'>
        <span class='read-only-field'>{{ enterpriseGroup.numberOfWorkersPC118 }}</span>
      </alimento-ipv-frontend-form-field>
    </div>

    <alimento-ipv-frontend-form-field
      *ngIf='showMainEnterprise'
      [formGroup]='formGroup'
      [required]='true'
      fieldKey='enterpriseGroups.mainEnterprise'
      formField='mainEnterprise'
    >
      <span *ngIf='formGroup.disabled || enterpriseGroup.mainEnterprise?.enterpriseId' class='read-only-field'>
        {{enterpriseGroup.mainEnterprise.name}}
      </span>
      <div *ngIf='formGroup.enabled && !enterpriseGroup.mainEnterprise?.enterpriseId'>
        <alimento-ipv-frontend-enterprise-select formControlName='mainEnterprise'
                                                 id='mainEnterprise'
                                                 [extraFilters]='activeFilter'
                                                 [showHasGroup]='true'>
        </alimento-ipv-frontend-enterprise-select>
      </div>
    </alimento-ipv-frontend-form-field>

    <div *ngIf='mainEnterprise && showMainEnterprise' >
      <div class='bg-grey-0 p-4 mb-8'>
          <div>
            <h4 class='font-bold text-lg'>{{'reimbursementRequests.basicBranchData' | translate}}</h4>
            <div class='grid grid-cols-[minmax(0,_2fr)_minmax(0,_1fr)] gap-4 gap-y-0'>
              <alimento-ipv-frontend-form-field fieldKey='enterprises.enterpriseName'
                                                [required]='true'>
                <span class='read-only-field'>{{ mainEnterprise.name }}</span>
              </alimento-ipv-frontend-form-field>

              <alimento-ipv-frontend-form-field fieldKey='branches.companyNumber'
                                                [required]='true'>
                <span class='read-only-field'>{{ mainEnterprise.companyNumber }}</span>
              </alimento-ipv-frontend-form-field>

              <alimento-ipv-frontend-form-field fieldKey='branches.vatNumber'
                                                [required]='true'>
                <span class='read-only-field'>{{ mainEnterprise.vatNumber }}</span>
              </alimento-ipv-frontend-form-field>

              <alimento-ipv-frontend-form-field fieldKey='branches.rszNumber'
                                                [required]='true'>
                <span class='read-only-field'>{{ mainEnterprise.rszNumber }}</span>
              </alimento-ipv-frontend-form-field>
            </div>
          </div>
          <div>
          </div>
        </div>
    </div>
  </div>
</div>

<ng-container *ngFor="let dataItem of data; let i = index;">
  <lib-card *ngIf="!useShowMore || showMore || i < showMoreCount"
            (click)="onClickEvent(dataItem, $event)"
            (dblclick)="onDoubleClickEvent(dataItem, $event)"
            [selectable]="selectable && triggerSelectOnClick"
            [elementClass]="elementClass"
            [navigable]="false"
            [styling]="styling"
            [isSelected]="isSelected(dataItem)">
    <div *ngIf="selectable && multiSelect">
      <p-checkbox [binary]="true"
                  (click)="selectionChange(dataItem, $event)"
                  [attr.index]="i"
                  [name]="viewGuid + i"
                  [value]="isSelected(dataItem)"
                  [disabled]="!selectableFunction(dataItem)"
      ></p-checkbox>
    </div>
    <div class="w-full ml-4">
      <ng-container *ngTemplateOutlet="cardTemplate; context: {item: dataItem, selected: isSelected(dataItem)}"></ng-container>
    </div>
    <div *ngIf="deletable" class="w-12 flex flex-col items-center">
      <i *ngIf="deletableFunction(dataItem)" (click)="deleteItem(dataItem, i, $event)"
         class="{{deleteIcon}} text-grey hover:text-black cursor-pointer"></i>
    </div>
  </lib-card>
</ng-container>
<div *ngIf="useShowMore && !showMore && data?.length > showMoreCount"
     (click)="showMore = true"
     class="flex w-full border border-purple-10 justify-center gap-2 items-center
     text-purple hover:text-purple-light py-2 cursor-pointer">
  <i class="fa fa-angles-down"></i>
  <span>{{'showMore' | translate}}</span>
</div>
<div *ngIf="data.length === 0 && noDataText" class="mt-4">
  <span>{{noDataText | translate}}</span>
</div>


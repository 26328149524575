import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MoveTrainingEvent, Training } from '../../../types/training.type';
import { FilterType, MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';

@Component({
  selector: 'alimento-ipv-frontend-move-training-dialog',
  templateUrl: './move-training-dialog.component.html'
})
export class MoveTrainingDialogComponent implements OnChanges {
  @Input()
  training: Training;

  @Input()
  firstSessionDate?: Date;

  @Input()
  groupId?: string;

  @Output()
  moveTraining = new EventEmitter<MoveTrainingEvent>();

  dialogVisible = false;
  moveTrainingForm: FormGroup;
  loading = false;
  groupIdFilter: { values: string[]; type: FilterType }[];

  constructor(private fb: FormBuilder, private messageService: MyMessageService) {
    this.moveTrainingForm = this.fb.group({
      trainingAllowanceApplication: ['', Validators.required]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['groupId']?.currentValue) {
      this.groupIdFilter = [{type: FilterType.filterEnterpriseGroupId, values: [this.groupId]}];
    }
  }

  open(): void {
    this.dialogVisible = true;
    this.loading = false;
  }

  clone() {
    validateAllFormFields(this.moveTrainingForm);
    if (!this.moveTrainingForm.valid) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.moveTraining.emit({
      trainingId: this.training.trainingId,
      trainingAllowanceApplicationId: this.moveTrainingForm.value.trainingAllowanceApplication.trainingAllowanceApplicationId,
      setLoading: state => this.loading = state
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.moveTrainingForm.reset();
  }
}

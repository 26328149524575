<p-dialog
  (blur)="popupVisible = false"
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [closable]="true"
  [styleClass]="'full-screen-dialog'"
  appendTo="body"
  *ngIf="popupVisible"
>
  <ng-template pTemplate="header">
    <div class="dialog-header-content">
      <span class="p-dialog-title">{{titleObject.title | translate}}</span>
    </div>
  </ng-template>

  <div class="dialog-body-content" [formGroup]="formGroup" *ngIf="formGroup">
    <alimento-ipv-frontend-header title='enrollments.participant'>
    </alimento-ipv-frontend-header>

    <alimento-ipv-frontend-person
      [dataOptional]="false"
      [showPersonalContactData]="enrollment?.enrollment.enrollmentViaId === ENROLLMENT_VIA.NO_COMPANY"
      [person]="$any(enrollment?.person)"
      [readOnly]='true'
      name="personForm"
    ></alimento-ipv-frontend-person>

    <alimento-ipv-frontend-extra-form
      formControlName="extraForm"
      name="extraForm"
      [training]='training'
      [branchId]="enrollment?.enrollment.branch.data"
      [person]="$any(enrollment?.person)"
      [enrollment]='enrollment'
      [showContactInfo]='false'
      [showBranchContactPersonInfo]='false'
      [groupId]='groupId'
      [enterpriseId]='enterpriseId'
      [trainingStartDate]='trainingStartDate'
      [showRestructuringInfo]='false'
    >
    </alimento-ipv-frontend-extra-form>
  </div>

  <ng-template pTemplate="footer">
    <div class="dialog-footer-content">
      <p-button
        (onClick)="closePopup()"
        icon="pi pi-times"
        iconPos="left"
        label="{{ 'trainings.sessions.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>

      <div *ngIf='!readOnly'>
        <p-button
          (onClick)="submit()"
          [loading]="loading"
          label="{{ titleObject.complete | translate}}"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>

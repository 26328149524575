import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { DateMapper, FormComponent } from '@alimento-ipv-frontend/ui-lib';
import { Enterprise } from '../../../types/enterprise.type';
import { ValidationService } from '../../../services/validation.service';
import { first } from 'rxjs';
import { DataLabelType } from '../../../types/reference-data.type';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-enterprise',
  templateUrl: './enterprise.component.html',
  providers: [
    { provide: FormComponent, useExisting: EnterpriseComponent }
  ]
})
export class EnterpriseComponent extends FormComponent implements OnChanges {
  @Input()
  enterprise: Enterprise;

  @Input()
  readOnly = false;

  naceCodes: DataLabelType[] = [];
  naceCodesReadOnly: string;

  constructor(private formBuilder: FormBuilder,
              private validationService: ValidationService,
              private referenceDataService: ReferenceDataService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enterprise']?.currentValue) {
      this.createEnterpriseFormGroup(this.enterprise);
    }

    super.setDisabledState(this.readOnly);
  }

  override getData(): any {
    const data = super.getData();

    if (data.enterpriseGroup?.enterpriseGroupId) {
      data.enterpriseGroupId = data.enterpriseGroup.enterpriseGroupId;
    }
    data.rszCancellationDate = DateMapper.getDateFromDateTimeAsString(data.rszCancellationDate);

    return data;
  }

  get rszCodes(): FormArray {
    return this.formGroup.controls['rszCodes'] as FormArray;
  }

  private createEnterpriseFormGroup(enterprise?: Enterprise): void {
    this.formGroup = this.formBuilder.group({
      enterpriseId: [enterprise?.enterpriseId],
      enterpriseName: [enterprise?.enterpriseName, Validators.required],
      companyNumber: [enterprise?.companyNumber, {
        updateOn: 'blur',
        validators: [Validators.required],
        asyncValidators: [this.validationService.validateCompanyNumber()]
      }],
      vatNumber: [enterprise?.vatNumber, {
        updateOn: 'blur',
        asyncValidators: [this.validationService.validateVATNumber()]
      }],
      rszNumber: [enterprise?.rszNumber, {
        updateOn: 'blur',
        asyncValidators: [this.validationService.validateRSZNumber()]
      }],
      rszCodes: this.formBuilder.array([]),
      numberOfEmployees: [enterprise?.numberOfEmployees || 0],
      numberOfClerksPC220: [enterprise?.numberOfClerksPC220 || 0],
      numberOfWorkersPC118: [enterprise?.numberOfWorkersPC118 || 0],
      isFishing: [enterprise?.isFishing || false],
      isFood: [enterprise?.isFood || false],
      isFoodTrade: [enterprise?.isFoodTrade || false],
      rszCancellationDate: [enterprise?.rszCancellationDate ? new Date(enterprise.rszCancellationDate) : undefined],
      naceCodesIds: [enterprise?.naceCodesIds || []],
      isEducationalInstitution: [enterprise?.isEducationalInstitution || false],
      enterpriseGroup: [enterprise?.enterpriseGroupId ? {enterpriseGroupId: enterprise.enterpriseGroupId} : undefined]
    });
    super._addChangeListener();

    this._fillRszCodesData();

    this.referenceDataService.getNaceCodes().pipe(first())
      .subscribe(naceCodes => {
        this.naceCodes = naceCodes;
        if (this.enterprise?.naceCodesIds) {
          this.naceCodesReadOnly = naceCodes.filter(code => this.enterprise.naceCodesIds.includes(code.data))
            .map(code => code.label)
            .join(", ");
        }
      })
  }

  private _fillRszCodesData(): void {
    if (this.enterprise?.rszCodes?.length > 0) {
      this.enterprise.rszCodes.forEach(rszCode => {
        this.addRszCode(rszCode, true);
      });
    }
    else {
      this.addRszCode('', true);
    }
  }

  addRszCode(rszCode?: string, ignoreEvent?: boolean ) {
    const control = this.formBuilder.control(rszCode);
    this.rszCodes.push(control, {emitEvent: !ignoreEvent});
    if (!ignoreEvent) {
      this.formGroup.markAsDirty();
      this.changes.emit(true);
    }
  }

  deleteRszCode(index: number) {
    this.rszCodes.removeAt(index);
    this.formGroup.markAsDirty();
    this.changes.emit(true);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { EnterpriseGroupDetailComponent } from './pages/enterprise-group-detail/enterprise-group-detail.component';
import { EnterpriseGroupComponent } from './components/enterprise-group/enterprise-group.component';
import { InputTextModule } from 'primeng/inputtext';
import { EnterpriseGroupCardComponent } from './components/enterprise-group-card/enterprise-group-card.component';
import { UtilsModule } from '../utils/utils.module';
import { EnterpriseGroupListComponent } from './pages/enterprise-group-list/enterprise-group-list.component';
import { FormsModule } from '@angular/forms';
import { SelectEnterpriseGroupComponent } from './components/select-enterprise-group/select-enterprise-group.component';
import { SelectEnterprisePopupComponent } from './components/select-enterprise-popup/select-enterprise-popup.component';

@NgModule({
  imports: [
    CommonModule,
    UiLibModule,
    InputTextModule,
    UtilsModule,
    FormsModule
  ],
  providers: [
    CanDeactivateGuard
  ],
  declarations: [
    EnterpriseGroupDetailComponent,
    EnterpriseGroupComponent,
    EnterpriseGroupCardComponent,
    EnterpriseGroupListComponent,
    SelectEnterpriseGroupComponent,
    SelectEnterprisePopupComponent
  ],
  exports: [
    EnterpriseGroupCardComponent,
    SelectEnterpriseGroupComponent
  ]
})
export class EnterpriseGroupModule {
}

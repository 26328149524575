export enum FEATURE {
  OPEN_EXTERN_AND_CUSTOM_TRAININGS = "OpenExternAndCustomTraining",
  ACCOUNT_NUMBER = "AccountNumber",
  BRANCH_AND_ENTERPRISE_MANAGEMENT = "BranchAndEnterpriseManagement",
  FINANCIAL_DOCUMENTS = "FinancialDocument",
  CEVORA_TRAINING = "CevoraTraining",
  EDUCATIONAL_TRAINING = "EducationalTraining",
  TRANSITION = "Transition",
  ENTERPRISE_SME_BONUS = "EnterpriseSmeBonus",
  REIMBURSEMENT = "Reimbursement",
}

export enum NOTES_TYPE {
  TrainingNote = 0,
  PersonNote = 1,
  BranchNote = 2,
  AllowanceApplicationNote = 3,
  EnterpriseNote = 4
}

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc'
}

export const NO_BRANCH_ID = "00000001-0001-0001-0001-000000000001";

export enum HISTORY_TYPE {
  TRAINING_PLAN = "trainingPlan",
  ENTERPRISE_GROUP = "enterpriseGroup"
}

export const MAX_FETCH_COUNT = 3;

import { Component, HostListener, OnDestroy, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import {
  AuthorizationService,
  CanComponentDeactivate,
  ExtraMenuItem,
  FilterType,
  HISTORY_TYPE,
  HistoryItem,
  IFilter,
  InfiniteScrollDataAdapter,
  LeaveConfirmService,
  MyMessageService,
  PaginatedResponse,
  Role,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TabMenuItem,
  TitleService,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { first, map, Observable, of, Subscription, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { EnterpriseGroupComponent } from '../../components/enterprise-group/enterprise-group.component';
import { EnterpriseGroup, UpdateEnterpriseGroup } from '../../../types/enterprise-group.type';
import { EnterpriseGroupService } from '../../../services/enterprise-group.service';
import { SearchEnterpriseItem } from '../../../types/enterprise.type';
import { ENTERPRISE_GROUP_ACTION } from '../../../types/enterprise-group.enum';
import { SearchesService } from '../../../services/searches.service';
import { BranchesMapper } from '../../../utils/mapper/branches.mapper';
import { ENTERPRISE_ACTION } from '../../../types/enterprise.enum';

@Component({
  selector: 'alimento-ipv-frontend-enterprise-group-detail',
  templateUrl: './enterprise-group-detail.component.html'
})
export class EnterpriseGroupDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  enterpriseGroupId: string;
  enterpriseGroup: EnterpriseGroup;

  enterprisesCount: WritableSignal<number> = signal(undefined);
  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  activeTabIndex = 0;
  readOnly = false;
  saving = false;

  searchEnterpriseData$: InfiniteScrollDataAdapter<SearchEnterpriseItem>;
  searchEnterpriseFilters: SearchFilter[];
  hasChanges = false;

  @ViewChild(EnterpriseGroupComponent)
  basicInfoComponent: EnterpriseGroupComponent;

  private _subscriptions: Subscription[] = [];
  historyItems: HistoryItem[];
  showHistoryItems = false;

  protected readonly ENTERPRISE_GROUP_ACTION = ENTERPRISE_GROUP_ACTION;

  constructor(
    private translateService: TranslateService,
    private enterpriseGroupService: EnterpriseGroupService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private titleService: TitleService,
    private location: Location,
    private messageService: MyMessageService,
    private leaveConfirmationService: LeaveConfirmService,
    private authorizationService: AuthorizationService,
    private searchesService: SearchesService,
    private branchesMapper: BranchesMapper
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this._createMenuItems();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = 'Are you sure';
    }
  }

  ngOnInit() {
    this.route.params.pipe(first())
      .subscribe(params => {
        this.enterpriseGroupId = params['enterpriseGroupId'];
        if (this.enterpriseGroupId) {
          this._fetchEnterpriseGroupData();
        }
        else {
          this.enterpriseGroup = {} as EnterpriseGroup;
        }
      });

    this.route.queryParams.pipe(first())
      .subscribe(queryParams => this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.hasChanges) {
      return true;
    }

    return this.leaveConfirmationService.leaveDialog(() => this._saveEnterpriseGroup());
  }

  private _fetchEnterpriseGroupData(): void {
    this.enterpriseGroupService.getEnterpriseGroup(this.enterpriseGroupId)
      .pipe(first())
      .subscribe({
        next: enterpriseGroup => {
          this.enterpriseGroup = enterpriseGroup;
          if (!this.enterpriseGroup.enterpriseIds) {
            this.enterpriseGroup.enterpriseIds = [];
          }
          this.titleService.setTitle([this.translateService.instant('enterpriseGroups.enterpriseGroup'), enterpriseGroup.name]);
          this._createMenuItems();
          this._loadEnterprisesCount();
          this._createEnterprisesSearch();
        },
        error: () => {
          this._navigateToErrorPage();
        }
      });
  }

  private _navigateToErrorPage(): void {
    this.router.navigate(['error'], {
      state: {
        message: this.translateService.instant('error.itemWithIdDoesNotExist', {
          item: this.translateService.instant('enterpriseGroups.enterpriseGroup'),
          id: this.enterpriseGroupId
        }),
        redirectUrl: '/'
      }
    });
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem) {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  saveEnterpriseGroup(): void {
    this._saveEnterpriseGroup().subscribe();
  }

  addEnterprise(enterpriseId: string): void {
    const enterpriseGroup: UpdateEnterpriseGroup = {
      enterpriseGroupId: this.enterpriseGroupId,
      name: this.enterpriseGroup.name,
      enterpriseIds: [...this.enterpriseGroup.enterpriseIds, enterpriseId],
      mainEnterpriseId: this.enterpriseGroup.mainEnterprise.enterpriseId
    };

    this.enterpriseGroupService.updateEnterpriseGroup(this.enterpriseGroupId, enterpriseGroup).pipe(first())
      .subscribe(() => {
        this.messageService.success();
        this._fetchEnterpriseGroupData();
      });
  }

  removeEnterprise(toDeleteId: string): void {
    const enterpriseGroup: UpdateEnterpriseGroup = {
      enterpriseGroupId: this.enterpriseGroupId,
      name: this.enterpriseGroup.name,
      enterpriseIds: this.enterpriseGroup.enterpriseIds.filter(enterpriseId => enterpriseId !== toDeleteId),
      mainEnterpriseId: this.enterpriseGroup.mainEnterprise.enterpriseId
    };
    this.enterpriseGroupService.updateEnterpriseGroup(this.enterpriseGroupId, enterpriseGroup).pipe(first())
      .subscribe(() => {
        this.messageService.success();
        this._fetchEnterpriseGroupData();
      });
  }

  setAsMainEnterprise(enterpriseId: string): void {
    const enterpriseGroup: UpdateEnterpriseGroup = {
      enterpriseGroupId: this.enterpriseGroupId,
      name: this.enterpriseGroup.name,
      enterpriseIds: this.enterpriseGroup.enterpriseIds,
      mainEnterpriseId: enterpriseId
    };
    this.enterpriseGroupService.updateEnterpriseGroup(this.enterpriseGroupId, enterpriseGroup).pipe(first())
      .subscribe(() => {
        this.messageService.success();
        this._fetchEnterpriseGroupData();
      });
  }

  private _setDetailUrl(): void {
    if (this.enterpriseGroupId) {
      this.location.replaceState(
        this.router
          .createUrlTree(['/enterprise-groups', this.enterpriseGroupId], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.tabMenuItems = [
          { name: 'basic', index: 0, title: this.translateService.instant('reimbursementRequests.basicDataTitle') },
          {
            name: 'enterprises',
            index: 1,
            disabled: !this.enterpriseGroup?.enterpriseGroupAlimentoId,
            title: this.translateService.instant('enterpriseGroups.enterprisesTitle'),
            count: this.enterprisesCount
          }
        ];

        this.extraMenuItems = [
          {
            name: 'history',
            title: this.translateService.instant('reimbursementRequests.historyTitle'),
            disabled: !this.enterpriseGroupId,
            command: () => {
              this.showHistoryItems = true;
              this.historyItems = this.enterpriseGroup.actions.map(action => ({
                id: action.actionId,
                actionExecutedBy: action.executedBy,
                actionExecutedById: action.executedById,
                actionExecutedOn: action.executedOn,
                actionId: action.actionId,
                actionName: action.actionType,
                comments: action.comments,
                historyType: HISTORY_TYPE.ENTERPRISE_GROUP,
                data: {
                  enterpriseId: action?.enterpriseId,
                  enterpriseName: action?.enterpriseName
                }
              }));
            }
          }
        ];
      })
    );
  }

  private _loadEnterprisesCount(): void {
    if (this.enterpriseGroup) {
      const searchRequest: SearchRequest = { filters: [] };
      searchRequest.filters.push({
        type: FilterType.filterEnterpriseGroupId,
        values: [this.enterpriseGroupId]
      });
      this.searchesService.getEnterprisesCount(searchRequest).pipe(first())
        .subscribe(count => {
          this.enterprisesCount.set(count);
        });
    }
  }

  private _createEnterprisesSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterEnterpriseGroupId,
      values: [this.enterpriseGroupId]
    });

    this.searchEnterpriseData$ = new InfiniteScrollDataAdapter<SearchEnterpriseItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<SearchEnterpriseItem>> => {
      return this.searchesService.searchEnterprises(searchRequest);
    }, searchRequest, true);

    this.searchEnterpriseFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      },
      {
        type: SearchFilterType.multiselect,
        label: 'enterprises.status',
        key: FilterType.filterBranchState,
        data: this.branchesMapper.getStatesFilter()
      },
      {
        type: SearchFilterType.multiselect,
        label: 'enterprises.category',
        key: FilterType.filterBranchCategory,
        hasIcon: true,
        iconMapper: (value) => this.branchesMapper.getCategoryOptions()
          .filter(category => category.value === value)[0].icon,
        data: of(this.branchesMapper.getCategoryOptions(true))
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterBranchCategory,
              label: type.label,
              value: type.value
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'branches.numberOfEmployees',
        key: FilterType.filterNumberEmployees,
        data: this.branchesMapper.getNumberOfEmployeesOptions()
      }
    ];
  }

  private _saveEnterpriseGroup(): Observable<any> {
    if (!this.basicInfoComponent.isValid()) {
      this.messageService.notAllFieldsValid();

      return new Observable<{ id: string }>((observable) => observable.error());
    }

    this.saving = true;
    const data = this.basicInfoComponent.getData() as UpdateEnterpriseGroup;

    return (this.enterpriseGroupId ? this.enterpriseGroupService.updateEnterpriseGroup(this.enterpriseGroupId, data) :
      this.enterpriseGroupService.createEnterpriseGroup(data))
      .pipe(first(),
        tap({
          next: (response: { id: string }) => {
            this.enterpriseGroupId = response.id;
            this._fetchEnterpriseGroupData();
            this._setDetailUrl();
            this.messageService.success();
            this.saving = false;
            this.hasChanges = false;
          },
          error: () => {
            this.saving = false;
          }
        }));
  }

  enterpriseActionClicked(enterpriseId: string, event: ENTERPRISE_ACTION): void {
    if (event === ENTERPRISE_ACTION.delete) {
      this.removeEnterprise(enterpriseId);
    }
    else if (event === ENTERPRISE_ACTION.setAsMainEnterprise) {
      this.setAsMainEnterprise(enterpriseId);
    }
  }
}

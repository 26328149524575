<div class="items-center gap-2 grid
{{showHasGroup ? 'grid-cols-[minmax(0,_1fr)_150px_100px_100px_150px]' : 'grid-cols-[minmax(0,_1fr)_150px_100px_100px]'}}">
  <div isEllipsis pTooltip>
    <span *ngIf="enterprise">{{ enterprise.name }}</span>
    <span *ngIf="!enterprise" class="font-bold">{{ 'enterprises.enterpriseName' | translate }}</span>
  </div>

  <div isEllipsis pTooltip>
    <span *ngIf="enterprise">{{ enterprise.vatNumber }}</span>
    <span *ngIf="!enterprise" class="font-bold">{{ 'branches.vatNumber' | translate }}</span>
  </div>

  <div isEllipsis pTooltip>
    <span *ngIf="enterprise">{{ enterprise.rszNumber }}</span>
    <span *ngIf="!enterprise" class="font-bold">{{ 'branches.rszNumber' | translate }}</span>
  </div>

  <div isEllipsis pTooltip>
    <span *ngIf="enterprise">{{ enterprise.enterpriseNumber }}</span>
    <span *ngIf="!enterprise" class="font-bold">{{ 'enterprises.companyNumber' | translate }}</span>
  </div>

  <div isEllipsis pTooltip *ngIf='showHasGroup'>
    <span *ngIf="enterprise">{{ enterprise.enterpriseGroupName }}</span>
    <span *ngIf="!enterprise" class="font-bold">{{ 'enterprises.enterpriseGroup' | translate }}</span>
  </div>
</div>

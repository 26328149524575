<alimento-ipv-frontend-header
  title="{{ 'enrollments.popup.branchesTitle' | translate }}"
></alimento-ipv-frontend-header>

<div [formGroup]="formGroup">
  <div class="form-section">
    <div class="max-w-[425px] mb-4">
      <label for="enrollmentViaId" class="block">
        {{ (isInstitution ? 'enrollments.institutionVia' : 'enrollments.enrollmentVia') | translate }}
      </label>

      <span *ngIf='showEnrollmentViaReadOnly'>{{enrollmentViaReadOnly}}</span>
      <p-dropdown
        #viaComponent
        *ngIf='!showEnrollmentViaReadOnly'
        id="enrollmentViaId"
        formControlName="enrollmentViaId"
        [options]="enrollmentViaOptions"
        optionValue="data"
        optionLabel="label"
        placeholder="{{ 'enrollments.chooseOption' | translate }}"
        styleClass="w-full"
        [showClear]="true"
        appendTo='body'
      ></p-dropdown>
    </div>

    <div [hidden]="(formGroup.get('enrollmentViaId')?.value !== ENROLLMENT_VIA.COMPANY &&
    formGroup.get('enrollmentViaId')?.value !== ENROLLMENT_VIA.CEVORA) || enrollmentId">
      <alimento-ipv-frontend-branch-select formControlName="branch"
                                           [placeholder]="(isInstitution ? 'enrollments.searchInstitution' : 'branches.searchPlaceholder') | translate"
      ></alimento-ipv-frontend-branch-select>

      <small
        *ngIf="formGroup.get('branch')?.dirty && formGroup.get('branch')?.hasError('required')"
        class="p-error block"
      >
        {{ 'branches.selectBranchRequired' | translate }}
      </small>
    </div>
  </div>

  <ng-container>
    <small
      *ngIf="selectedBranch && selectedBranch.branchAlimentoId && isMaxOfCompany && !isInstitution"
      class="flex w-full items-center p-2 my-2 bg-warning-bg text-warning rounded"
    >
      <span class="material-symbols-outlined mr-2"> warning </span>
      {{ 'branches.formErrors.maxEnrollmentsOfCompany' | translate }}
    </small>
  </ng-container>

  <div class="px-4 py-2 bg-grey-0 mt-2" *ngIf="selectedBranch && selectedBranch.branchAlimentoId">
    <div class="p-1">
      <h3>{{ 'branches.basicDataTitle' | translate }}</h3>
      <div class='pl-2'>
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='true'
          fieldKey='branches.branchName'
        >
          <span>{{ selectedBranch.branchName || '-' }} </span>
        </alimento-ipv-frontend-form-field>

        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='true'
          fieldKey='branches.vatNumber'
        >
          <span>{{ selectedBranch.vatNumber || '-' }} </span>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>

    <div class='p-1'>
      <h3>{{ 'branches.branchAddress' | translate }}</h3>
      <div class="pl-2 flex">
        <div class="basis-1/2">
          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            [required]='true'
            fieldKey='branches.street'
          >
            <span>{{ selectedBranch.street || '-' }} </span>
          </alimento-ipv-frontend-form-field>

          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            [required]='true'
            fieldKey='branches.city'
          >
            <span>{{ selectedBranch.city || '-' }} </span>
          </alimento-ipv-frontend-form-field>

          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            [required]='true'
            fieldKey='branches.country'
          >
            <span>{{ selectedBranch.country || '-' }} </span>
          </alimento-ipv-frontend-form-field>
        </div>

        <div class="basis-1/2">
          <div class="flex gap-8">
            <alimento-ipv-frontend-form-field
              [formGroup]='formGroup'
              [required]='true'
              fieldKey='branches.houseNumber'
            >
              <span>{{ selectedBranch.houseNumber || '-' }} </span>
            </alimento-ipv-frontend-form-field>

            <alimento-ipv-frontend-form-field
              [formGroup]='formGroup'
              [required]='true'
              fieldKey='branches.mailBox'
            >
              <span>{{ selectedBranch.mailbox || '-' }} </span>
            </alimento-ipv-frontend-form-field>
          </div>

          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            [required]='true'
            fieldKey='branches.postalCode'
          >
            <span>{{ selectedBranch.postalCode || '-' }} </span>
          </alimento-ipv-frontend-form-field>
        </div>
      </div>
    </div>

    <div class='p-1' *ngIf='formGroup.get("enrollmentViaId")?.value === ENROLLMENT_VIA.COMPANY'>
      <h3>{{ 'branches.invoicing' | translate }}</h3>

      <div class='pl-2'>
        <div class='pb-4'>
          <p-checkbox
            formControlName="invoicingSame"
            [binary]="true"
            label="{{ 'branches.invoicingSame' | translate }}"
          ></p-checkbox>
        </div>

        <div class="p-4 pb-1 mb-2 bg-grey-1" [hidden]="formGroup.get('invoicingSame')?.value">
          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            formField='invoiceName'
            fieldKey='branches.invoiceName'
            [required]="!formGroup.get('invoicingSame')?.value"
          >
            <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('invoiceName')?.value}}</span>
            <input *ngIf='formGroup.enabled' class="w-full"
                   formControlName="invoiceName" id="invoiceName" pInputText type="text" />
          </alimento-ipv-frontend-form-field>

          <alimento-ipv-frontend-address
            [formControl]="$any(formGroup.get('invoiceAddress'))"
            [optional]="this.formGroup.get('invoicingSame')?.value"
            [readOnly]='formGroup.disabled'
          ></alimento-ipv-frontend-address>
        </div>

        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [errorTemplate]='emailErrorTemplate'
          formField='invoiceEmail'
          fieldKey='branches.email'
        >
          <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('invoiceEmail')?.value}}</span>
          <input *ngIf='formGroup.enabled' class="w-full"
                 formControlName="invoiceEmail" id="invoiceEmail" pInputText type="text" />
          <ng-template #emailErrorTemplate>
            <small *ngIf="formGroup.get('invoiceEmail')?.hasError('email')">
              {{ 'branches.formErrors.invoiceEmailNotCorrect' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>

        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          formField='invoiceReference'
          fieldKey='branches.reference'
        >
          <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('invoiceReference')?.value}}</span>
          <input *ngIf='formGroup.enabled' class="w-full"
                 formControlName="invoiceReference" id="invoiceReference" pInputText type="text" />
        </alimento-ipv-frontend-form-field>
      </div>
    </div>

    <div class='p-1' *ngIf='formGroup.get("enrollmentViaId")?.value === ENROLLMENT_VIA.COMPANY && !isInstitution && selectedBranch?.isFood'>
      <h3>{{ 'branches.extra' | translate }}</h3>

      <div class="pl-2">
        <label class="block mb-2">{{ 'branches.trainingPlan' | translate }}</label>

        <div class="field-radiobutton mb-1">
          <p-radioButton
            label="{{ 'yes' | translate }}"
            name="trainingPlan"
            [value]="true"
            formControlName="trainingPlan"
            id="training-plan-yes"
          ></p-radioButton>
        </div>

        <div class="field-radiobutton">
          <p-radioButton
            label="{{ 'no' | translate }}"
            name="trainingPlan"
            [value]="false"
            formControlName="trainingPlan"
            id="training-plan-false"
          ></p-radioButton>
        </div>

        <small
          *ngIf="
            (formGroup.get('trainingPlan')?.dirty || formGroup.get('trainingPlan')?.touched) &&
            formGroup.get('trainingPlan')?.hasError('required')
          "
          class="p-error block"
        >
          {{ 'validation.noValueSelected' | translate }}
        </small>

        <div *ngIf='trainingPlan' class='flex items-center gap-1 italic text-text text-sm'>
          <span *ngIf='trainingPlan.validFrom'>{{'branches.latestTrainingPlan' | translate}}</span>
          <span *ngIf='trainingPlan.validFrom'>{{trainingPlan.validFrom | date:"d MMMM yyyy"}} - {{trainingPlan.validTo | date:"d MMMM yyyy"}}</span>
          <span *ngIf='!trainingPlan.validFrom'>{{'branches.trainingPlans.current' | translate}}</span>
          <span *ngIf='trainingPlanState' class='font-bold'>{{trainingPlanState | lowerFirstLetter}}</span>
          <span>
              {{'branches.trainingPlans.byOn' | translate:
            {by: trainingPlan.actionExecutedBy || ('branches.trainingPlans.noName' | translate),
              on: trainingPlan.actionExecutedOn ? (trainingPlan.actionExecutedOn | date : 'dd/MM/yyyy') : ('branches.trainingPlans.noName' | translate)} }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DateMapper,
  flattenObject,
  MyMessageService,
  NO_BRANCH_ID,
  SelectionChangedEvent,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { first, Subscription } from 'rxjs';
import {
  EnrollmentRequest,
  EnrollmentRequestParticipant,
  ParticipantUpdateEvent
} from '../../../types/enrollment.type';
import { Person, SearchPersonItem } from '../../../types/person.type';
import { SearchesService } from '../../../services/searches.service';
import { EnrollmentExtraFormComponent } from '../enrollment-extra-form/enrollment-extra-form.component';
import { PersonComponent } from '../../../persons/components/person/person.component';
import { ENROLLMENT_VIA } from '../../../types/enrollment.enum';
import { PersonService } from '../../../services/person.service';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-enrollment-request-participant-validation-popup',
  templateUrl: './enrollment-request-participant-validation-popup.component.html'
})
export class EnrollmentRequestParticipantValidationPopupComponent implements OnChanges, OnDestroy {
  @Input()
  participant: EnrollmentRequestParticipant;

  @Input()
  enrollmentRequest: EnrollmentRequest;

  @Input()
  groupId?: string;

  @Input()
  enterpriseId?: string;

  @Input()
  readOnly = false;

  @Input()
  isOpenExternOrCustom: boolean;

  @Input()
  trainingStartDate?: Date;

  @Output()
  formSubmit = new EventEmitter<ParticipantUpdateEvent>();

  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;

  readonly PERSON_FORM_TYPE_SUGGESTED: string = "suggested";
  readonly PERSON_FORM_TYPE_EXISTING: string = "existing";
  readonly PERSON_FORM_TYPE_NEW: string = "new";

  personFormControl = new FormControl<string>(this.PERSON_FORM_TYPE_SUGGESTED);
  searchPersonFormControl = new FormControl<SearchPersonItem>(undefined);
  suggestedPersonMatches: SearchPersonItem[] = [];
  selectedPerson?: SearchPersonItem;
  personDetail: Person;
  statute: string;

  private _subscriptions: (Subscription | undefined)[] = [];
  protected readonly ENROLLMENT_VIA = ENROLLMENT_VIA;
  protected readonly NO_BRANCH_ID = NO_BRANCH_ID;

  @ViewChild(EnrollmentExtraFormComponent)
  extraFormComponent: EnrollmentExtraFormComponent;

  @ViewChild(PersonComponent)
  personForm: PersonComponent;

  constructor(private formBuilder: FormBuilder,
              private searchesService: SearchesService,
              private referenceDataService: ReferenceDataService,
              private personService: PersonService,
              private messageService: MyMessageService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['participant']?.currentValue) {
      this._createFormGroup();

      if (this.participant?.workStatusId) {
        this.referenceDataService.getWorkStatus(this.participant.workStatusId).pipe(first())
          .subscribe(workStatus => this.statute = workStatus.label);
      }

      this.searchesService.searchPersonMatches({
        firstName: this.participant.firstName,
        lastName: this.participant.lastName,
        professionalEmail: this.participant.employmentEmail || this.participant.email,
        professionalPhoneNumber: this.participant.employmentPhoneNumber || this.participant.phoneNumber,
        birthDate: this.participant.dateOfBirth,
        employmentBranchAlimentoId: this.enrollmentRequest.branchAlimentoId,
        employerId: this.participant.employerId,
        nationalIdentificationNumber: this.participant.nationalIdentificationNumber,
        maxResults: 10
      }).pipe(first())
        .subscribe(result => this.suggestedPersonMatches = result);

      if (!this.participant.personId) {
        if (!this.participant.cost) {
          this.personFormControl.setValue(this.PERSON_FORM_TYPE_SUGGESTED);
        }
        else {
          this.personFormControl.setValue(this.PERSON_FORM_TYPE_NEW);
        }
      }
      else {
        this.personService.getPerson(this.participant.personId).pipe(first())
          .subscribe(person => {
            this.selectedPerson = person;
            this.personDetail = this._getPersonDetailFromPerson(person);
            this.searchPersonFormControl.setValue(person, { emitEvent: false });
          });

        this.personFormControl.setValue(this.PERSON_FORM_TYPE_EXISTING);
      }
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    this._addSubscriptions();
    this._createFormGroup();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
    this.selectedPerson = undefined;
    this.personDetail = undefined;
    this.searchPersonFormControl.setValue(undefined);
    this.personFormControl.setValue(this.PERSON_FORM_TYPE_SUGGESTED);
    this.suggestedPersonMatches = [];
  }

  private _isPersonValid(): boolean {
    return this.personForm.isValid() && (this.personFormControl.value === this.PERSON_FORM_TYPE_NEW || !!this.selectedPerson);
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    let extraFormValid = true;

    if (this.extraFormComponent) {
      extraFormValid = this.extraFormComponent.isValid();
    }

    return this._isPersonValid() && extraFormValid && this.formGroup.valid;
  }

  getFormData(): EnrollmentRequestParticipant {
    let data: any = this.personForm.getData();
    data.genderId = data.gender;
    delete data.gender;
    data.educationLevelId = data.educationLevel;
    delete data.educationLevel;

    if (this.extraFormComponent) {
      const extraFormData = this.extraFormComponent.getData();
      delete extraFormData.enrollment.contactPerson;
      delete extraFormData.enrollment.contactEmail;
      delete extraFormData.enrollment.contactPhoneNumber;
      delete extraFormData.enrollment.contactResponsibilities;
      delete extraFormData.enrollment.isContact;

      data = { ...data, ...flattenObject(extraFormData.enrollment) };
      data.employmentEmail = extraFormData.employment.email;
      data.employmentPhoneNumber = extraFormData.employment.phoneNumber;
      data.function = extraFormData.employment.function;
      data.employerId = extraFormData.employment?.employer?.branchId || extraFormData.employment?.employer;
      data.employmentStartDate = extraFormData.employment.startDate;
    }
    else {
      data.employerId = this.participant.employerId;
    }

    data.workStatusId = data.workStatus;
    delete data.workStatus;

    data.restructuringOrDismissalId = data.restructuringOrDismissal;
    delete data.restructuringOrDismissal;

    if (!this.isOpenExternOrCustom) {
      data.cost = this.formGroup.get('cost').value;
      data.enrollmentViaCevora = this.formGroup.get("enrollmentViaCevora").value;
    }

    if (this.participant?.employmentStartDate) {
      data.employmentStartDate = DateMapper.getDateFromDateTimeAsString(new Date(this.participant.employmentStartDate));
    }

    data.employerName = this.participant.employerName;
    data.employerCity = this.participant.employerCity;

    data._uiId = this.participant._uiId;
    data.validationResultId = this.participant.validationResultId;
    data.id = this.participant.id;

    return data;
  }

  addOrUpdateForm(): void {
    this._submitForm();
  }

  private _submitForm(): void {
    if (!this._isPersonValid()) {
      this.messageService.error('validation.noPersonSelected');
      return;
    }
    else if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.formSubmit.emit({
      participant: this.getFormData(),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private _createFormGroup(): void {
    const fields: any = {
      extraForm: [EnrollmentExtraFormComponent.createFormDataFromParticipant(this.participant)]
    };

    if (!this.isOpenExternOrCustom) {
      fields.cost = [this.participant?.cost, [Validators.required, Validators.min(0), Validators.max(9999)]];
      fields.enrollmentViaCevora = [this.participant?.enrollmentViaCevora || false]
    }

    this.formGroup = this.formBuilder.group(fields);
    this.personDetail = this._getPersonDetailFromParticipant();
  }

  onSelectSuggestedChange(event: SelectionChangedEvent) {
    this.selectedPerson = event.selected ? event.item : undefined;
    this._onPersonChange();
  }

  private _onPersonChange(): void {
    if (this.selectedPerson) {
      const matchingEmployment = this.selectedPerson.employments
        .filter(employment => employment.branchId === this.enrollmentRequest.branchId)[0];
      if (this.extraFormComponent && !this.isOpenExternOrCustom) {
        this.extraFormComponent.formGroup.get("employment.email").setValue(this.participant.employmentEmail || matchingEmployment?.professionalEmail || "");
        this.extraFormComponent.formGroup.get("employment.phoneNumber").setValue(this.participant.employmentPhoneNumber || matchingEmployment?.phoneNumber || "");
      }

      this.personService.getPerson(this.selectedPerson.personId).pipe(first())
        .subscribe(person => this.personDetail = this._getPersonDetailFromPerson(person));
    }
    else {
      this.personDetail = this._getPersonDetailFromParticipant();
    }
  }

  private _addSubscriptions(): void {
    this._subscriptions.push(
      this.personFormControl.valueChanges.subscribe(() => {
        this.selectedPerson = undefined;
        this.personDetail = undefined;
        this.searchPersonFormControl.setValue(undefined);
        this._onPersonChange();
      }),
      this.searchPersonFormControl.valueChanges.subscribe(person => {
        this.selectedPerson = person;
        this._onPersonChange();
      }));
  }

  private _getPersonDetailFromPerson(person: Person): Person {
    return {
      personId: person.personId,
      personAlimentoId: person.personAlimentoId,

      nationalIdentificationNumber: this.personDetail.nationalIdentificationNumber ? this.personDetail.nationalIdentificationNumber : person.nationalIdentificationNumber,
      firstName: this.personDetail.firstName ? this.personDetail.firstName : person.firstName,
      lastName: this.personDetail.lastName ? this.personDetail.lastName : person.lastName,
      gender: this.personDetail.gender ? this.personDetail.gender : person.gender,
      nationality: this.personDetail.nationality ? this.personDetail.nationality : person.nationality,
      educationLevel: this.personDetail.educationLevel ? this.personDetail.educationLevel : person.educationLevel,
      dateOfBirth: this.personDetail.dateOfBirth ? this.personDetail.dateOfBirth : person.dateOfBirth,
      language: this.personDetail.language ? this.personDetail.language : person.language,

      street: person.street,
      houseNumber: person.houseNumber,
      mailbox: person.mailbox,
      city: person.city,
      postalCode: person.postalCode,
      country: person.country,
      phoneNumber: person.phoneNumber,
      email: person.email
    };
  }

  private _getPersonDetailFromParticipant(): any {
    return {
      nationalIdentificationNumber: this.participant?.nationalIdentificationNumber,
      firstName: this.participant?.firstName,
      lastName: this.participant?.lastName,
      gender: this.participant?.genderId ? { data: this.participant?.genderId } : undefined,
      nationality: this.participant?.nationality,
      educationLevel: this.participant?.educationLevelId ? { data: this.participant?.educationLevelId } : undefined,
      dateOfBirth: this.participant?.dateOfBirth ? new Date(this.participant?.dateOfBirth) : undefined,
      street: undefined,
      houseNumber: undefined,
      mailbox: undefined,
      city: undefined,
      postalCode: undefined,
      country: undefined
    };
  }
}

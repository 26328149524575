import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MyMessageService, NO_BRANCH_ID } from '@alimento-ipv-frontend/ui-lib';
import { Subscription } from 'rxjs';
import { EnrollmentRequest } from '../../../types/enrollment.type';
import { BranchListItem } from '../../../types/searches.type';
import { BranchSelectComponent } from '../../../utils/components/branch-select/branch-select.component';

@Component({
  selector: 'alimento-ipv-frontend-enrollment-request-branch-popup',
  templateUrl: './enrollment-request-branch-popup.component.html'
})
export class EnrollmentRequestBranchPopupComponent implements OnDestroy {
  @Input()
  request: EnrollmentRequest;

  @Output()
  formSubmit = new EventEmitter<string>();

  @ViewChild(BranchSelectComponent)
  searchBranchFormControlComponent: BranchSelectComponent;

  popupVisible = false;

  branchFormControl = new FormControl<string>('existing');
  searchBranchFormControl = new FormControl<any>(undefined);
  branch: BranchListItem;

  private _subscriptions: (Subscription | undefined)[] = [];

  constructor(private messageService: MyMessageService) {
    this._subscriptions.push(
      this.branchFormControl.valueChanges.subscribe(() => {
        this.branch = undefined;
        this.searchBranchFormControl.setValue(undefined);
      }),
      this.searchBranchFormControl.valueChanges.subscribe(branch =>
        this.branch = branch));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    if (!this.request.branchId) {
      setTimeout(() => this.searchBranchFormControlComponent?.setSearchValue(this.request.branchName));
    }
    else {
      setTimeout(() => this.searchBranchFormControlComponent?.focus());
    }
  }

  closePopup(): void {
    this.popupVisible = false;
    this.branch = undefined;
    this.searchBranchFormControl.setValue(undefined);
  }

  isDataValid(): boolean {
    if (this.branchFormControl.value === 'existing') {
      return !!this.branch;
    }
    else {
      return true;
    }
  }

  getFormData(): string {
    if (this.branchFormControl.value === 'existing') {
      return this.branch.branchId;
    }
    else {
      return NO_BRANCH_ID;
    }
  }

  addOrUpdateForm(): void {
    this._submitForm();
  }

  private _submitForm(): void {
    if (!this.isDataValid()) {
      this.messageService.error('validation.noBranchSelected');
      return;
    }

    this.formSubmit.emit(this.getFormData());
    this.closePopup();
  }
}

export enum TRAINING_SORT_FIELDS {
  ALIMENTO_ID = 'alimentoid',
  START_DATE = 'startdate',
}

export enum TRAINING_PROJECT_AUDIENCE {
  PUPIL = 'pupil',
  TEACHER = 'teacher'
}

export enum SALES_INVOICE_ACTION {
  view,
  edit,
  approve
}

export enum SALES_INVOICE_CLIENT_TYPE {
  BRANCH = 'branch',
  PERSON = "person"
}

export enum SALES_INVOICE_STATUS {
  DRAFT = "ffb896a1-e0d3-4668-bb3d-d964de1377f4",
  APPROVED = "685174c9-0e1a-4939-af51-aac0c7575267"
}

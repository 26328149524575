import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { delay, first, forkJoin, Observable, shareReplay } from 'rxjs';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { FilterType, FormComponent, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { Dropdown } from 'primeng/dropdown';
import { BaseTraining, ReimbursementRequest } from '../../../types/reimbursement-request.type';
import {
  CaseManager,
  Counselor, TrainingMethod,
  TrainingTitleTreeItem,
  TrainingType
} from '../../../types/reference-data.type';
import { ContactPerson } from '../../../types/branch.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { BranchService } from '../../../services/branch.service';
import { TRAINING_TYPE } from '../../../types/reference-data.enum';
import { getSelectedTree, mapTreeSelect } from '../../../utils/utils/tree-select-utils';

@Component({
  selector: 'alimento-ipv-frontend-basic-training',
  templateUrl: './basic-training.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BasicTrainingComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: BasicTrainingComponent
    },
    { provide: FormComponent, useExisting: BasicTrainingComponent }
  ]
})
export class BasicTrainingComponent extends FormComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {

  @Input()
  reimbursementRequest: ReimbursementRequest;

  @Input()
  firstSessionDate?: Date;

  @Input()
  lastSessionDate?: Date;

  @Input()
  contactPersons: ContactPerson[] = [];

  @Input()
  selectedContactPersons: ContactPerson[] = [];

  caseManagers: CaseManager[] = [];
  caseManagerBackups: CaseManager[] = [];
  counselors: Counselor[] = [];
  trainingTitles: TrainingTitleTreeItem[] = [];
  types: TrainingType[] = [];
  methods: TrainingMethod[] = [];

  loadingResources: Observable<any>;
  trainingTitleReadOnly: TrainingTitleTreeItem | null;
  caseManager: CaseManager;
  caseManagerBackup: CaseManager;
  counselor: Counselor;
  type: TrainingType;
  contactPersonsReadOnly: string[] = [];
  loadingContactPersonsDone = false;
  teacherBranchReadOnly: string;
  methodReadOnly: string;

  teacherFilter = { type: FilterType.filterIsTeacher, values: ['true'] };

  @ViewChild('caseManagerField')
  caseManagerField: Dropdown;

  @ViewChild('caseManagerBackupField')
  caseManagerBackupField: Dropdown;

  @ViewChild('counselorField')
  counselorField: Dropdown;

  constructor(private referenceDataService: ReferenceDataService,
              private branchService: BranchService,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._fetchReferenceData();
    this._createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedContactPersons']?.currentValue) {
      this.contactPersonsReadOnly = this.selectedContactPersons.map(person => (person.lastName + ' ' + person.firstName));
      this.loadingContactPersonsDone = true;
    }
  }

  public static createFormData(training: BaseTraining, reimbursementRequest: ReimbursementRequest): any {
    const contactPersons =
      (training.trainingId ? training.contactPersons : reimbursementRequest.contactPersons) || [];

    return {
      typeId: training.typeId || TRAINING_TYPE.OPEN_EXTERN,
      languageCode: training.languageCode,
      rubricId: training.rubricId,
      mainTitleId: training.mainTitleId,
      trainingTitleId: training.trainingTitleId,
      caseManagerId: training.caseManagerId,
      caseManagerBackupId: training.caseManagerBackupId,
      counselorId: training.counselorId,
      teacherBranchId: training.teacherBranchId,
      contactPersons: contactPersons,
      contactPerson: contactPersons.length > 0 ? contactPersons[0] : undefined,
      contactPerson2: contactPersons.length > 1 ? contactPersons[1] : undefined,
      externalReference: training.externalReference,
      trainingProgramId: training.trainingProgramId,
      methodId: training.methodId,
      mentorTraining: training.mentorTraining
    };
  }

  override writeValue(training: BaseTraining): void {
    if (training) {
      this.formGroup.patchValue({ ...training }, { emitEvent: false });

      this.loadingResources.subscribe(() => {
        if (training.languageCode) {
          this.referenceDataService.getTrainingTitles(training.languageCode).pipe(first())
            .subscribe(titles => {
              this.trainingTitles = titles;
              this.trainingTitleReadOnly = getSelectedTree(
                training.rubricId,
                training.mainTitleId,
                training.trainingTitleId,
                this.trainingTitles
              );

              this.formGroup.patchValue({ treeSelect: this.trainingTitleReadOnly }, { emitEvent: false });
            });
        }

        this._setReadOnlyValues(training);
        this.closeUnselectedTreeItems();
        this.formGroup.updateValueAndValidity({ emitEvent: false });
      });
    }
    else {
      this.closeUnselectedTreeItems();
    }
  }

  onTreeSelectChange(): void {
    setTimeout(() => {
      const titleTree: { rubricId: string; mainTitleId: string; trainingTitleId: string } = mapTreeSelect(
        this.formGroup.get('treeSelect').value
      );

      this.formGroup.patchValue({
        rubricId: titleTree.rubricId,
        mainTitleId: titleTree.mainTitleId || undefined,
        trainingTitleId: titleTree.trainingTitleId || undefined
      });
    });
  }

  override isFieldInvalid(field: string): boolean {
    return !this.formGroup.get(field).disabled && !this.formGroup.get(field).valid;
  }

  private _fetchReferenceData(): void {
    this.loadingResources = forkJoin([
      this.referenceDataService.getCaseManagers(true).pipe(first()),
      this.referenceDataService.getCounselors(true).pipe(first()),
      this.referenceDataService.getTrainingTypes().pipe(first()),
      this.referenceDataService.getTrainingMethods().pipe(first())
    ]).pipe(shareReplay(1));

    this.loadingResources.subscribe(([caseManagers, counselors, trainingTypes, methods]) => {
      this.caseManagers = JSON.parse(JSON.stringify(caseManagers));
      this.caseManagerBackups = JSON.parse(JSON.stringify(caseManagers));
      this.counselors = counselors;
      this.types = trainingTypes.filter((trainingType: TrainingType) => [TRAINING_TYPE.OPEN_EXTERN, TRAINING_TYPE.CUSTOM].includes(trainingType.data as any));
      this.methods = methods
    });
  }

  private _createFormGroup(): void {
    this.formGroup = this.fb.group({
      typeId: [null, [Validators.required]],
      languageCode: [null, [Validators.required]],
      treeSelect: [null],
      rubricId: [null, [Validators.required]],
      mainTitleId: [null, [Validators.required]],
      trainingTitleId: [null, [Validators.required]],
      caseManagerId: [null, [Validators.required]],
      caseManagerBackupId: [null],
      counselorId: [null, [Validators.required]],
      teacherBranchId: [null],
      contactPerson: [],
      contactPerson2: [],
      contactPersons: [[]],
      externalReference: [''],
      trainingProgramId: [],
      methodId: [null],
      mentorTraining: [false]
    });
    this._addSubscriptions();
  }

  private _setReadOnlyValues(training: BaseTraining): void {
    if (training.methodId) {
      this.referenceDataService.getTrainingMethod(training.methodId)
        .subscribe(method => this.methodReadOnly = method.label);
    }

    if (training.caseManagerId) {
      this.referenceDataService.getCaseManager(training.caseManagerId)
        .subscribe(caseManager => {
          this.caseManager = caseManager;
          if (!caseManager.isActive) {
            this.caseManagers.push(caseManager);
            this.formGroup.get('caseManagerId').setValue(training.caseManagerId, { emitEvent: false });
          }
        });
    }

    if (training.caseManagerBackupId) {
      this.referenceDataService.getCaseManager(training.caseManagerBackupId)
        .subscribe(caseManager => {
          this.caseManagerBackup = caseManager;
          if (!caseManager.isActive) {
            this.caseManagerBackups.push(caseManager);
            this.formGroup.get('caseManagerBackupId').setValue(training.caseManagerBackupId, { emitEvent: false });
          }
        });
    }

    if (training.counselorId) {
      this.referenceDataService.getCounselor(training.counselorId)
        .subscribe(counselor => {
          this.counselor = counselor;
          if (!counselor.isActive) {
            this.counselors.push(counselor);
            this.formGroup.get('counselorId').setValue(training.counselorId, { emitEvent: false });
          }
        });
    }

    if (training.typeId) {
      this.referenceDataService.getTrainingType(training.typeId)
        .subscribe(type => {
          this.type = type;
        });
    }
  }

  private _addSubscriptions(): void {
    this.subscriptions.push(
      this.formGroup.get('counselorId')?.valueChanges.subscribe((newValue) => {
        this.referenceDataService.getCounselor(newValue)
          .subscribe(counselor => this.counselor = counselor);
      }),
      this.formGroup.get('caseManagerId')?.valueChanges.subscribe((newValue) => {
        this.referenceDataService.getCaseManager(newValue)
          .subscribe(caseManager => this.caseManager = caseManager);
      }),
      this.formGroup.get('caseManagerBackupId')?.valueChanges.subscribe((newValue) => {
        this.referenceDataService.getCaseManager(newValue)
          .subscribe(caseManager => this.caseManagerBackup = caseManager);
      }),
      this.formGroup.get('languageCode')?.valueChanges.subscribe(newValue => {
        if (newValue) {
          this.referenceDataService.getTrainingTitles(newValue).pipe(first())
            .subscribe(titles => {
              this.trainingTitles = titles;
              this.formGroup.get('treeSelect').setValue(null);
            });
        }
      }),
      this.formGroup.get('typeId')?.valueChanges.subscribe(newValue => {
        this.referenceDataService.getTrainingType(newValue)
          .subscribe(type => this.type = type);
      })
    );

    this.subscriptions.push(
      this.formGroup.valueChanges.pipe(delay(1)).subscribe((value) => {
        const data = { ...value };
        delete data.treeSelect;

        data.contactPersons = [];
        if (data.contactPerson) {
          data.contactPersons.push(data.contactPerson);
        }
        delete data.contactPerson;

        if (data.contactPerson2) {
          data.contactPersons.push(data.contactPerson2);
        }
        delete data.contactPerson2;

        if (data.teacherBranchId?.branchId) {
          data.teacherBranchId = data.teacherBranchId.branchId;
        }

        this.onChange(data);
        this.onTouched();
      })
    );
  }

  closeUnselectedTreeItems(): void {
    const selectedItem = this.formGroup.get('treeSelect')?.value;
    this.trainingTitles.forEach(treeItem => {
      treeItem.expanded = selectedItem?.parent?.parent?.data === treeItem.data;
      treeItem.children.forEach(child => {
        child.expanded = selectedItem?.parent?.data === child.data;
      });
    });
  }

  hasInactiveCaseManagersOrCounselors(): boolean {
    return (this.caseManager && !this.caseManager.isActive) ||
      (this.caseManagerBackup && !this.caseManagerBackup.isActive) ||
      (this.counselor && !this.counselor.isActive);
  }

  focusFirstInactiveCaseManager(): void {
    if (this.caseManager && !this.caseManager.isActive) {
      this.caseManagerField?.focus();
    }
    else if (this.caseManagerBackup && !this.caseManagerBackup.isActive) {
      this.caseManagerBackupField?.focus();
    }
    else if (this.counselor && !this.counselor.isActive) {
      this.counselorField?.focus();
    }
  }

  validateFormFields(): void {
    validateAllFormFields(this.formGroup);
  }

  validate(): ValidationErrors | null {
    return (this.formGroup.disabled || this.formGroup.valid) ? null : { basicTraining: { valid: false } };
  }
}

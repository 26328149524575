import {
  Component,
  computed,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  signal,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, Subscription } from 'rxjs';
import {
  CardListComponent,
  emailPattern,
  MyMessageService,
  NO_BRANCH_ID,
  SelectionChangedEvent,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import {
  ENROLLMENT_RESULT_ID,
  EnrollmentRequestActionEnum,
  getEnrollmentResultKey
} from '../../../types/enrollment.enum';
import {
  EnrollmentRequest,
  EnrollmentRequestParticipant,
  EnrollmentRequestUpdateEvent,
  ParticipantUpdateEvent
} from '../../../types/enrollment.type';
import { Training } from '../../../types/training.type';
import { Branch, ContactPerson, TrainingPlan } from '../../../types/branch.type';
import { BranchService } from '../../../services/branch.service';
import {
  EnrollmentRequestParticipantValidationPopupComponent
} from '../enrollment-request-participant-validation-popup/enrollment-request-participant-validation-popup.component';
import {
  EnrollmentRequestBranchPopupComponent
} from '../enrollment-request-branch-popup/enrollment-request-branch-popup.component';
import { ReimbursementRequest } from '../../../types/reimbursement-request.type';
import { TRAINING_TYPE } from '../../../types/reference-data.enum';
import { EnterpriseGroupService } from '../../../services/enterprise-group.service';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-enrollment-request-popup',
  templateUrl: './enrollment-request-popup.component.html',
  styleUrls: ['./enrollment-request-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnrollmentRequestPopupComponent implements OnChanges, OnDestroy {
  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;
  enrollmentRequest: EnrollmentRequest;
  enrollmentActionEnum?: EnrollmentRequestActionEnum;

  contactPersons: ContactPerson[] = [];
  contact1ResponsibilitiesReadOnly: string;
  contactPerson1ReadOnly: ContactPerson;
  contact2ResponsibilitiesReadOnly: string;
  contactPerson2ReadOnly: ContactPerson;
  branch: Branch;
  trainingPlan: TrainingPlan;
  bankAccountNumber?: string;

  collapsed: { [key: string]: boolean } = {};
  selectedParticipants: EnrollmentRequestParticipant[] = [];

  participants: WritableSignal<EnrollmentRequestParticipant[]> = signal([]);
  subscribeParticipants = computed(() => this.participants()
    .filter(participant => participant.validationResultId === ENROLLMENT_RESULT_ID.SUBSCRIBE));
  reserveParticipants = computed(() => this.participants()
    .filter(participant => participant.validationResultId === ENROLLMENT_RESULT_ID.RESERVE));
  cancelParticipants = computed(() => this.participants()
    .filter(participant => participant.validationResultId === ENROLLMENT_RESULT_ID.CANCEL));
  totalCost = computed(() =>
    this.participants().reduce((previous, participant) => previous + (participant.cost || 0), 0));

  @Input()
  training: Training;

  @Input()
  groupId?: string;

  @Input()
  enterpriseId?: string;

  @Input()
  maxNewParticipants: number | undefined;

  @Input()
  readOnly = false;

  @Input()
  isOpenExternOrCustom: boolean;

  @Input()
  reimbursementRequest?: ReimbursementRequest;

  @Input()
  trainingStartDate?: Date;

  @Output()
  formSubmit = new EventEmitter<EnrollmentRequestUpdateEvent>();

  @ViewChild(EnrollmentRequestParticipantValidationPopupComponent)
  editParticipantPopup: EnrollmentRequestParticipantValidationPopupComponent;

  @ViewChild(EnrollmentRequestBranchPopupComponent)
  validateBranchPopup: EnrollmentRequestBranchPopupComponent;

  @ViewChildren(CardListComponent)
  cardLists: CardListComponent[];

  private _titleMap: Map<any, any>;

  selectedParticipant: EnrollmentRequestParticipant;
  protected readonly ENROLLMENT_RESULT_ID = ENROLLMENT_RESULT_ID;
  protected readonly NO_BRANCH_ID = NO_BRANCH_ID;
  readonly _INTERIM_PC_118 = 'a3bd8f32-ab6d-4749-8b1b-11ef962b5529';
  readonly _INTERIM_PC_220 = '4d8b315e-da9e-445f-ab54-68737fbfd1cb';
  protected readonly getEnrollmentResultKey = getEnrollmentResultKey;
  contactNotEditable = true;

  private _subscriptions: Subscription[] = [];
  showBankAccountNotMatching: boolean;
  canSetCevora = false;
  trainingPlanState: string;

  constructor(private formBuilder: FormBuilder,
              private messageService: MyMessageService,
              private branchService: BranchService,
              private enterpriseGroupService: EnterpriseGroupService,
              private referenceDataService: ReferenceDataService) {
    this._createTitleMap();
    this.createFormGroup();
  }

  ngOnChanges(): void {
    this._setFormState();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  private _setFormState(): void {
    if (this.readOnly) {
      this.formGroup?.disable({ emitEvent: false });
    }
    else {
      this.formGroup?.enable({ emitEvent: false });
    }
  }

  private createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      branchId: [this.enrollmentRequest?.branchId, Validators.required],
      invoicingSame: [!this.enrollmentRequest?.invoiceName],
      invoiceName: [this.enrollmentRequest?.invoiceName],
      invoiceEmail: [this.enrollmentRequest?.invoiceEmail, emailPattern()],
      invoiceAddress: [{
        street: this.enrollmentRequest?.invoiceStreet,
        city: this.enrollmentRequest?.invoiceCity,
        houseNumber: this.enrollmentRequest?.invoiceNumber,
        postalCode: this.enrollmentRequest?.invoicePostalCode,
        mailbox: this.enrollmentRequest?.invoiceMailbox,
        country: this.enrollmentRequest?.invoiceCountry
      }],
      invoiceReference: [this.enrollmentRequest?.invoiceReference],
      trainingPlan: [this.isOpenExternOrCustom ? false : this.enrollmentRequest?.trainingPlan],
      contact1Id: [undefined, Validators.required],
      contact1Email: [this.enrollmentRequest?.contact1Email],
      contact1PhoneNumber: [this.enrollmentRequest?.contact1PhoneNumber],
      contact1Responsibilities: [null],
      contact2Id: [undefined],
      contact2Email: [this.enrollmentRequest?.contact2Email],
      contact2PhoneNumber: [this.enrollmentRequest?.contact2PhoneNumber],
      contact2Responsibilities: [null],
      remarks: [this.enrollmentRequest?.remarks]
    });
    this._setFormState();

    let enrolledParticipantsCount = this.enrollmentRequest?.participants
      .filter(participant => participant.validationResultId === ENROLLMENT_RESULT_ID.SUBSCRIBE)
      .length;
    this.participants.set(this.enrollmentRequest?.participants
      .map(participant => {
        participant._uiId = Math.random() + '';
        if (!participant.validationResultId) {
          if (this.maxNewParticipants !== undefined && enrolledParticipantsCount >= this.maxNewParticipants) {
            participant.validationResultId = ENROLLMENT_RESULT_ID.RESERVE;
          }
          else {
            participant.validationResultId = ENROLLMENT_RESULT_ID.SUBSCRIBE;
            enrolledParticipantsCount++;
          }
        }
        return participant;
      }) || []);

    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
    this._subscriptions = [];

    this._subscriptions.push(
      this.formGroup.get('contact1Id').valueChanges.subscribe(contactPersonId => {
        this.contactPerson1ReadOnly = this.contactPersons
          .filter(contactPerson => contactPerson.personId === contactPersonId)[0];
        this.contact1ResponsibilitiesReadOnly = this.contactPerson1ReadOnly.responsibilities
          .map(responsibility => responsibility.label)
          .join(', ');
      }),
      this.formGroup.get('contact2Id').valueChanges.subscribe(contactPersonId => {
        this.contactPerson2ReadOnly = this.contactPersons
          .filter(contactPerson => contactPerson.personId === contactPersonId)[0];
        this.contact2ResponsibilitiesReadOnly = this.contactPerson2ReadOnly.responsibilities
          .map(responsibility => responsibility.label)
          .join(', ');
      })
    );

    this.updateBranch(this.enrollmentRequest?.branchId);
    this.setInvoiceFieldsRequiredIfNotTheSameAsBranch();
  }

  private _createTitleMap(): void {
    this._titleMap = new Map();
    this._titleMap.set(EnrollmentRequestActionEnum.view, {
      title: 'enrollments.viewEnrollmentRequest',
      complete: 'enrollments.close'
    });
    this._titleMap.set(EnrollmentRequestActionEnum.validate, {
      title: 'enrollments.validateEnrollmentRequest',
      complete: 'enrollments.validateEnrollmentRequest'
    });
  }

  get titleObject(): { title: string, complete: string } {
    return this._titleMap.get(this.enrollmentActionEnum);
  }

  openPopup(enrollmentRequest: EnrollmentRequest, enrollmentRequestActionEnum: EnrollmentRequestActionEnum): void {
    this.enrollmentRequest = enrollmentRequest;
    this.enrollmentActionEnum = enrollmentRequestActionEnum;
    this.selectedParticipants = [];
    this.createFormGroup();
    this.popupVisible = true;
  }

  closePopup(): void {
    this.popupVisible = false;
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);

    return this._participantsValid() && this.formGroup.valid;
  }

  private _participantsValid(): boolean {
    return [...this.subscribeParticipants(), ...this.reserveParticipants()]
      .every(participant => this._participantIsValid(participant));
  }

  save(): void {
    this.addOrUpdateEnrollment(EnrollmentRequestActionEnum.save);
  }

  submit(): void {
    if (!this._participantsValid()) {
      this.messageService.error('validation.notAllParticipantsValid');
      return;
    }
    else if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.addOrUpdateEnrollment(this.enrollmentActionEnum);
  }

  private addOrUpdateEnrollment(action: EnrollmentRequestActionEnum): void {
    this.formSubmit.emit({
      enrollmentRequestId: this.enrollmentRequest?.id,
      enrollmentRequest: this.getData(),
      action: action,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private getData(): EnrollmentRequest {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));

    data.participants = this.participants();

    if (!data.invoiceSame && data.invoiceAddress) {
      data.invoiceStreet = data.invoiceAddress.street;
      data.invoiceCity = data.invoiceAddress.city;
      data.invoiceNumber = data.invoiceAddress.houseNumber;
      data.invoicePostalCode = data.invoiceAddress.postalCode;
      data.invoiceMailbox = data.invoiceAddress.mailbox;
      data.invoiceCountry = data.invoiceAddress.country;
    }
    delete data.invoiceAddress;

    return { ...this.enrollmentRequest, ...data };
  }

  validateBranch(): void {
    this.validateBranchPopup.openPopup();
  }

  updateBranch(branchId: string): void {
    if (this.enrollmentRequest) {
      this.enrollmentRequest.branchId = branchId;
    }
    this.formGroup.get('branchId').setValue(branchId);

    if (branchId !== NO_BRANCH_ID) {
      this._getReadOnlyInfo();
      this.formGroup.get('trainingPlan').setValidators([Validators.required]);
      this.canSetCevora = this.training?.typeId === TRAINING_TYPE.OPEN_TRAINING;
    }
    else {
      this.formGroup.get('trainingPlan').setValidators([]);
      this.canSetCevora = false;
    }
  }

  private _getReadOnlyInfo(): void {
    if (this.enrollmentRequest?.branchId) {
      this.branchService.getBranch(this.enrollmentRequest.branchId).pipe(first())
        .subscribe(branch => {
          this.branch = branch;
          if (!this.branch.isFood) {
            this.formGroup?.get('trainingPlan')?.setValue(false);
          }
          this.bankAccountNumber = branch.defaultAccountNumber?.label;
          this.showBankAccountNotMatching = this.enrollmentRequest?.bankAccountNumber?.replace(/\s/g, '').toLowerCase()
            !== this.bankAccountNumber?.replace(/\s/g, '').toLowerCase();
        });
      this.branchService.getTrainingPlans(this.enrollmentRequest.branchId).pipe(first())
        .subscribe(trainingPlans => {
          this.trainingPlan = trainingPlans[0];
          if (this.trainingPlan) {
            this.referenceDataService.getTrainingPlanState(this.trainingPlan.stateId).pipe(first())
              .subscribe(trainingPlanState => this.trainingPlanState = trainingPlanState.label);
          }
        });

      let contactPersonRequest;
      if (this.groupId) {
        contactPersonRequest = this.enterpriseGroupService.getContactPersons(this.groupId)
      }
      else {
        contactPersonRequest = this.branchService.getContactPersons(this.enrollmentRequest.branchId);
      }
      contactPersonRequest.pipe(first())
        .subscribe(contactPersons => {
          this.contactPersons = contactPersons;

          if (this.enrollmentRequest?.contact1Id) {
            this.contactPerson1ReadOnly = this.contactPersons
              .filter(contactPerson => contactPerson.personId === this.enrollmentRequest?.contact1Id)[0];
          }
          else if (this.enrollmentRequest?.contact1Email) {
            this.contactPerson1ReadOnly = this.contactPersons
              .filter(contactPerson => contactPerson.email === this.enrollmentRequest?.contact1Email)[0];
          }

          if (this.contactPerson1ReadOnly) {
            this.formGroup.get('contact1Id').setValue(this.contactPerson1ReadOnly.personId, { emitEvent: false });
            this.contact1ResponsibilitiesReadOnly = this.contactPerson1ReadOnly.responsibilities
              .map(responsibility => responsibility.label)
              .join(', ');
          }

          if (this.enrollmentRequest?.contact2Id) {
            this.contactPerson2ReadOnly = this.contactPersons
              .filter(contactPerson => contactPerson.personId === this.enrollmentRequest?.contact2Id)[0];
          }
          else if (this.enrollmentRequest?.contact2Email) {
            this.contactPerson2ReadOnly = this.contactPersons
              .filter(contactPerson => contactPerson.email === this.enrollmentRequest?.contact2Email)[0];
          }

          if (this.contactPerson2ReadOnly) {
            this.formGroup.get('contact2Id').setValue(this.contactPerson2ReadOnly.personId, { emitEvent: false });
            this.contact2ResponsibilitiesReadOnly = this.contactPerson2ReadOnly.responsibilities
              .map(responsibility => responsibility.label)
              .join(', ');
          }
        });
    }
    else {
      this.showBankAccountNotMatching = false;
    }
  }

  changeParticipantStatus(toResultId: ENROLLMENT_RESULT_ID): void {
    this.participants.set(this.participants().map(participant => {
      if (this.selectedParticipants.includes(participant)) {
        participant.validationResultId = toResultId;
      }
      return participant;
    }));
    this._clearSelection();
  }

  setCevoraStatus(status: boolean): void {
    this.selectedParticipants.forEach(participant => {
      participant.enrollmentViaCevora = status;
    });
    this._clearSelection();
  }

  updateSelectedParticipants(event: SelectionChangedEvent): void {
    if (event.selected) {
      this.selectedParticipants.push(event.item);
    }
    else {
      this.selectedParticipants = this.selectedParticipants
        .filter(participant => participant !== event.item);
    }
  }

  editParticipant(participant: EnrollmentRequestParticipant): void {
    this.selectedParticipant = JSON.parse(JSON.stringify(participant));
    this.editParticipantPopup.openPopup();
  }

  saveParticipant(event: ParticipantUpdateEvent): void {
    this.participants.set(this.participants().map(participant => {
      if (participant._uiId === event.participant._uiId) {
        return event.participant;
      }
      return participant;
    }));
    this.editParticipantPopup.closePopup();
    this._clearSelection();
  }

  _participantIsValid(participant: EnrollmentRequestParticipant): boolean {
    let personValid =
      !!participant.lastName &&
      !!participant.firstName &&
      !!participant.dateOfBirth &&
      !!participant.genderId;

    if (this.enrollmentRequest.branchId === NO_BRANCH_ID) {
      personValid = personValid &&
        !!participant.email &&
        !!participant.street &&
        !!participant.houseNumber &&
        !!participant.country &&
        !!participant.city &&
        !!participant.postalCode;
    }
    else {
      personValid = personValid && (this.isOpenExternOrCustom ||
        !!participant.employmentEmail);
    }

    if (participant.workStatusId === this._INTERIM_PC_220
      || participant.workStatusId === this._INTERIM_PC_118) {
      personValid = personValid && !!participant.interimOfficeId;
    }

    return personValid &&
      participant.workStatusId &&
      participant.restructuringOrDismissalId &&
      (this.isOpenExternOrCustom ||
        (participant.cost >= 0 && participant.cost < 9999));
  }

  private setInvoiceFieldsRequiredIfNotTheSameAsBranch() {
    const invoicingSameFormField = this.formGroup.get('invoicingSame');

    if (invoicingSameFormField) {
      this._subscriptions.push(
        invoicingSameFormField.valueChanges.subscribe((invoicingSame: boolean) => {
          this.formGroup.get('invoiceName')?.setValidators(!invoicingSame ? Validators.required : []);
          this.formGroup.get('invoiceName')?.reset();
          this.formGroup.get('invoiceAddress')?.reset();
          this.formGroup.get('invoiceAddress')?.updateValueAndValidity();
        })
      );
    }
  }

  getSelectedParticipantsCevora(cevoraStatus: boolean): EnrollmentRequestParticipant[] {
    return this.selectedParticipants
      .filter(participant => !!participant.enrollmentViaCevora === cevoraStatus);
  }

  private _clearSelection(): void {
    this.selectedParticipants = [];
    this.cardLists.forEach(cardList => {
      cardList.resetSelection();
    });
  }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FilterType, FormComponent, SearchFilterValue } from '@alimento-ipv-frontend/ui-lib';
import {
  EnterpriseGroup,
  EnterpriseGroupMainEnterprise,
  UpdateEnterpriseGroup
} from '../../../types/enterprise-group.type';

@Component({
  selector: 'alimento-ipv-frontend-enterprise-group',
  templateUrl: './enterprise-group.component.html',
  providers: [
    { provide: FormComponent, useExisting: EnterpriseGroupComponent }
  ]
})
export class EnterpriseGroupComponent extends FormComponent implements OnChanges {
  @Input()
  enterpriseGroup: EnterpriseGroup;

  @Input()
  showMainEnterprise = true;

  mainEnterprise: EnterpriseGroupMainEnterprise;
  activeFilter: SearchFilterValue[] = [{type: FilterType.isActive , values: [true]}];

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enterpriseGroup']?.currentValue) {
      this._createForm();

      if (this.enterpriseGroup.mainEnterprise?.enterpriseId) {
        this.mainEnterprise = this.enterpriseGroup.mainEnterprise;
      }
      else {
        delete this.mainEnterprise;
      }
    }
  }

  private _createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [this.enterpriseGroup?.name, Validators.required],
      mainEnterprise: [this.enterpriseGroup?.mainEnterprise?.enterpriseId, Validators.required],
      enterpriseIds: [this.enterpriseGroup?.enterpriseIds || []]
    });

    this.subscriptions.push(
      this.formGroup.get("mainEnterprise").valueChanges.subscribe(enterprise => {
        if (enterprise) {
          this.mainEnterprise = {
            enterpriseId: enterprise.enterpriseId,
            companyNumber: enterprise.enterpriseNumber,
            name: enterprise.name,
            rszNumber: enterprise.rszNumber,
            vatNumber: enterprise.vatNumber
          };
          if (!this.formGroup.get("name").value) {
            this.formGroup.get("name").setValue(enterprise.name);
          }
        }
        else {
          this.mainEnterprise = undefined;
        }
      })
    );

    this._addChangeListener();
  }

  override getData(): UpdateEnterpriseGroup {
    const data = super.getData();
    if (data.mainEnterprise) {
      data.mainEnterpriseId = data.mainEnterprise.enterpriseId;
      delete data.mainEnterprise;
    }

    return data;
  }
}

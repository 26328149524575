<div *ngIf="enterpriseGroup">
  <alimento-ipv-frontend-history [(visible)]="showHistoryItems" [historyItems]="historyItems" [messageTemplate]='messageTemplate'>
    <ng-template #messageTemplate let-historyItem="historyItem">
      <div *ngIf="historyItem.actionName === ENTERPRISE_GROUP_ACTION.ENTERPRISE_ADDED || historyItem.actionName === ENTERPRISE_GROUP_ACTION.ENTERPRISE_REMOVED"
           class="flex p-2 rounded bg-grey-2">
        <a [routerLink]='["/enterprises", historyItem.data.enterpriseId, "detail"]'>{{historyItem.data?.enterpriseName}}</a>
      </div>
    </ng-template>
  </alimento-ipv-frontend-history>

  <alimento-ipv-frontend-detail-page-layout
    title="{{ 'enterpriseGroups.enterpriseGroup' | translate }}"
    [navigationUrl]="['/enterprise-groups']"
  >
    <ng-container top-action>
      <div class="text-xl">
        <span class='text-text'>{{ enterpriseGroup.name }}</span>
        <span class="mx-2 whitespace-nowrap text-text">- {{ enterpriseGroup.enterpriseGroupAlimentoId }}</span>
      </div>

      <div class="buttons" *ngIf='!readOnly'>
        <button
          pButton
          type="button"
          label="{{ 'reimbursementRequests.actions.save' | translate }}"
          class="secondary-button uppercase"
          icon="pi pi-save"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveEnterpriseGroup()"
          [loading]="saving"
        ></button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)="setActiveTabIndex($event)"
        [activeTabIndex]="activeTabIndex"
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div [hidden]="activeTabIndex !== 0">
        <alimento-ipv-frontend-header title="{{ 'trainingPrograms.basicData.formTitle' | translate }}">
        </alimento-ipv-frontend-header>
        <alimento-ipv-frontend-enterprise-group
          [enterpriseGroup]='enterpriseGroup'
        ></alimento-ipv-frontend-enterprise-group>
      </div>

      <div *ngIf="activeTabIndex === 1">
        <alimento-ipv-frontend-select-enterprise-popup #selectEnterprisePopup
                                                       (enterpriseSelected)='addEnterprise($event)'
        ></alimento-ipv-frontend-select-enterprise-popup>

        <alimento-ipv-frontend-header title="{{ 'enterpriseGroups.enterprisesTitle' | translate }}">
<!--          <p-button (onClick)='selectEnterprisePopup.openDialog()'>{{'enterpriseGroups.addEnterprise' | translate}}</p-button>-->
        </alimento-ipv-frontend-header>

        <lib-search [data$]='searchEnterpriseData$'
                    [filters]='searchEnterpriseFilters'
                    [cardTemplate]='cardTemplate'
                    [inlineFilters]='true'
                    [activeFiltersOnTop]='false'
                    [showSearchFilter]='false'
                    name='enterprise-groups-enterprises'
                    searchPlaceholderKey='enterprises.searchPlaceholder'
                    availableResultsTranslateKey='enterprises.menuTitle'
        >
          <ng-template #cardTemplate let-item="item">
            <alimento-ipv-frontend-enterprise-card
              [enterprise]='item'
              [isMainEnterprise]='enterpriseGroup.mainEnterprise?.enterpriseId === item.enterpriseId'
              [showActions]='false'
              [routerLink]='["/enterprises", item.enterpriseId, "detail"]'
              (actionClicked)='enterpriseActionClicked(item.enterpriseId, $event)'
              tabindex="0"
            >
            </alimento-ipv-frontend-enterprise-card>
          </ng-template>
        </lib-search>
      </div>
    </ng-container>

    <ng-container metadata *ngIf='enterpriseGroupId'>
      <alimento-ipv-frontend-metadata-item title='enterpriseGroups.groupApprovedOn'
                                           [value]='enterpriseGroup.groupApprovedOn | date:"dd/MM/yyyy"'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item
        title="trainings.createdBy"
        [value]="enterpriseGroup.createdBy"
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item
        title="trainings.createdOn"
        [value]="enterpriseGroup.createdOn | date : 'dd/MM/yyyy'"
      ></alimento-ipv-frontend-metadata-item>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>

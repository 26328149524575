export enum EXCEPTIONS {
  business = 'http://alimento/dali/exceptions/business',
  unhandled = 'http://alimento/dali/exceptions/unhandled',
  validation = 'http://alimento/dali/exceptions/validation',
  multiple = "http://alimento/dali/exceptions/multiple-errors"
}

export const ALIMENTO_EXCEPTIONS = "http://alimento/dali/exceptions/";

export enum ERRORS {
  FIELD_REQUIRED = 'field-required',
  FIELD_BETWEEN = 'field-should-be-between',
  FIELD_LESS_THEN_FIELD = 'field-should-be-less-than-field',
  SUPPLIER_NOT_ALLOWED = 'supplier-notallowed',
  EOL_SUPPLIER_NOT_FOUND = 'eol-purchaseentry-supplier-notfound',
  EOL_PURCHASE_GLACCOUNT_NOT_FOUND = 'eol-purchaseentry-glaccount-notfound',
  EOL_DEFAULT_ACCOUNT_NOT_FOUND = 'eol-purchaseentry-supplier-defaultbankaccount-notfound',
  EOL_INVALID_REFERENCEYEAR = 'eol-invalid-referenceyear',
  MUST_BE_GREATER_THAN = "must-be-greater-than",
  EOL_BRANCH_NOT_FOUND = 'eol-salesinvoice-clientbranch-notfound',
  EOL_PERSON_NOT_FOUND = 'eol-salesinvoice-clientperson-notfound',
  EOL_SALE_GLACCOUNT_NOT_FOUND = 'eol-salesinvoice-glaccount-notfound',
}

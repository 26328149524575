export enum TRAINING_TYPE {
  TEMPLATE = 'template',
  OPEN_TRAINING = 'f3ca1fe0-5f1e-41c7-9fdc-ad4d2ec1a85a',
  OPEN_EXTERN = '7d4b30e5-4767-4544-a7a2-1a35d3820707',
  CUSTOM = 'ed823289-8057-4810-b310-4390102f2225',
  CEVORA = 'c2968889-0c64-49aa-8ed2-8ca81648d8b8',
  TEACHER_GENERAL = '3e86925a-0f9a-4ee5-a3bb-13dce27b9f65',
  TEACHER_OPEN_TRAINING = '59226bce-2d76-4818-af9c-75b3b19a4ee0',
  TEACHER_ON_DEMAND = 'eb08fb44-2259-4cb6-93c7-589965a0c378',
  TEACHER_EXTERNAL = '21800fb9-0eed-4803-85b6-53b57ee7fc6f',
  PUPIL_GENERAL = '4a96fd44-6dc4-41b2-a4c7-c878977d57e7',
  PUPIL_OPEN_TRAINING = '986b7871-014f-4b4b-b8a5-1be629800698',
  PUPIL_ON_DEMAND = '9b62ccb9-f3e9-4865-b204-97279f7c8955',
  PUPIL_EXTERNAL = '09f8c1ae-3e0b-4980-b2e2-acc9977dc558',
  EDUCATION_EVENT = '1079c42b-13e5-4c63-b8ff-f5ccfdded0ed',
  EDUCATION_COMPANY_VISIT = 'c627e121-8d07-47bc-b076-ab76ec082021',
  EDUCATION_INFO_SESSION = '88c62ec7-9ea3-4e8c-a3d4-c53e006022c5',
  EDUCATION_INITIATIVE = 'bb4ba8a4-9b7c-4d78-ba66-512c47f9bcf9',
  EDUCATION_TYPE_STUB = 'education_type'
}

export const PUPIL_TRAINING_TYPE = [
  TRAINING_TYPE.PUPIL_GENERAL,
  TRAINING_TYPE.PUPIL_OPEN_TRAINING,
  TRAINING_TYPE.PUPIL_ON_DEMAND,
  TRAINING_TYPE.PUPIL_EXTERNAL,
]

export const TEACHER_TRAINING_TYPE = [
  TRAINING_TYPE.TEACHER_GENERAL,
  TRAINING_TYPE.TEACHER_OPEN_TRAINING,
  TRAINING_TYPE.TEACHER_ON_DEMAND,
  TRAINING_TYPE.TEACHER_EXTERNAL,
]

export const EVENT_TYPE = [
  TRAINING_TYPE.EDUCATION_EVENT,
  TRAINING_TYPE.EDUCATION_COMPANY_VISIT,
  TRAINING_TYPE.EDUCATION_INFO_SESSION,
  TRAINING_TYPE.EDUCATION_INITIATIVE,

  TRAINING_TYPE.EDUCATION_TYPE_STUB
]

export const isPupilProjectType = (type: TRAINING_TYPE): boolean => {
  return PUPIL_TRAINING_TYPE.includes(type);
}

export const isTeacherProjectType = (type: TRAINING_TYPE): boolean => {
  return TEACHER_TRAINING_TYPE.includes(type);
}

export const isEventType = (type: TRAINING_TYPE): boolean => {
  return EVENT_TYPE.includes(type);
}

export const isOpenExternOrCustom = (type: TRAINING_TYPE): boolean => {
  return [
    TRAINING_TYPE.CUSTOM,
    TRAINING_TYPE.PUPIL_ON_DEMAND,
    TRAINING_TYPE.TEACHER_ON_DEMAND,
    TRAINING_TYPE.OPEN_EXTERN,
    TRAINING_TYPE.PUPIL_EXTERNAL,
    TRAINING_TYPE.TEACHER_EXTERNAL
  ].includes(type);
}

export const getTrainingTypeUrl = (type: TRAINING_TYPE): string => {
  if (type === TRAINING_TYPE.OPEN_TRAINING) {
    return "opentraining";
  }
  else if (type === TRAINING_TYPE.OPEN_EXTERN) {
    return 'openexterntrainings';
  }
  else if (type === TRAINING_TYPE.CUSTOM) {
    return 'customtrainings';
  }
  else if (type === TRAINING_TYPE.CEVORA) {
    return "cevoratraining";
  }
  else if ([...PUPIL_TRAINING_TYPE, ...TEACHER_TRAINING_TYPE].includes(type)) {
    return "educationaltrainings";
  }
  else if (EVENT_TYPE.includes(type)) {
    return 'educationalevents';
  }
  return '';
}

export const CostTypeKey = {
  Paying: 'f073689d-46fc-4b86-b079-b6ced0269670',
  NotPaying: '3caa1422-87e7-40c3-a5d6-06e0ec0d1c7d',
  Fixed: '867fd76a-50c1-44b5-99f4-161a191c58c7',
} as const;

export type CostTypeKey = (typeof CostTypeKey)[keyof typeof CostTypeKey];

export const EnrollmentStatusKey = {
  ToValidate: '2c1f4fb7-7541-44c7-bb56-6e62c5c67d3f',
  Enrolled: 'b59d0747-9250-409f-b710-3440f0c59871',
  Cancelled: 'a234b2d5-ddb7-4db0-9677-412e29f236e6',
  Reserve: '34065c15-a7f4-4736-be6d-8b3e0e161892',
} as const;

export type EnrollmentStatusKey = (typeof EnrollmentStatusKey)[keyof typeof EnrollmentStatusKey];

export const getEnrollmentStatusKey = (status: EnrollmentStatusKey): string => {
  return Object.keys(EnrollmentStatusKey).filter(
    (key) => (EnrollmentStatusKey as { [key: string]: string })[key] === status
  )[0];
};

export enum MailType {
  SessionCancelled = 0,
  TrainingCancelled = 1,
  EnrollInTrainingWithKnownDateAndUniqueMethod = 2,
  EnrollInTrainingWithKnownDateAndDifferentMethods = 3,
  EligibleForExtraTrainingBudgetUnder26 = 4,
  EligibleForExtraTrainingBudgetOver50 = 5
}

export enum EnrollInTrainingMailType {
  EnrollInTrainingWithKnownDateAndUniqueMethod = 'EnrollInTrainingWithKnownDateAndUniqueMethod',
  EnrollInTrainingWithKnownDateAndDifferentMethods = 'EnrollInTrainingWithKnownDateAndDifferentMethods',
  EnrollInTrainingWithUnknownDate = 'EnrollInTrainingWithUnknownDate'
}

export const EnrollInTrainingKey = 'EnrollInTraining';

export const ACCEPT_LANGUAGE = 'Accept-Language';

export const SessionMethodKey = {
  Physical: 'ae12ae27-b4e2-442e-a0d9-722e5bea688b',
  Hybrid: '90e7bb87-e61a-40e8-af04-75a4c40b4a78',
  Coaching: '18eb7caa-b3cd-4766-a999-053b59a9f5a2',
  Remote: 'dcb491ef-4188-47fa-96e9-6099e180d4d5',
  Virtual: '9ed6b66b-c74a-42bb-81de-9d09b6c003f7',
  DigitalLearning: '18dedb36-3e36-4d11-805e-a4b74a59593f',
  VR: '03e88694-602b-480e-8622-d6fda395dd3b',
  Webinar: '78f4befe-6d37-4592-9bb1-ee674ae5957a',
} as const;

export type SessionMethodKey = (typeof SessionMethodKey)[keyof typeof SessionMethodKey];

export const TemplateStatusKey = {
  Draft: '1341206c-fbf1-4f40-99ec-579fe98afb57',
  Active: '6413072d-d017-41d5-ae84-d4e0de6768a5',
  Removed: '3bd94c8b-7902-4f7c-beed-9c4fb0de2942',
} as const;

export type TemplateStatusKey = (typeof TemplateStatusKey)[keyof typeof TemplateStatusKey];

export const getTemplateStatusKey = (status: TemplateStatusKey): string => {
  return Object.keys(TemplateStatusKey).filter(
    (key) => (TemplateStatusKey as { [key: string]: string })[key] === status
  )[0];
};

export const TrainingStatusKey = {
  Draft: '2c1f4fb7-7541-44c7-bb56-6e62c5c67d3f',
  Active: 'b59d0747-9250-409f-b710-3440f0c59871',
  Cancelled: 'a234b2d5-ddb7-4db0-9677-412e29f236e6',
  Removed: '34065c15-a7f4-4736-be6d-8b3e0e161892',
  Completed: '4144a3de-454b-4f69-ba6f-310237a438fa'
} as const;

export type TrainingStatusKey = (typeof TrainingStatusKey)[keyof typeof TrainingStatusKey];

export const getTrainingStatusKey = (status: TrainingStatusKey): string => {
  return Object.keys(TrainingStatusKey).filter(
    (key) => (TrainingStatusKey as { [key: string]: string })[key] === status
  )[0];
};

export const PupilTrainingTypeKey = 'PupilTrainingType';

export const TeacherTrainingTypeKey = 'TeacherTrainingType';

export const EnrollmentRequestStatusKey = {
  toVerify: '5fcdf1e8-c8b2-485d-b0e7-0bf79cb9006a',
  toValidate: '69f4c797-231b-4155-9a4f-2e340a6a4a7c',
  confirmed: '7d08609c-2bce-4dca-b012-53e8e5bc7b6c',
  cancelled: 'dadd33d3-7a14-44de-9030-0c17f2b75e08',
};

export type EnrollmentRequestStatusKey = (typeof EnrollmentRequestStatusKey)[keyof typeof EnrollmentRequestStatusKey];

export const getEnrollmentRequestStatusKey = (status: EnrollmentRequestStatusKey): string => {
  return Object.keys(EnrollmentRequestStatusKey).filter(
    (key) => (EnrollmentRequestStatusKey as { [key: string]: string })[key] === status
  )[0];
};

export enum DOCUMENT_SET_TYPE {
  BRANCH = 'branch',
  PERSON = "person",
  ALLOWANCE_APPLICATION = "allowanceApplication",
  TRAINING = "training",
  ENTERPRISE = "enterprise"
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'alimento-ipv-frontend-enterprises-status-chip',
  templateUrl: './enterprise-status-chip.component.html'
})
export class EnterpriseStatusChipComponent {
  @Input()
  isActive!: boolean;

  getStatusClass(): string {
    if (this.isActive) {
      return 'text-primary border border-primary ';
    }
    else {
      return 'border-grey-3 text-grey-3';
    }
  }

}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { SelectionChangedEvent } from '../../types/ui-lib.type';
import { Checkbox } from 'primeng/checkbox';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-card-list',
  templateUrl: './card-list.component.html'
})
export class CardListComponent implements OnChanges {

  @Input()
  cardTemplate: TemplateRef<any>;

  @Input()
  data: any[] = [];

  @Input()
  selectable = false;

  @Input()
  multiSelect = false;

  @Input()
  autoSelect = false;

  @Input()
  selectableFunction: (dataItem: any) => boolean = () => true;

  @Input()
  deletableFunction: (dataItem: any) => boolean = () => true;

  @Input()
  triggerSelectOnClick = true;

  @Input()
  deletable = false;

  @Input()
  noDataText = 'noData';

  @Input()
  elementClass = '';

  @Input()
  styling = 2;

  @Input()
  id: string;

  @Input()
  deleteIcon = 'fa fa-trash';

  @Input()
  useShowMore = false;

  @Input()
  checkboxClass = '!mt-0';

  @Output()
  onClick = new EventEmitter<any>();

  @Output()
  onDelete = new EventEmitter<any>();

  @Output()
  onSelectionChange = new EventEmitter<SelectionChangedEvent>();

  @Output()
  onDoubleClick = new EventEmitter<any>();

  @ViewChildren('elementRef')
  elReference: QueryList<ElementRef>;

  @ViewChildren(Checkbox)
  checkboxes: Checkbox[];

  selectedItems: any[] = [];
  viewGuid: string = Math.random() + '';
  showMore = false;
  showMoreCount = 5;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue) {
      this.selectedItems = [];
      this.showMore = false;
      if (this.autoSelect && this.data.length === 1) {
        this.selectionChange(this.data[0], undefined);
      }
    }
  }

  onClickEvent(dataItem: any, event: any): void {
    this.onClick.emit(dataItem);
    if (this.isSelectable(dataItem) && this.triggerSelectOnClick) {
      this.selectionChange(dataItem, event);
    }
  }

  onDoubleClickEvent(dataItem: any, event: any): void {
    this.onDoubleClick.emit(dataItem);
    if (this.isSelectable(dataItem) && this.triggerSelectOnClick) {
      this.selectionChange(dataItem, event);
    }
  }

  selectionChange(dataItem: any, event: any): void {
    const isSelected = this.isSelected(dataItem);
    if (!this.multiSelect) {
      this.selectedItems = [];
    }
    if (!isSelected) {
      this.selectedItems.push(dataItem);
    }
    else {
      this.selectedItems = this.selectedItems.filter(item => item != dataItem);
    }
    this.onSelectionChange.emit({ item: dataItem, selected: !isSelected });
    event?.stopImmediatePropagation();
  }

  getSelectedItems(): any[] {
    return this.selectedItems;
  }

  getSelectedItem(): any {
    return this.selectedItems[0];
  }

  isSelected(dataItem: any): boolean {
    return this.selectedItems.includes(dataItem);
  }

  isSelectable(dataItem: any): boolean {
    return this.selectable && this.selectableFunction(dataItem);
  }

  isDeletable(dataItem: any): boolean {
    return this.deletable && this.deletableFunction(dataItem);
  }

  deleteItem(dataItem: any, index: number, event: any): void {
    event.stopImmediatePropagation();
    this.data.splice(index, 1);
    this.onDelete.emit(dataItem);
  }

  changeSelected(dataItem: any, status: boolean): void {
    if (this.isSelectable(dataItem) && ((status && !this.isSelected(dataItem)) || (!status && this.isSelected(dataItem)))) {
      this.selectionChange(dataItem, undefined);
    }
  }

  changeAll(status: boolean): void {
    this.data.forEach(item => this.changeSelected(item, status));
  }

  resetSelection(): void {
    this.selectedItems = [];
    this.checkboxes.forEach(checkbox => checkbox.writeValue(false));
  }
}

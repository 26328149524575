import { Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ContactPerson } from '../../../types/branch.type';

@Component({
  selector: 'alimento-ipv-frontend-contact-person-select',
  templateUrl: './contact-person-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactPersonSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactPersonSelectComponent),
      multi: true
    }
  ]
})
export class ContactPersonSelectComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input({required: true})
  contactPersons: ContactPerson[];

  @Input()
  showSelectedPerson = true;

  @Input()
  showLabels = false;

  formControl: FormControl<string> = new FormControl<string>(undefined);

  contactPersonSource: any[];
  private _subscriptions: Subscription[] = [];

  constructor() {
    this._subscriptions.push(
      this.formControl.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contactPersons']?.currentValue) {
      this.contactPersonSource = this.contactPersons.map(contactPerson => {
        return {
          ...contactPerson,
          firstNameFullIndex: contactPerson.firstName + " " + contactPerson.lastName,
          lastNameFullIndex: contactPerson.lastName + " " + contactPerson.firstName
        }
      })
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.formControl.setValue(value, { emitEvent: false });
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate() {
    return this.formControl.valid ? null : { branch: { valid: false } };
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable({ emitEvent: false });
    }
    else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  selectedContactPerson(): ContactPerson {
    return this.contactPersons
      .filter(contactPerson => contactPerson.personId === this.formControl.value)[0];
  }
}

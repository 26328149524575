<p-dialog
  (blur)="popupVisible = false"
  *ngIf='popupVisible'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  [closable]="false"
  appendTo="body"
  header="{{'branches.trainingPlans.request' | translate }}"
>
  <div *ngIf='popupVisible' [formGroup]="formGroup">
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [errorTemplate]='contactErrorTemplate'
                                      formField='contactPersonId'
                                      fieldKey='branches.trainingPlans.contactPerson'
                                      [required]='true'>

      <alimento-ipv-frontend-contact-person-select formControlName='contactPersonId'
                                                   [contactPersons]='contactPersons'
                                                   [showLabels]='true'
      ></alimento-ipv-frontend-contact-person-select>

      <ng-template #contactErrorTemplate>
        <small *ngIf="formGroup.get('contactPersonId').errors?.['invalidContactPerson']">
          {{"branches.trainingPlans.contactPersonHasNoEmail" | translate }}
        </small>
        <small *ngIf="formGroup.get('contactPersonId').errors?.['required']">
          {{"validation.isRequired" | translate:{field: 'branches.trainingPlans.contactPerson' | translate} }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <div *ngIf='latestTrainingPlan' class='mb-4 flex flex-col gap-2 italic'>
      <span *ngIf='latestTrainingPlan.validFrom'>
        {{'branches.trainingPlans.latestTrainingPlan' | translate}}: {{latestTrainingPlan.validFrom | date: "dd MMMM yyyy"}} - {{latestTrainingPlan.validTo | date: "dd MMMM yyyy"}}
      </span>

      <span *ngIf='!latestTrainingPlan.validTo'>
        {{'branches.trainingPlans.latestAlreadyRequested' | translate:{by: latestTrainingPlan.actionExecutedBy, date: (latestTrainingPlan.actionExecutedOn || "") | date: "dd MMMM yyyy"} }}
      </span>
<!--      <p-button *ngIf='!latestTrainingPlan.validTo'-->
<!--                (onClick)='sendReminder()'>-->
<!--        {{'branches.trainingPlans.sendReminder' | translate}}-->
<!--      </p-button>-->
    </div>

    <div class='flex flex-col gap-2 mb-2'>
      <p-radioButton
        formControlName='requestType'
        id='current'
        label="{{ 'branches.trainingPlans.requestCurrent' | translate }}"
        name='requestType'
        [value]='REQUEST_TYPE_CURRENT'
      ></p-radioButton>

      <p-radioButton
        formControlName='requestType'
        id='period'
        label="{{ 'branches.trainingPlans.requestPeriod' | translate }}"
        name='requestType'
        [value]='REQUEST_TYPE_PERIOD'
      ></p-radioButton>
    </div>

    <div *ngIf="formGroup.get('requestType').value === REQUEST_TYPE_PERIOD" class="grid grid-cols-2 gap-4 w-fit">
      <div class="w-[202px]">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='true'
          [errorTemplate]='dateErrorTemplate'
          formField='validFrom'
          fieldKey='branches.trainingPlans.validFrom'
        >
          <lib-date-picker
            formControlName="validFrom"
            id="validFrom"
          ></lib-date-picker>

          <ng-template #dateErrorTemplate>
            <small *ngIf='formGroup.get("validFrom")?.hasError("required")'>
              {{ 'trainings.sessions.dateRequired' | translate }}
            </small>
            <small *ngIf='formGroup.get("validFrom").hasError("invalidDate")'>
              {{ 'validation.invalidDate' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
      <div class="w-[202px]">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='true'
          [errorTemplate]='dateErrorTemplate2'
          formField='validTo'
          fieldKey='branches.trainingPlans.validTo'
        >
          <lib-date-picker
            [showIcon]="true"
            [showOnFocus]='false'
            [keepInvalid]='true'
            formControlName="validTo"
            id="validTo"
          ></lib-date-picker>

          <ng-template #dateErrorTemplate2>
            <small *ngIf='formGroup.get("validTo")?.hasError("required")'>
              {{ 'trainings.sessions.dateRequired' | translate }}
            </small>
            <small *ngIf='formGroup.get("validTo").hasError("invalidDate")'>
              {{ 'validation.invalidDate' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>
    <small *ngIf="formGroup.get('validTo')?.hasError('minValueToLow')" class="p-error block">
      {{ 'branches.trainingPlans.validToToLow' | translate }}
    </small>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        [loading]="loading"
        (onClick)="requestTrainingPlan()"
        icon="pi pi-save"
        iconPos="left"
        label="{{ 'branches.trainingPlans.request' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closePopup()"
        icon="pi pi-times"
        iconPos="left"
        label="{{ 'branches.trainingPlans.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

import {
  Component,
  computed,
  effect,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AuthorizationService,
  CanComponentDeactivate,
  ExtraMenuItem,
  FilterType,
  flattenObject,
  LeaveConfirmService, MyMessageService,
  NOTES_TYPE,
  NotesSidebarComponent,
  Role,
  SearchRequest,
  TabMenuItem,
  TitleService,
  TranslationService,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { filter, first, forkJoin, map, mergeMap, Observable, Subscription, takeWhile, tap, timer } from 'rxjs';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  CancelTrainingDialogComponent
} from '../../components/cancel-training-dialog/cancel-training-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import {
  ParticipantsListDialogComponent
} from '../../components/participants-list-dialog/participants-list-dialog.component';
import { CertificatesDialogComponent } from '../../components/certificates-dialog/certificates-dialog.component';
import { Menu } from 'primeng/menu';
import {
  CommunicateChangesDialogComponent
} from '../../components/communicate-changes-dialog/communicate-changes-dialog.component';
import {
  ApproveEnrollmentsDialogComponent
} from '../../components/approve-enrollments-dialog/approve-enrollments-dialog.component';
import { BasicInfoComponent } from '../../../utils/components/basic-info/basic-info.component';
import {
  DOCUMENT_SET_TYPE,
  getTrainingStatusKey,
  TRAINING_TYPE,
  TrainingStatusKey
} from '../../../types/reference-data.enum';
import {
  ApproveEnrollmentEvent,
  CancelTrainingEvent,
  CertificatesEvent,
  CloseTraining,
  CommunicateChangesEvent,
  ParticipantsListEvent,
  PurchaseEntry,
  ReactivateTraining,
  SearchSalesInvoice,
  Training
} from '../../../types/training.type';
import { TrainingService } from '../../../services/training.service';
import { SessionService } from '../../../services/session.service';
import { EnrollmentService } from '../../../services/enrollment.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { CommunicationsService } from '../../../services/communications.service';
import { EnrollmentActionEnum } from '../../../types/enrollment.type';
import { SessionActionEnum } from '../../../types/session.enum';
import { MailContextType } from '../../../types/communications.enum';
import { PurchaseEntryService } from '../../../services/purchase-entries.service';
import { DocumentService } from '../../../services/document.service';
import { SearchesService } from '../../../services/searches.service';

@Component({
  selector: 'alimento-ipv-frontend-cevora-training-detail',
  templateUrl: './cevora-training-detail.component.html'
})
export class CevoraTrainingDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('basicInfoComponent')
  basicInfoComponent!: BasicInfoComponent;
  @ViewChild(ParticipantsListDialogComponent)
  participantsPopup: ParticipantsListDialogComponent;
  @ViewChild(CertificatesDialogComponent)
  certificatesPopup: CertificatesDialogComponent;
  @ViewChild(CommunicateChangesDialogComponent)
  communicateChangesPopup: CommunicateChangesDialogComponent;
  @ViewChild(ApproveEnrollmentsDialogComponent)
  approveEnrollmentsPopup: ApproveEnrollmentsDialogComponent;
  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;

  @Input()
  readOnly = true;

  sessionsMenuItem: TabMenuItem;
  participantsMenuItem: TabMenuItem;
  communicationMenuItem: TabMenuItem;
  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  activeTabIndex = 0;
  trainingForm: FormGroup;
  trainingId!: string;
  trainingStatus: TrainingStatusKey = TrainingStatusKey.Draft;
  getTrainingStatusKey = getTrainingStatusKey;
  training: WritableSignal<Training> = signal(undefined);
  isOngoing: Signal<boolean> = this.sessionService.isOngoing;
  savingTraining = false;
  loading = false;
  trainingStatusKeys = TrainingStatusKey;
  completeAllAbsencesToggle = new FormControl(false);
  firstSessionDate?: Date;
  closingTraining = false;
  reactivatingTraining = false;
  showNotes = false;
  generateItems: MenuItem[] = [];
  notesHasChanges = false;
  reasonCancelled: string;
  TrainingNote = NOTES_TYPE.TrainingNote;
  documentCount: WritableSignal<number> = signal(undefined);
  communicationsCount: WritableSignal<number> = signal(undefined);
  purchaseEntries: WritableSignal<PurchaseEntry[]> = signal([]);
  salesInvoices: WritableSignal<SearchSalesInvoice[]> = signal([]);

  private _subscriptions: Subscription[] = [];
  private _getCommunicationCountSub: Subscription;
  private readonly MAX_FETCH_COUNT = 3;
  protected readonly TRAINING_TYPE = TRAINING_TYPE;

  get isActivateDisabled(): boolean {
    return !this.trainingForm.get('customTitle')?.value;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = 'Are you sure';
    }
  }

  constructor(private fb: FormBuilder,
              private trainingService: TrainingService,
              private messageService: MyMessageService,
              private confirmationService: ConfirmationService,
              private leaveConfirmationService: LeaveConfirmService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private translateService: TranslateService,
              private translationService: TranslationService,
              public sessionService: SessionService,
              public enrollmentService: EnrollmentService,
              private authorizationService: AuthorizationService,
              private titleService: TitleService,
              private referenceDataService: ReferenceDataService,
              private communicationService: CommunicationsService,
              private purchaseEntryService: PurchaseEntryService,
              private documentService: DocumentService,
              private searchesService: SearchesService) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this._createMenuItems();
    this._createTrainingForm();

    effect(() => {
      this.participantsMenuItem.completed = this.enrollmentService.nrOfParticipants() >= this.training()?.minParticipants;
    });

    effect(() => {
      const firstSessionWithDate = this.sessionService.sessions().filter(session => session.date)[0];
      this.firstSessionDate = firstSessionWithDate ? new Date(firstSessionWithDate.date) : undefined;

      const absencesComplete = this.sessionService.sessions()
        .filter(session => !session.absenceRegistrationComplete).length === 0;
      this.completeAllAbsencesToggle.setValue(absencesComplete , {emitEvent: false});
      if (absencesComplete) {
        this.completeAllAbsencesToggle.disable({emitEvent: false});
      }
      else {
        this.completeAllAbsencesToggle.enable({emitEvent: false});
      }
    });

    effect(() => {
      if (this.training()) {
        this.titleService.setTitle(
          [`${this.translateService.instant('titles.cevoraTrainings.detail')} - ${this.training().customTitle}`]);
        if (this.training().reasonCancelledId) {
          this.referenceDataService.getCancelTrainingReason(this.training().reasonCancelledId)
            .subscribe(reason => this.reasonCancelled = reason.label);
        }
        else {
          this.reasonCancelled = undefined;
        }
        this._fillTrainingForm(this.training());
        this.getPurchaseEntries();
        this.getSalesInvoices();
      }
      this._createMenuItems();
    }, { allowSignalWrites: true });
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        first(),
        map(params => params['trainingId']),
        filter(trainingId => !!trainingId),
        tap(trainingId => (this.trainingId = trainingId)),
        mergeMap(trainingId => this.trainingService.getTraining(trainingId, TRAINING_TYPE.CEVORA))
      )
      .subscribe({
        next: (training) => {
          this.training.set(training);
          this._createGenerateItems();
          this.sessionService.loadSessions(this.trainingId, TRAINING_TYPE.OPEN_TRAINING);
          this.enrollmentService.loadEnrollments(this.trainingId);
          this._checkForNewCommunication();
          if (!window.history.state?.newTraining) {
            this._loadDocuments();
          }
        },
        error: () => {
          this.router.navigate(['error'], {
            state: {
              message: this.translateService.instant('error.itemWithIdDoesNotExist', {
                item: this.translateService.instant('trainings.cevoraTraining'),
                id: this.trainingId
              }),
              redirectUrl: '/trainings'
            }
          });
        }
      });

    this._subscriptions.push(
      this.route.queryParams.subscribe(
        queryParams => this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0
      )
    );

    this._subscriptions.push(
      this.completeAllAbsencesToggle.valueChanges.subscribe(newValue => {
        this.trainingService.completeAllAbsences(this.trainingId).pipe(first()).subscribe({
          next: () => {
            this.messageService.success();

            this.loadTrainingData();
            this.sessionService.loadSessions(this.trainingId, TRAINING_TYPE.CEVORA);
            this.enrollmentService.loadEnrollments(this.trainingId);
            this.enrollmentService.loadEnrollmentRequests(this.trainingId);

            this.completeAllAbsencesToggle.setValue(newValue, { emitEvent: false });
          },
          error: () => {
            this.completeAllAbsencesToggle.setValue(!newValue, { emitEvent: false });
          }
        })
      })
    )
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.trainingForm.dirty && !this.notesHasChanges) {
      return true;
    }

    const saveActions: Observable<any>[] = [];
    if (this.trainingForm.dirty) {
      saveActions.push(this._saveTrainingData());
    }

    if (this.notesHasChanges) {
      saveActions.push(this.notesSidebarComponent.saveChanges());
    }

    return this.leaveConfirmationService.leaveDialog(() => forkJoin(saveActions));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe());
    this._getCommunicationCountSub?.unsubscribe();
    this.enrollmentService.clearEnrollments();
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem): void {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  saveTraining(): void {
    const executeSave = () => {
      this.savingTraining = true;
      this._saveTrainingData()
        .subscribe({
          next: () => this.savingTraining = false,
          error: () => this.savingTraining = false
        });
    };

    if (this.basicInfoComponent.hasInactiveCaseManagersOrCounselors()) {
      this.confirmationService.confirm({
        header: this.translateService.instant('trainings.inactiveCaseManagerDialog.title'),
        message: this.translateService.instant('trainings.inactiveCaseManagerDialog.message'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.accept'),
        acceptIcon: 'hidden',
        rejectLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.reject'),
        rejectButtonStyleClass: 'inverted-button',
        accept: () => executeSave(),
        reject: () => {
          this.activeTabIndex = 0;
          setTimeout(() => this.basicInfoComponent.focusFirstInactiveCaseManager());
        }
      });
    }
    else {
      executeSave();
    }
  }

  deleteTraining(): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('trainings.deleteDialog.title'),
      message: this.translateService.instant('trainings.deleteDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('trainings.deleteDialog.delete'),
      acceptIcon: 'hidden',
      rejectLabel: this.translateService.instant('trainings.deleteDialog.cancel'),
      rejectButtonStyleClass: 'inverted-button',
      accept: () => {
        this.trainingService.deleteTraining(this.trainingId, TRAINING_TYPE.CEVORA)
          .subscribe(() => this.router.navigate(['/trainings']));
      }
    });
  }

  cancelTraining(event: CancelTrainingEvent, dialog: CancelTrainingDialogComponent) {
    event.setLoading(true);
    this.trainingService.cancelTraining(this.trainingId, event.data, TRAINING_TYPE.CEVORA).subscribe({
      next: () => {
        this.trainingStatus = TrainingStatusKey.Cancelled;

        this.messageService.success('trainings.cancelled');

        event.setLoading(false);
        dialog.closeDialog();

        this.loadTrainingData();
        this.sessionService.loadSessions(this.trainingId, TRAINING_TYPE.OPEN_TRAINING);
        this.enrollmentService.loadEnrollments(this.trainingId);
        this._checkForNewCommunication();
      }
    });
  }

  loadTrainingData(): void {
    this.trainingService.getTraining(this.trainingId, TRAINING_TYPE.CEVORA).pipe(first())
      .subscribe((training: Training) => {
        this.training.set(training);
      });
  }

  closeTraining(closeTraining: CloseTraining) {
    this.trainingService.closeTraining(this.trainingId, closeTraining, TRAINING_TYPE.CEVORA).subscribe({
      next: () => {
        this.trainingStatus = TrainingStatusKey.Completed;

        this.messageService.success('states.completed');

        this.sessionService.loadSessions(this.trainingId, TRAINING_TYPE.OPEN_TRAINING);
        this.enrollmentService.loadEnrollments(this.trainingId);
      }
    });
  }

  reactivateTraining(reactivateTraining: ReactivateTraining) {
    this.trainingService.reactivateTraining(this.trainingId, reactivateTraining, TRAINING_TYPE.CEVORA).subscribe({
      next: () => {
        this.trainingStatus = TrainingStatusKey.Active;

        this.messageService.success('states.reactivated');

        this.sessionService.loadSessions(this.trainingId, TRAINING_TYPE.OPEN_TRAINING);
        this.enrollmentService.loadEnrollments(this.trainingId);
      }
    });
  }

  communicateChanges(event: CommunicateChangesEvent): void {
    event.setLoading(true);
    this.trainingService.communicateChanges(this.trainingId, event.data).pipe(first())
      .subscribe({
        next: () => {
          event.setLoading(false);
          this._checkForNewCommunication();
          this.communicateChangesPopup.closeDialog();
        },
        error: () => event.setLoading(false)
      });
  }

  approveEnrollments(event: ApproveEnrollmentEvent): void {
    event.setLoading(true);
    this.trainingService.sendEnrollmentMails(this.trainingId, event.data).pipe(first())
      .subscribe({
        next: () => {
          this._checkForNewCommunication();
          this.approveEnrollmentsPopup.closeDialog();
          event.setLoading(false);
        },
        error: () => event.setLoading(false)
      });
  }

  requestParticipantsList(event: ParticipantsListEvent): void {
    event.setLoading(true);
    (event.sendMail ?
      this.trainingService.sendParticipantsList(this.trainingId, event.data) :
      this.trainingService.requestParticipantsList(this.trainingId, event.data, TRAINING_TYPE.OPEN_TRAINING))
      .pipe(first())
      .subscribe({
        next: () => {
          this._checkForNewCommunication();
          this.participantsPopup.closeDialog();
          event.setLoading(false);
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  requestCertificates(event: CertificatesEvent): void {
    event.setLoading(true);
    this.trainingService.requestCertificates(this.trainingId, event.data, TRAINING_TYPE.OPEN_TRAINING).pipe(first())
      .subscribe({
        next: () => {
          event.setLoading(false);
          this.certificatesPopup.closeDialog();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  generateButtonClicked(event: Event, menu: Menu) {
    this._createGenerateItems();
    menu.toggle(event);
  }

  onEnrollmentChange(event: EnrollmentActionEnum) {
    if ([EnrollmentActionEnum.create, EnrollmentActionEnum.cancel].includes(event)) {
      this._checkForNewCommunication();
    }
  }

  onSessionChange(event: SessionActionEnum) {
    if (event === SessionActionEnum.cancel) {
      this._checkForNewCommunication();
    }
  }

  private _createTrainingForm(): void {
    this.trainingForm = this.fb.group({
      trainingAlimentoId: [null],
      typeId: [TRAINING_TYPE.CEVORA],
      customTitle: ['', [Validators.required, Validators.maxLength(200)]],
      basicDataForm: []
    });
  }

  private _fillTrainingForm(training: Training) {
    this.trainingStatus = (training.status?.data as TrainingStatusKey) ?? TrainingStatusKey.Active;

    this.trainingForm.patchValue({
      trainingAlimentoId: training.trainingAlimentoId,
      typeId: training.typeId,
      customTitle: training.customTitle,
      basicDataForm: BasicInfoComponent.createFormData(training)
    });

    if (this.readOnly) {
      this.trainingForm.disable();
    }
  }

  private _saveTrainingData(): Observable<{ id: string }> {
    const training: Training = {
      ...(flattenObject(this.trainingForm.value) as Training)
    };

    if (this.trainingForm.value.basicDataForm?.maxParticipants) {
      this.participantsMenuItem.maxCount = this.trainingForm.value.basicDataForm?.maxParticipants;
    }

    validateAllFormFields(this.trainingForm);

    if (!this.trainingForm.valid) {
      this.messageService.notAllFieldsValid();
      return new Observable<{ id: string }>((observable) => observable.error());
    }

    const createOrUpdateTraining$ = this.trainingId
      ? this.trainingService.updateTraining(this.trainingId, training, TRAINING_TYPE.CEVORA)
      : this.trainingService.createTraining(training, TRAINING_TYPE.CEVORA);

    return createOrUpdateTraining$.pipe(
      tap({
        next: (response: { id: string }) => {
          this.trainingId = response.id;
          this.trainingForm.markAsPristine();
          this.messageService.success('trainings.saved');
          this._setDetailUrl();

          this.loadTrainingData();
        }
      })
    );
  }

  private _setDetailUrl(): void {
    if (this.trainingId) {
      this.location.replaceState(
        this.router
          .createUrlTree(['/trainings', 'cevora', this.trainingId, 'detail'], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.sessionsMenuItem = {
          index: 2,
          name: 'sessions',
          title: this.translateService.instant('trainings.sessions.sessions'),
          count: this.sessionService.nrOfSessions,
          disabled: !this.training()?.trainingId
        };
        this.participantsMenuItem = {
          index: 3,
          name: 'participants',
          title: this.translateService.instant('trainings.participantsHeader'),
          count: this.enrollmentService.nrOfParticipants,
          maxCount: this.training()?.maxParticipants,
          disabled: !this.training()?.trainingId
        };
        this.communicationMenuItem = {
          index: 4,
          name: 'communications',
          title: this.translateService.instant('trainings.communications.communications'),
          count: this.communicationsCount,
          disabled: !this.training()?.trainingId
        };
        this.tabMenuItems = [
          { index: 0, name: 'basicData', title: this.translateService.instant('trainings.basicData.formTitle') },
          this.sessionsMenuItem,
          this.participantsMenuItem,
          this.communicationMenuItem,
          {
            index: 5,
            name: 'invoices',
            title: this.translateService.instant('trainings.invoices.menuTitle'),
            disabled: !this.training()?.trainingId,
            count: computed(() => this.purchaseEntries().length + this.salesInvoices().length)
          }
        ];

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('trainings.notesData.formTitle'),
            disabled: !this.training()?.trainingId,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          },
          {
            name: 'documents',
            title: this.translateService.instant('documents'),
            disabled: !this.training()?.trainingId || !this.training()?.documentSetUrl,
            count: this.documentCount,
            command: () => {
              window.open(this.training()?.documentSetUrl, '_blank');
            }
          }
        ];
      })
    );
  }

  private _createGenerateItems(): void {
    const generateDisabled = this.trainingStatus === this.trainingStatusKeys.Draft ||
      this.trainingStatus === this.trainingStatusKeys.Cancelled ||
      this.sessionService.nrOfSessions() === 0 ||
      this.enrollmentService.nrOfParticipants() === 0;

    const participantsListDisabled = generateDisabled ||
      this.trainingStatus === this.trainingStatusKeys.Completed ||
      this.sessionService.nrOfSessionsWithDates() === 0;

    const canSendMails = this.trainingStatus === this.trainingStatusKeys.Active &&
      this.sessionService.nrOfSessions() > 0 &&
      this.sessionService.nrOfSessionsWithDates() === this.sessionService.nrOfSessions() &&
      this.enrollmentService.nrOfParticipants() > 0;

    this.generateItems = [
      this._getMenuItem('trainings.certificatesPopup.certificates', 'collections-bookmark',
        () => this.certificatesPopup.open(), generateDisabled),
      this._getMenuItem('trainings.participantsPopup.participantsList', 'list',
        () => this.participantsPopup.open(), participantsListDisabled,
        this.sessionService.nrOfSessionsWithDates() === 0 ? this.translateService.instant('trainings.noSessionWithDateTooltip') : ''),
      this._getMenuItem('trainings.approveEnrollments.title', 'mail',
        () => this.approveEnrollmentsPopup.open(), !canSendMails),
      this._getMenuItem('trainings.communicateChangesDialog.title', 'mail',
        () => this.communicateChangesPopup.open(), !canSendMails)
    ];
  }

  private _getMenuItem(label: string, icon: string, command: any, disabled: boolean, tooltip?: string): MenuItem {
    return {
      label: this.translateService.instant(label),
      icon: icon,
      command: command,
      iconStyle: {
        position: 'absolute',
        right: '2px'
      },
      styleClass: 'has-icon',
      disabled: disabled,
      tooltip: tooltip
    };
  }

  private _loadDocuments(): void {
    if (this.training()?.documentSetUrl) {
      this.documentService.getDocumentCount(this.trainingId, DOCUMENT_SET_TYPE.TRAINING).pipe(first())
        .subscribe(count => {
          this.documentCount.set(count.count);
          if (count.count === -1) {
            this.documentService.createDocumentSet(this.trainingId, DOCUMENT_SET_TYPE.TRAINING).pipe(first())
              .subscribe(documentSet => {
                this.training.update(training => {
                  training.documentSetUrl = documentSet.url;
                  return training;
                });
                this.documentCount.set(0);
              });
          }
        });
    }
  }

  private _loadCommunicationsCount(fetching: WritableSignal<number>): void {
    if (this.training()) {
      const searchRequest: SearchRequest = { filters: [] };
      searchRequest.filters.push({ type: FilterType.filterContextType, values: [MailContextType.Training] });
      searchRequest.filters.push({ type: FilterType.filterContextValue, values: [this.training().trainingId] });
      this.communicationService.getCommunicationsCount(searchRequest).pipe(first())
        .subscribe(count => {
          const currentCount = this.communicationsCount();
          if (fetching && currentCount !== undefined && count > currentCount) {
            this.messageService.success('trainings.communicationSent');
          }
          fetching.set(this.MAX_FETCH_COUNT);
          this.communicationsCount.set(count);
        });
    }
  }

  private _checkForNewCommunication(): void {
    this._getCommunicationCountSub?.unsubscribe();
    const fetching = signal(0);
    this._getCommunicationCountSub = timer(0, 3000)
      .pipe(
        takeWhile(() => fetching() < this.MAX_FETCH_COUNT),
        tap(() => {
          this._loadCommunicationsCount(fetching);
          fetching.set(fetching() + 1);
        })
      )
      .subscribe();
  }

  getPurchaseEntries(): void {
    if (this.trainingId) {
      this.purchaseEntryService.getPurchaseEntries(this.trainingId).pipe(first())
        .subscribe(purchaseEntries => this.purchaseEntries.set(purchaseEntries));
    }
  }

  getSalesInvoices(): void {
    if (this.trainingId) {
      const searchRequest: SearchRequest = {
        filters: [{type: FilterType.filterTrainingId, values: [this.trainingId]}]
      };

      this.searchesService.searchSalesInvoices(searchRequest).pipe(first())
        .subscribe(response => this.salesInvoices.set(response.data));
    }
  }
}

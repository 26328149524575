import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';
import { delay, first } from 'rxjs';
import { DateMapper, emailPattern, FilterType, FormComponent, SearchFilterValue } from '@alimento-ipv-frontend/ui-lib';
import { Person } from '../../../types/person.type';
import { EnrollmentDetail, EnrollmentRequestParticipant } from '../../../types/enrollment.type';
import { ContactPerson, InterimOffice } from '../../../types/branch.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { BranchService } from '../../../services/branch.service';
import { EmploymentService } from '../../../services/employment.service';
import { InterimOfficeService } from '../../../services/interim-office.service';
import { PersonService } from '../../../services/person.service';
import { Training } from '../../../types/training.type';
import { isPupilProjectType, isTeacherProjectType } from '../../../types/reference-data.enum';
import { GlobalMapper } from '../../../utils/mapper/global.mapper';
import { WORK_STATUS } from '../../../types/person.enum';
import { EnterpriseGroupService } from '../../../services/enterprise-group.service';

@Component({
  selector: 'alimento-ipv-frontend-extra-form',
  templateUrl: './enrollment-extra-form.component.html',
  styleUrls: ['./enrollment-extra-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EnrollmentExtraFormComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: EnrollmentExtraFormComponent
    },
    { provide: FormComponent, useExisting: EnrollmentExtraFormComponent }
  ]
})
export class EnrollmentExtraFormComponent extends FormComponent implements ControlValueAccessor, Validator, OnChanges {
  @Input()
  person!: Person;

  @Input()
  branchId?: string;

  @Input()
  groupId?: string;

  @Input()
  enterpriseId?: string;

  @Input()
  training: Training;

  @Input()
  enrollment?: EnrollmentDetail;

  @Input()
  showBranchContactPersonInfo = true;

  @Input()
  showContactInfo = true;

  @Input()
  loadActualData = true;

  @Input()
  trainingStartDate?: Date;

  @Input()
  showRestructuringInfo = true;

  @ViewChild('focusElement')
  focusElement: ElementRef;

  workStatus$ = this.referenceData.getWorkStatuses();
  restructuringOrDismissal$ = this.referenceData.getRestructuringOrDismissals();
  contactPersons: ContactPerson[] = [];
  responsibilities$ = this.referenceData.getResponsibilities();
  contactResponsibilitiesReadOnly: string;
  interimAddress: FormControl<any>;
  interimOffice: InterimOffice;
  showInterimOfficeFields = false;
  showBranchField = false;
  isInstitution = false;

  workStatusReadOnly: string;
  interimOfficeReadOnly: string;
  restructuringOrDismissalReadOnly: string;
  contactPersonReadOnly: ContactPerson;
  branchSearchFilter: SearchFilterValue[] = [];

  private readonly _NO_PERSON_ID = 'no-id';
  private static readonly _RESTRUCTURING_OR_DISMISSAL_DEFAULT = '72107bb1-0471-11ee-ad33-3e2ade19d699';
  readonly _INTERIM_PC_118 = 'a3bd8f32-ab6d-4749-8b1b-11ef962b5529';
  readonly _INTERIM_PC_220 = '4d8b315e-da9e-445f-ab54-68737fbfd1cb';
  showEmploymentStartDate = false;

  constructor(
    private formBuilder: FormBuilder,
    private referenceData: ReferenceDataService,
    private branchService: BranchService,
    private employmentService: EmploymentService,
    private interimOfficeService: InterimOfficeService,
    private personService: PersonService,
    private enterpriseGroupService: EnterpriseGroupService
  ) {
    super();
    this.createFormGroup();
  }

  static createFormData(value: EnrollmentDetail): any {
    if (value) {
      return {
        enrollment: {
          restructuringOrDismissal: value.enrollment.restructuringOrDismissal?.data || null,
          outsideWorkingHours: value.enrollment.outsideWorkingHours || false,
          recuperated: value.enrollment.recuperated || false,
          isContact: value.enrollment.isContact || false,
          contactPerson: value.enrollment.contactPerson,
          contactEmail: value.enrollment.contactEmail,
          contactPhoneNumber: value.enrollment.contactPhoneNumber || '',
          contactResponsibilities: value.enrollment.contactResponsibilities?.map(responsibilities => responsibilities.data) || [],
          workStatus: value.enrollment.workStatus?.data || '',
          interimOfficeId: value.enrollment.interimOfficeId || null,
          newInterimOffice: false,
          interimOfficeName: '',
          interimOfficeAddress: '',
          interimOfficeContactFirstName: value.enrollment.interimOfficeContactFirstName || '',
          interimOfficeContactLastName: value.enrollment.interimOfficeContactLastName || '',
          interimOfficeContactEmail: value.enrollment.interimOfficeContactEmail || ''
        },
        employment: {
          function: value.employment.function || '',
          employer: value.enrollment.branch?.data || undefined,
          email: value.employment.email,
          phoneNumber: value.employment.phoneNumber || '',
          employmentId: value.employment.employmentId,
          startDate: value.employment.startDate ? new Date(value.employment.startDate) : undefined
        }
      };
    }
    else {
      return undefined;
    }
  }

  static createFormDataFromParticipant(value: EnrollmentRequestParticipant): any {
    if (value) {
      return {
        enrollment: {
          restructuringOrDismissal: value.restructuringOrDismissalId || this._RESTRUCTURING_OR_DISMISSAL_DEFAULT,
          outsideWorkingHours: value.outsideWorkingHours || false,
          recuperated: value.recuperated || false,
          isContact: false,
          contactPerson: 'no-id',
          contactEmail: '',
          contactPhoneNumber: '',
          contactResponsibilities: [],
          workStatus: value.workStatusId,
          interimOfficeId: value.interimOfficeId || null,
          newInterimOffice: !value.interimOfficeId,
          interimOfficeName: value.interimOfficeName || '',
          interimOfficeAddress: GlobalMapper.getAddress(
            value.interimOfficeStreet,
            value.interimOfficeNumber,
            value.interimOfficeMailbox,
            value.interimOfficePostalCode,
            value.interimOfficeCity
          ),
          interimOfficeContactFirstName: value.interimOfficeContactFirstName || '',
          interimOfficeContactLastName: value.interimOfficeContactLastName || '',
          interimOfficeContactEmail: value.interimOfficeContactEmail || ''
        },
        employment: {
          function: value.function || '',
          employer: value.employerId || undefined,
          email: value.employmentEmail || '',
          phoneNumber: value.employmentPhoneNumber || '',
          employmentId: undefined,
          startDate: value.employmentStartDate ? new Date(value.employmentStartDate) : undefined
        }
      };
    }
    else {
      return undefined;
    }
  }

  override writeValue(value: any): void {
    if (value) {
      this.formGroup.patchValue(value);
      this._setReadOnlyValues(value);
    }
    else {
      this.formGroup.reset({ enrollment: { restructuringOrDismissal: EnrollmentExtraFormComponent._RESTRUCTURING_OR_DISMISSAL_DEFAULT } });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loadActualData) {
      if ((changes['branchId']?.currentValue || changes['person']?.currentValue) && !this.enrollment?.enrollment?.enrollmentId) {
        if (this.branchId && this.person) {
          this.employmentService
            .getEmploymentsOfPersonByBranch(this.branchId, this.person.personId)
            .pipe(first())
            .subscribe((employment) => {
              this.formGroup.patchValue({
                employment: {
                  employmentId: employment ? employment.employmentId : null,
                  function: employment ? employment.function : '',
                  email: employment ? employment.professionalEmail : this.person.email,
                  phoneNumber: employment ? employment.phoneNumber : this.person.phoneNumber
                },
                enrollment: {
                  workStatus: employment ? employment.workStatus?.data : null
                }
              });
              this._updateInterimRequiredFields();
            });
        }
        else if (this.person) {
          this.formGroup.patchValue({
            enrollment: {
              isContact: true,
              contactPerson: this.person.personId || this._NO_PERSON_ID,
              contactEmail: this.person.email,
              contactPhoneNumber: this.person.phoneNumber,
              workStatus: null
            },
            employment: {
              employmentId: null,
              employer: null,
              function: '',
              email: this.person.email,
              phoneNumber: this.person.phoneNumber
            }
          });
        }
      }

      if (Object.keys(changes).includes('branchId')) {
        if (this.branchId) {
          let contactPersonRequest;
          if (this.groupId) {
            contactPersonRequest = this.enterpriseGroupService.getContactPersons(this.groupId);
          }
          else {
            contactPersonRequest = this.branchService.getContactPersons(this.branchId);
          }

          contactPersonRequest.pipe(first()).subscribe((contactPersons) => {
            this.contactPersons = contactPersons;
            this._updateContactPersonValues();
          });
        }
        else {
          this.contactPersons = [];
        }
      }
    }

    if (changes['training']?.currentValue) {
      this.isInstitution = isTeacherProjectType(this.training.typeId) || isPupilProjectType(this.training.typeId);
    }

    if (changes['groupId']?.currentValue || changes['enterpriseId']?.currentValue) {
      if (this.groupId) {
        this.branchSearchFilter = [{ type: FilterType.filterEnterpriseGroupId, values: [this.groupId] }];
      }
      else {
        this.branchSearchFilter = [{ type: FilterType.filterEnterpriseId, values: [this.enterpriseId] }];
      }

      this._updateBranchRequiredFields();
    }

    this.formGroup?.get('employment.email').setValidators(this.showContactInfo ?
      [Validators.required, emailPattern()] : [emailPattern()]);
    this.formGroup?.get('enrollment.contactPerson').setValidators(this.showBranchContactPersonInfo ? [Validators.required] : []);
  }

  validate(_: FormGroup) {
    return (this.formGroup.disabled || this.formGroup.valid) ? null : { enrollmentExtraForm: { valid: false } };
  }

  private createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      enrollment: this.formBuilder.group({
        restructuringOrDismissal: [EnrollmentExtraFormComponent._RESTRUCTURING_OR_DISMISSAL_DEFAULT, Validators.required],
        outsideWorkingHours: [false],
        recuperated: [false],
        isContact: [false],
        contactPerson: [null],
        contactEmail: [''],
        contactPhoneNumber: [''],
        contactResponsibilities: [null],
        workStatus: ['', Validators.required],
        interimOfficeId: [],
        interimOfficeContactFirstName: [],
        interimOfficeContactLastName: [],
        interimOfficeContactEmail: ['', emailPattern()],
        newInterimOffice: [false],
        interimOfficeName: [''],
        interimOfficeAddress: [{
          street: '',
          country: '',
          postalCode: '',
          mailbox: '',
          houseNumber: '',
          city: ''
        }]
      }),
      employment: this.formBuilder.group({
        function: [''],
        employer: ['', this.enterpriseId == null && this.groupId == null ? null : Validators.required],
        email: ['', [emailPattern()]],
        phoneNumber: [''],
        employmentId: [''],
        startDate: [undefined]
      })
    });

    this._addListeners();
  }

  private _setReadOnlyValues(value: any): void {
    if (value.enrollment?.workStatus) {
      this.referenceData.getWorkStatus(value.enrollment.workStatus).pipe(first())
        .subscribe(workStatus => this.workStatusReadOnly = workStatus.label);
    }
    if (value.enrollment?.interimOfficeId) {
      this.interimOfficeService.getInterimOffice(value.enrollment.interimOfficeId).pipe(first())
        .subscribe(interimOffice => this.interimOfficeReadOnly = interimOffice.name);
    }
    if (value.enrollment?.restructuringOrDismissal) {
      this.referenceData.getRestructuringOrDismissal(value.enrollment.restructuringOrDismissal).pipe(first())
        .subscribe(restructuringOrDismissal => this.restructuringOrDismissalReadOnly = restructuringOrDismissal.label);
    }
  }

  private _addListeners(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];

    this.subscriptions.push(
      this.formGroup.valueChanges.pipe(delay(1)).subscribe((value) => {
        const result = JSON.parse(JSON.stringify(value));
        if (result.enrollment.contactPerson === this._NO_PERSON_ID) {
          result.enrollment.contactPerson = undefined;
        }
        result.employment.startDate = DateMapper.getDateFromDateTimeAsString(result.employment.startDate);
        this.onChange(result);
        this.onTouched();
      })
    );

    this.subscriptions.push(
      this.formGroup.get('enrollment.workStatus').valueChanges.pipe(delay(1))
        .subscribe(() => this._updateInterimRequiredFields())
    );

    this.subscriptions.push(
      this.formGroup.get('enrollment.interimOfficeId').valueChanges.subscribe(interimOfficeId => {
        if (interimOfficeId) {
          this.interimOfficeService.getInterimOffice(interimOfficeId).pipe(first())
            .subscribe(interimOffice => {
              this.interimOffice = interimOffice;
              this.interimAddress = this.formBuilder.control({
                street: interimOffice.street,
                houseNumber: interimOffice.houseNumber,
                city: interimOffice.city,
                postalCode: interimOffice.postalCode,
                mailbox: interimOffice.mailbox,
                country: interimOffice.country
              });
              if (this.loadActualData) {
                this.formGroup.patchValue({
                  enrollment: {
                    interimOfficeContactFirstName: interimOffice.contactFirstName,
                    interimOfficeContactLastName: interimOffice.contactLastName,
                    interimOfficeContactEmail: interimOffice.email
                  }
                });
              }
            });
        }
        else {
          this.interimOffice = undefined;
          this.interimAddress = undefined;
        }
      })
    );

    const contactPerson = this.formGroup.get('enrollment.contactPerson');
    this.subscriptions.push(contactPerson.valueChanges.subscribe(() => this._updateContactPersonValues()));

    this.subscriptions.push(
      this.formGroup.get('enrollment.isContact').valueChanges.subscribe((newValue) => {
        if (newValue) {
          contactPerson.setValue(this.person?.personId || this._NO_PERSON_ID);
          if (this.formGroup.get('employment.email').value) {
            this.formGroup.get('enrollment.contactEmail').setValue(this.formGroup.get('employment.email').value);
          }
          if (this.formGroup.get('employment.phoneNumber').value) {
            this.formGroup.get('enrollment.contactPhoneNumber').setValue(this.formGroup.get('employment.phoneNumber').value);
          }
        }
        else {
          contactPerson.reset();
        }
      })
    );

    this.subscriptions.push(
      this.formGroup.get('enrollment.workStatus').valueChanges.subscribe(() => this._setShowEmploymentStartDate())
    );
  }

  private _updateContactPersonValues(): void {
    const personId = this.formGroup.get('enrollment.contactPerson').value;
    const person = this.contactPersons.filter((person) => (person.personId || this._NO_PERSON_ID) === personId)[0];
    if (!person && personId && personId !== this._NO_PERSON_ID) {
      this.personService.getPerson(personId).pipe(first())
        .subscribe(person => {
          const inactiveContactPerson = {
            personId: person.personId,
            firstName: person.firstName,
            lastName: person.lastName,
            phoneNumber: person.phoneNumber,
            email: person.email,
            responsibilities: [],
            isInactive: true
          } as ContactPerson;
          this._patchContactPersonValues(inactiveContactPerson);
          this.contactPersons.push(inactiveContactPerson);
        });
    }
    else {
      this._patchContactPersonValues(person);
    }
  }

  private _patchContactPersonValues(person?: ContactPerson): void {
    this.formGroup.patchValue({
      enrollment: {
        contactEmail: person?.email,
        contactPhoneNumber: person?.phoneNumber,
        contactResponsibilities: person?.responsibilities.map((responsibility) => responsibility.data)
      }
    });

    this.contactResponsibilitiesReadOnly = person?.responsibilities.map(responsibility => responsibility.label).join(', ');
    this.contactPersonReadOnly = person;
  }

  private _updateInterimRequiredFields(): void {
    const workStatus = this.formGroup.get('enrollment.workStatus').value;
    this.showInterimOfficeFields = workStatus === this._INTERIM_PC_118 || workStatus === this._INTERIM_PC_220;
    this.formGroup.get('enrollment.interimOfficeId').setValidators(this.showInterimOfficeFields ? Validators.required : []);
    this.formGroup.get('enrollment.interimOfficeId').updateValueAndValidity();
  }

  private _updateBranchRequiredFields(): void {
    this.showBranchField = !(this.enterpriseId == null && this.groupId == null);

    this.formGroup.get('employment.employer').setValidators(this.showBranchField ? Validators.required : []);
    this.formGroup.get('employment.employer').updateValueAndValidity();
  }

  get isContact(): boolean {
    return this.formGroup.get('enrollment.isContact').value;
  }

  override setFocus(): void {
    setTimeout(() => this.focusElement?.nativeElement.focus());
  }

  private _setShowEmploymentStartDate(): void {
    const workStatusId = this.formGroup.get('enrollment.workStatus').value;

    if ((workStatusId === WORK_STATUS.PC_118 || workStatusId === WORK_STATUS.PC_220) && this.trainingStartDate) {
      const age = DateMapper.getAge(new Date(this.person?.dateOfBirth), new Date(this.trainingStartDate));
      this.showEmploymentStartDate = age <= 26 || age >= 50;
    }
    else {
      this.showEmploymentStartDate = false;
    }
  }

  override getData(): any {
    const data = super.getData();
    data.employment.startDate = DateMapper.getDateFromDateTimeAsString(data.employment.startDate);
    return data;
  }
}

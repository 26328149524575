import { Route } from '@angular/router';
import { EnterpriseGroupDetailComponent } from './pages/enterprise-group-detail/enterprise-group-detail.component';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';
import { EnterpriseGroupListComponent } from './pages/enterprise-group-list/enterprise-group-list.component';

export const enterpriseGroupRoutes: Route[] = [
  {
    path: '',
    component: EnterpriseGroupListComponent,
    data: { title: 'titles.enterpriseGroups.list' }
  },
  {
    path: 'create',
    component: EnterpriseGroupDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.enterpriseGroups.create' }
  },
  {
    path: ':enterpriseGroupId',
    component: EnterpriseGroupDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.enterpriseGroups.detail' }
  },
];

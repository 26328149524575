import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CloneTrainingEvent, Training } from '../../../types/training.type';

@Component({
  selector: 'alimento-ipv-frontend-clone-training-dialog',
  templateUrl: './clone-training-dialog.component.html'
})
export class CloneTrainingDialogComponent {
  @Input()
  training: Training;

  @Input()
  firstSessionDate?: Date;

  @Output()
  cloneTraining = new EventEmitter<CloneTrainingEvent>();

  dialogVisible = false;

  cloneTrainingForm: FormGroup;

  loading = false;

  constructor(private fb: FormBuilder) {
    this.cloneTrainingForm = this.fb.group({
      includeSessions: [false]
    });
  }

  open(): void {
    this.dialogVisible = true;
    this.loading = false;
  }

  clone() {
    this.cloneTraining.emit({
      trainingId: this.training.trainingId,
      includeSessions: this.cloneTrainingForm.value.includeSessions,
      setLoading: state => this.loading = state
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.cloneTrainingForm.reset();
  }
}

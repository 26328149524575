<p-dialog
  *ngIf='dialogVisible'
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '80vw', height: '80vh' }"
  appendTo="body"
  header="{{ 'enterpriseGroups.selectEnterpriseGroup' | translate }}"
>

  <p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel [header]='"enterpriseGroups.existingEnterpriseGroup" | translate'>
      <div class='mt-2'>
        <lib-search [data$]='search$'
                    [cardTemplate]='cardTemplate'
                    [selectable]='true'
                    [filters]='searchFilters'
                    [inlineFilters]='true'
                    [activeFiltersOnTop]='false'
                    [showSearchFilter]='false'
                    (onSelectChange)='selectionChange($event)'
                    name='enterprise-group-select'
                    searchPlaceholderKey='enterpriseGroups.searchPlaceholder'
                    availableResultsTranslateKey='enterpriseGroups.menuTitle'
        >
          <ng-template #cardTemplate let-item="item" let-selected="selected">
            <alimento-ipv-frontend-enterprise-group-card [enterpriseGroup]='item'
                                                         [selected]='selected'
            >
            </alimento-ipv-frontend-enterprise-group-card>
          </ng-template>
        </lib-search>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]='"enterpriseGroups.newEnterpriseGroup" | translate'>
      <div class='my-2'>
        <alimento-ipv-frontend-enterprise-group [enterpriseGroup]='newEnterpriseGroup'
                                                [showMainEnterprise]='false'
        ></alimento-ipv-frontend-enterprise-group>

        <div>
          <span>{{'enterpriseGroups.defaultMainEnterpriseCurrent' | translate}}</span>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="selectEnterpriseGroup()"
        [loading]='loading'
        label="{{ 'enterpriseGroups.selectEnterpriseGroup' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'leaveDialog.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

<div>
  <div *ngIf='enterpriseGroup' class='flex flex-grow items-center w-full'>
    <div class='flex-grow' *ngIf='enterpriseGroup.mainEnterpriseId'>
      <alimento-ipv-frontend-enterprise-group-card
        [enterpriseGroup]='enterpriseGroup'
        [clickable]='false'
      ></alimento-ipv-frontend-enterprise-group-card>
    </div>
    <div class='flex-grow' *ngIf='!enterpriseGroup.mainEnterpriseId'>
      <div class="shadow-grey-2 shadow-md my-2 p-4 hover:bg-grey-1 relative border border-input-border cursor-pointer bg-white">
        <span>{{enterpriseGroup.groupName}}</span>
      </div>
    </div>
    <div>
      <button *ngIf="!disabled && enterpriseId !== enterpriseGroup.mainEnterpriseId" (click)="removeEnterpriseGroup()" class="p-2 group">
        <i class="pi pi-times p-2 text-grey-3 group-hover:bg-primary-hover"></i>
      </button>
    </div>
  </div>
  <div *ngIf='!enterpriseGroup && !disabled'
       class='flex items-center w-fit p-2 text-primary hover:bg-primary-hover' >
    <button (click)='addEnterpriseGroup()'>{{'enterpriseGroups.selectEnterpriseGroup' | translate}}</button>
  </div>
</div>

<alimento-ipv-frontend-reimbursement-popup #reimbursementPopupComponent
                                           (formSubmit)='reimbursementSubmit($event, reimbursementPopupComponent)'
                                           [trainingData]='trainingData()'
                                           [extraInfo]='currentReimbursementAction === REIMBURSEMENT_ACTION_ENUM.edit ?
                                           editReimbursementExtraInfo : reimbursementExtraInfo'
                                           [reimbursement]='currentReimbursement'>
</alimento-ipv-frontend-reimbursement-popup>

<alimento-ipv-frontend-reimbursement-correction-popup #reimbursementCorrectionPopup
                                                      [parentReimbursement]='currentReimbursement'
                                                      [trainingData]='trainingData()'
                                                      [correction]='currentCorrection'
                                                      [extraInfo]='reimbursementCorrectionInfo'
                                                      (formSubmit)='reimbursementCorrectionSubmit($event, reimbursementCorrectionPopup)'
></alimento-ipv-frontend-reimbursement-correction-popup>

<alimento-ipv-frontend-reimbursement-action-dialog [reimbursement]='currentReimbursement'
                                                   [action]='currentReimbursementAction'
                                                   [selectedContactPersons]='selectedContactPersons'
                                                   (submitDialog)='executeReimbursementAction($event)'
></alimento-ipv-frontend-reimbursement-action-dialog>

<alimento-ipv-frontend-header title="{{ 'reimbursements.menuTitle' | translate }}">
  <div>
    <p-button
      *ngIf="!readOnly && reimbursementExtraInfo?.possibleReimbursements?.length > 0"
      (onClick)='addReimbursement(reimbursementPopupComponent)'
      icon="pi pi-plus"
      label="{{ 'reimbursements.create' | translate | uppercase }}"
    ></p-button>
  </div>
</alimento-ipv-frontend-header>

<div *ngIf='reimbursementExtraInfo?.possibleReimbursements?.includes(REIMBURSEMENT_TYPE.TRANSITION) ||
reimbursementExtraInfo?.possibleReimbursements?.includes(REIMBURSEMENT_TYPE.SME_BONUS)'
     class='mt-4 mb-2 p-4 bg-grey-0 border-l-4 border-l-primary flex flex-col items-start'>
  <span *ngIf='reimbursementExtraInfo.possibleReimbursements.includes(REIMBURSEMENT_TYPE.SME_BONUS)'>{{'reimbursements.canAddSMEBonus' | translate}}</span>
  <span *ngIf='reimbursementExtraInfo.possibleReimbursements?.includes(REIMBURSEMENT_TYPE.TRANSITION)'>{{'reimbursements.canAddTransition' | translate}}</span>
</div>

<div class='grid grid-cols-[300px_120px_120px_120px] gap-x-2 place-items-end mb-8' *ngIf='reimbursementData()'>
  <span></span>
  <span class='font-bold'>{{'reimbursements.reimbursement' | translate}}</span>
  <span class='font-bold'>{{'reimbursements.payed' | translate}}</span>
  <span></span>

  <span class='place-self-start'>{{'reimbursements.training' | translate}}</span>
  <span>{{ reimbursementData()[REIMBURSEMENT_TYPE.TRAINING].sum | currency:"EUR"}}</span>
  <span>{{ reimbursementData()[REIMBURSEMENT_TYPE.TRAINING].payed | currency:"EUR"}}</span>
  <span></span>

  <span class='place-self-start'>{{'reimbursements.smeBonus' | translate}}</span>
  <span>{{ reimbursementData()[REIMBURSEMENT_TYPE.SME_BONUS].sum | currency:"EUR"}}</span>
  <span>{{ reimbursementData()[REIMBURSEMENT_TYPE.SME_BONUS].payed | currency:"EUR"}}</span>
  <span></span>

  <span class='place-self-start'>{{'reimbursements.preparation' | translate}}</span>
  <span>{{ reimbursementData()[REIMBURSEMENT_TYPE.PREPARATION].sum | currency:"EUR"}}</span>
  <span>{{ reimbursementData()[REIMBURSEMENT_TYPE.PREPARATION].payed | currency:"EUR"}}</span>
  <span></span>

  <span class='place-self-start'>{{'reimbursements.transition' | translate}}</span>
  <span>{{ reimbursementData()[REIMBURSEMENT_TYPE.TRANSITION].sum | currency:"EUR"}}</span>
  <span>{{ reimbursementData()[REIMBURSEMENT_TYPE.TRANSITION].payed | currency:"EUR"}}</span>
  <span></span>

  <span class='col-span-3 h-4'></span>
  <span class='font-bold'>{{'reimbursements.cost' | translate}}</span>

  <span class='place-self-start'>{{'reimbursements.totalTrainingCost' | translate}}</span>
  <span>{{ (reimbursementData()[REIMBURSEMENT_TYPE.TRAINING].sum +
    reimbursementData()[REIMBURSEMENT_TYPE.SME_BONUS].sum) | currency:"EUR"}}</span>
  <span>{{ (reimbursementData()[REIMBURSEMENT_TYPE.TRAINING].payed +
    reimbursementData()[REIMBURSEMENT_TYPE.SME_BONUS].payed) | currency:"EUR"}}</span>
  <span>{{ trainingData().sumTotalAmount | currency:"EUR"}}</span>

  <span class='place-self-start'>{{'reimbursements.preparationCost' | translate}}</span>
  <span>{{ reimbursementData()[REIMBURSEMENT_TYPE.PREPARATION].sum | currency:"EUR"}}</span>
  <span>{{ reimbursementData()[REIMBURSEMENT_TYPE.PREPARATION].payed | currency:"EUR"}}</span>
  <span>{{ trainingData().sumPreparationAmount | currency:"EUR"}}</span>

  <span class='place-self-start font-bold'>{{'reimbursements.total' | translate}}</span>
  <span class='font-bold'>{{ (reimbursementData()[REIMBURSEMENT_TYPE.TRAINING].sum +
    reimbursementData()[REIMBURSEMENT_TYPE.PREPARATION].sum +
    reimbursementData()[REIMBURSEMENT_TYPE.TRANSITION].sum +
    reimbursementData()[REIMBURSEMENT_TYPE.SME_BONUS].sum) | currency:"EUR"}}</span>
  <span class='font-bold'>{{ (reimbursementData()[REIMBURSEMENT_TYPE.TRAINING].payed +
    reimbursementData()[REIMBURSEMENT_TYPE.PREPARATION].payed +
    reimbursementData()[REIMBURSEMENT_TYPE.TRANSITION].payed +
    reimbursementData()[REIMBURSEMENT_TYPE.SME_BONUS].payed) | currency:"EUR"}}</span>
  <span class='font-bold'>{{ (trainingData().sumTotalAmount +
    trainingData().sumPreparationAmount) | currency:"EUR"}}</span>
</div>


<div *ngIf='reimbursementData()' class='flex flex-col gap-8'>
  <ng-container *ngTemplateOutlet='reimbursementsTemplate,
  context: {reimbursements: getReimbursements(REIMBURSEMENT_TYPE.TRAINING), type: "trainingReimbursements"}'
  ></ng-container>
  <ng-container *ngTemplateOutlet='reimbursementsTemplate,
  context: {reimbursements: getReimbursements(REIMBURSEMENT_TYPE.PREPARATION), type: "preparationReimbursements"}'
  ></ng-container>
  <ng-container *ngTemplateOutlet='reimbursementsTemplate,
  context: {reimbursements: getReimbursements(REIMBURSEMENT_TYPE.SME_BONUS), type: "smeBonusReimbursements"}'
  ></ng-container>
  <ng-container *ngTemplateOutlet='reimbursementsTemplate,
  context: {reimbursements: transitionReimbursements(), type: "transitionReimbursements"}'
  ></ng-container>

</div>
<div *ngIf='reimbursements().length === 0'>{{ 'reimbursements.noReimbursements' | translate }}</div>

<ng-template #reimbursementsTemplate let-type="type" let-reimbursements="reimbursements">
  <div *ngIf='reimbursements?.length > 0'>
    <h3>{{("reimbursements." + type) | translate}}</h3>
    <div class='flex flex-col gap-2'>
      <div *ngFor='let reimbursement of reimbursements' class='border-b border-b-grey-1 last:border-0'>
        <alimento-ipv-frontend-reimbursement-card *ngIf='reimbursement.reimbursementTypeId !== REIMBURSEMENT_TYPE.CORRECTION'
          [reimbursement]='reimbursement'
          [readOnly]='readOnly'
          (actionClicked)='reimbursementActionClicked($event)'
          [corrections]='getCorrections(reimbursement.reimbursementId)'
        ></alimento-ipv-frontend-reimbursement-card>
      </div>
    </div>
  </div>
</ng-template>

<p-dialog
  (blur)="popupVisible = false"
  *ngIf='popupVisible'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '45rem' }"
  [closable]="false"
  appendTo="body"
  header="{{
    (accountNumber?.accountNumberId ? 'enterprises.accountNumbers.editAccountNumber' : 'enterprises.accountNumbers.new') | translate
  }}"
>
  <div *ngIf="accountNumber" [formGroup]="formGroup">
    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='true'
      [errorTemplate]='ibanErrorTemplate'
      formField='iban'
      fieldKey='enterprises.accountNumbers.iban'
    >
      <span class='read-only-field' *ngIf='accountNumber.accountNumberId'>{{accountNumber.iban | accountNumber}}</span>
      <input *ngIf='!accountNumber.accountNumberId'
             class='max-w-[204px]'
             formControlName='iban'
             id='iban'
             pInputText
             type='text' />

      <ng-template #ibanErrorTemplate>
        <small *ngIf="formGroup.get('iban').errors?.['IBANInvalid']">
          {{"validation.ibanInvalid" | translate }}
        </small>
        <small *ngIf="formGroup.get('iban').errors?.['required']">
          {{"validation.isRequired" | translate:{field: 'enterprises.accountNumbers.iban' | translate} }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <div class="grid grid-cols-2 gap-4 w-fit">
      <div class="w-[202px]">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='true'
          [errorTemplate]='dateErrorTemplate'
          formField='startDate'
          fieldKey='enterprises.accountNumbers.startDate'
        >
          <span *ngIf='!canChangeStartDate' class='read-only-field'>{{accountNumber.startDate | date: 'dd/MM/yyyy'}}</span>
          <lib-date-picker
            *ngIf='canChangeStartDate'
            formControlName="startDate"
            id="startDate"
          ></lib-date-picker>

          <ng-template #dateErrorTemplate>
            <small *ngIf='formGroup.get("startDate")?.hasError("required")'>
              {{ 'trainings.sessions.dateRequired' | translate }}
            </small>
            <small *ngIf='formGroup.get("startDate").hasError("invalidDate")'>
              {{ 'validation.invalidDate' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
      <div class="w-[202px]">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [errorTemplate]='dateErrorTemplate2'
          formField='endDate'
          fieldKey='enterprises.accountNumbers.endDate'
        >
          <lib-date-picker
            [showIcon]="true"
            [showOnFocus]='false'
            [keepInvalid]='true'
            formControlName="endDate"
            id="endDate"
          ></lib-date-picker>

          <ng-template #dateErrorTemplate2>
            <small *ngIf='formGroup.get("endDate")?.hasError("required")'>
              {{ 'trainings.sessions.dateRequired' | translate }}
            </small>
            <small *ngIf='formGroup.get("endDate").hasError("invalidDate")'>
              {{ 'validation.invalidDate' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>
    <small *ngIf="formGroup.get('endDate')?.hasError('minValueToLow')" class="p-error block">
      {{ 'enterprises.accountNumbers.endDateToLow' | translate }}
    </small>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='description'
      fieldKey='enterprises.accountNumbers.description'
    >
      <textarea
        class="w-full"
        formControlName="description"
        id="description"
        pInputTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>

    <div *ngIf='accountNumber.default && formGroup.get("endDate").value'
         class='my-4 p-4 border-l-4 border-l-warning bg-warning-bg text-warning flex flex-col'>
      <span>{{'enterprises.accountNumbers.defaultWithEndDate' | translate}}</span>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        *ngIf="accountNumber"
        [loading]="loading"
        (onClick)="addOrUpdate(false)"
        icon="pi pi-save"
        iconPos="left"
        label="{{ (accountNumber?.accountNumberId ? 'enterprises.accountNumbers.editAccountNumber' : 'enterprises.accountNumbers.create') | translate }}"
      ></p-button>
      <p-button
        *ngIf="showApproveButton()"
        [loading]="loading"
        (onClick)="addOrUpdate(true)"
        label="{{ 'enterprises.accountNumbers.approve' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closePopup()"
        icon="pi pi-times"
        iconPos="left"
        label="{{ 'enterprises.accountNumbers.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

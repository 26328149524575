<div *ngIf="session" class="group flex flex-col border-b border-b-grey-2 py-2">
  <div
    (click)="toggleShowExtraInfo()"
    class="flex gap-4 cursor-pointer group-hover:bg-primary group-hover:bg-opacity-[7%]"
  >
    <div>
      <div
        (keyup.enter)="toggleShowExtraInfo()"
        class="flex items-center p-2 m-2 text-primary outline-primary"
        tabindex="0"
      >
        <i class="pi pi-angle-{{ showExtraInfo ? 'up' : 'down' }}"></i>
      </div>
    </div>

    <div class="flex-grow mb-4">
      <div class="flex justify-between items-center mt-2 mr-2">
        <div
          class="flex items-center uppercase font-bold {{
            session.sessionCancelled ? 'text-grey-3 line-through' : 'text-title'
          }}"
        >
          <ng-container *ngIf="session.title || session.date; else titleWithoutDate">
            {{
              session.title || 'trainings.sessions.sessionOf'
                | translate : { date: (session.date | date : 'dd/MM/yyyy') }
            }}
          </ng-container>
          <ng-template #titleWithoutDate>
            {{ session.title || 'trainings.sessions.sessionNr' | translate : { number: index + 1 } }}
          </ng-template>
        </div>

        <div class="flex items-center gap-2">
          <ng-container *ngIf="session?.sessionCancelled" class="flex mr-2 items-center justify-center">
            <alimento-ipv-frontend-training-status-chip
              status="{{ cancelledStatus }}"
            ></alimento-ipv-frontend-training-status-chip>
          </ng-container>

          <div
            (click)="toggleAction(primaryAction.action, $event)"
            (keyup.enter)="toggleAction(primaryAction.action, $event)"
            *ngIf="primaryAction"
            class="p-button inverted-button h-8 w-8 bg-white justify-center hover:bg-primary focus:bg-primary hover:bg-opacity-[7%] focus:bg-opacity-[7%]"
            tabindex="0"
            [pTooltip]="primaryActionTooltip | translate"
          >
            <span class="material-symbols-outlined text-base">
              {{ primaryAction.icon }}
            </span>
          </div>

          <alimento-ipv-frontend-more-actions [actions]="actions"></alimento-ipv-frontend-more-actions>
        </div>
      </div>

      <div class="grid grid-cols-5">
        <ng-container *ngIf="trainingType !== TRAINING_TYPE.TEMPLATE; else showMethod">
          <ng-container *ngTemplateOutlet='LabelValueTemplate; context: {label: "trainings.sessions.day", value: session.date | date : "dd/MM/yyyy"}' ></ng-container>
        </ng-container>

        <ng-template #showMethod>
          <ng-container *ngTemplateOutlet='LabelValueTemplate; context: {label: "trainings.sessions.method", value: session.sessionMethod?.label}' ></ng-container>
        </ng-template>

        <ng-container *ngTemplateOutlet='LabelValueTemplate; context: {label: "trainings.sessions.start", value: session.startTime}' ></ng-container>
        <ng-container *ngTemplateOutlet='LabelValueTemplate; context: {label: "trainings.sessions.end", value: session.endTime}' ></ng-container>
        <ng-container *ngTemplateOutlet='LabelValueTemplate; context: {label: "trainings.sessions.duration", value: session.duration | duration}' ></ng-container>

        <div *ngIf="session.absenceRegistrationComplete || session.numberOfRegisteredAbsences > 0">
          <ng-container *ngTemplateOutlet='LabelValueTemplate; context: {label: "trainings.sessions.absences", value: session.numberOfRegisteredAbsences}' ></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showExtraInfo" class="session-extra-info grid grid-cols-2 bg-grey-0 mt-4 p-2 pl-12">
    <ng-container *ngIf="!session.sessionCancelled; else sessionCancelledInfo">
      <div class="grid grid-cols-2 gap-2 pl-4 border-r border-r-grey-2">
        <div class='flex flex-col gap-2'>
          <alimento-ipv-frontend-metadata-item *ngIf='session.sessionMethod?.label'
                                               [value]='session.sessionMethod.label'
                                               [title]='"trainings.sessions.method" | translate'
          ></alimento-ipv-frontend-metadata-item>
          <div class="teacher">

            <alimento-ipv-frontend-metadata-item
              [cardLayout]='true'
              title='{{ "trainings.sessions.teacher" | translate }}'
            >
              <div *ngFor='let branch of sessionTeachers' class='flex flex-col text-text text-[16px] whitespace-pre-line mb-1 last:mb-0'>
              <span [ngClass]="{ 'text-primary underline cursor-pointer': branch.link }"
                    [routerLink]='branch.link || []'>
                {{branch.label}}
              </span>
                <div class='ml-4 flex flex-wrap gap-2'>
                <span *ngFor='let teacher of (branch.teachers || [])'
                      [ngClass]="{ 'text-primary underline cursor-pointer': teacher.link }"
                      [routerLink]='teacher.link || []'>
                  {{teacher.label}}
                </span>
                </div>
              </div>
            </alimento-ipv-frontend-metadata-item>
          </div>
          <div class="location" *ngIf="methodHasLocation">

            <alimento-ipv-frontend-metadata-item
              [cardLayout]='true'
              title='{{ "trainings.sessions.location" | translate }}'
              value='{{session.location ? session.location.label : ("trainings.sessions.noLocation" | translate) }}'
              [link]='session.location ? ["/branches", session.location.data, "detail"] : undefined'
            ></alimento-ipv-frontend-metadata-item>
          </div>
        </div>
        <div class='flex flex-col gap-2'>
          <alimento-ipv-frontend-metadata-item *ngIf='session.siteVisitBranch?.label'
                                               [title]='"trainings.sessions.siteVisitBranch" | translate'
                                               [value]='session.siteVisitBranch?.label'
                                               [link]='session.siteVisitBranch ? ["/branches", session.siteVisitBranch.data, "detail"] : undefined'
          >
          </alimento-ipv-frontend-metadata-item>
          <alimento-ipv-frontend-metadata-item *ngIf='session.siteVisitEmployment?.branchId'
                                               [title]='"trainings.sessions.siteVisitEmployment" | translate'
                                               [value]='session.siteVisitEmployment?.lastName + " " + session.siteVisitEmployment?.firstName'
          >
          </alimento-ipv-frontend-metadata-item>
        </div>
      </div>

      <div class="right flex flex-col gap-2 pl-4">
        <div *ngIf="session.sessionComments">
          <div class="label text-grey-4">
            {{ 'trainings.sessions.comments' | translate }}
          </div>
          <div class="value text-text">
            {{ session.sessionComments }}
          </div>
        </div>

        <span *ngFor="let sessionOption of session.includedSessionOptions">
          <i class="pi pi-check"></i> {{ sessionOption.label }}
        </span>
      </div>
    </ng-container>
    <ng-template #sessionCancelledInfo>
      <div class="flex flex-col gap-2 pl-4 border-r border-r-grey-2">
        <div class="location">
          <div class="label text-grey-4">
            {{ 'trainings.cancelDialog.labels.reasonTrainingCancelled' | translate }}
          </div>
          <div class="value text-text">
            {{ session.reasonSessionCancelled?.label }}
          </div>
        </div>
      </div>
      <div class="right flex flex-col gap-2 pl-4">
        <div class="location" *ngIf="session.sessionCancelledComment">
          <div class="label text-grey-4">
            {{ 'trainings.cancelDialog.labels.remarks' | translate }}
          </div>
          <div class="value text-text">
            {{ session.sessionCancelledComment }}
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #LabelValueTemplate let-label="label" let-value="value" >
  <div class="flex flex-col">
  <span class="label {{ session.sessionCancelled ? 'text-grey-3' : 'text-grey-4' }}">
    {{ label | translate }}
  </span>
  <span class="value {{ session.sessionCancelled ? 'text-grey-3' : 'text-text' }}">
    {{ value }}
  </span>
  </div>
</ng-template>

import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';
import { delay, first, Observable } from 'rxjs';
import { NationalIdValidator } from '../../utils/national-id-validator';
import { DateMapper, dateValid, emailPattern, FormComponent } from '@alimento-ipv-frontend/ui-lib';
import { Person } from '../../../types/person.type';
import { EducationLevel, Gender, LanguageCode, Nationality } from '../../../types/reference-data.type';
import { PersonService } from '../../../services/person.service';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-person',
  templateUrl: './person.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PersonComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PersonComponent
    },
    { provide: FormComponent, useExisting: PersonComponent }
  ]
})
export class PersonComponent extends FormComponent implements ControlValueAccessor, Validator, OnChanges {
  @Input()
  person?: Person | undefined;

  @Input()
  showPersonalContactData = false;

  @Input()
  dataOptional = false;

  @Input()
  readOnly = false;

  @Input()
  disableUniqueValidation = false;

  @Output()
  personFormChanges = new EventEmitter<FormGroup>();

  maxDate = new Date();
  genders$: Observable<Gender[]> = this.referenceDataService.getGenders();
  educationLevels$: Observable<EducationLevel[]> = this.referenceDataService.getEducationLevels();
  nameAndBirthdayNotUnique = false;

  @ViewChild(FormComponent)
  addressComponent?: FormComponent;

  @ViewChild("focusElement")
  focusElement: ElementRef;

  readonly BELGIAN_NATIONALITY = 'BE';
  readonly PHONE_NUMBER_REGEX = '^[0-9+./ ]+$';

  languages: LanguageCode[];
  languageReadOnly: string;
  nationalities: Nationality[];
  nationalityReadOnly: string;

  constructor(
    private formBuilder: FormBuilder,
    private personService: PersonService,
    private referenceDataService: ReferenceDataService
  ) {
    super();
    this.createPersonFormGroup(this.person);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['person']?.currentValue !== changes['person']?.previousValue) {
      this.createPersonFormGroup(this.person);
    }

    if (changes['dataOptional']?.currentValue !== undefined ||
      changes['showPersonalContactData']?.currentValue !== undefined) {
      this.setOptionalFieldsRequired();
    }
  }

  override isValid(): boolean {
    if (this.readOnly) {
      return true;
    }

    const valid = super.isValid();
    return (!this.addressComponent || this.addressComponent.isValid()) && valid;
  }

  private createPersonFormGroup(person?: Person): void {
    const nationality = person?.nationality === undefined ? this.BELGIAN_NATIONALITY : person.nationality;
    this.formGroup = this.formBuilder.group({
      personId: [person?.personId],
      firstName: [person?.firstName],
      lastName: [person?.lastName, Validators.required],
      dateOfBirth: [person?.dateOfBirth ? new Date(person.dateOfBirth) : undefined, [dateValid()]],
      personAlimentoId: [person?.personAlimentoId],
      gender: [person?.gender?.data, Validators.required],
      language: [person?.language],
      nationality: [nationality],
      nationalIdentificationNumber: [person?.nationalIdentificationNumber],
      educationLevel: [person?.educationLevel?.data],
      phoneNumber: [person?.phoneNumber, Validators.pattern(this.PHONE_NUMBER_REGEX)],
      email: [person?.email, emailPattern()],
      address: [
        {
          street: person?.street || '',
          houseNumber: person?.houseNumber || '',
          mailbox: person?.mailbox || '',
          city: person?.city || '',
          postalCode: person?.postalCode || '',
          country: person?.country || ''
        }
      ]
    });

    this.subscriptions.forEach(subscription => subscription?.unsubscribe());
    this.subscriptions = [];

    this.subscriptions.push(
      this.formGroup.valueChanges.pipe(delay(1)).subscribe(() => {
        this.onChange(this.getData());
        this.onTouched();
        this.personFormChanges.emit(this.formGroup);
      })
    );

    ['firstName', 'lastName', 'dateOfBirth'].forEach((field) =>
      this.formGroup
        .get(field)
        .valueChanges.pipe(delay(1))
        .subscribe(() => {
          const value = this.formGroup.value;
          if (!value?.personId && value?.firstName && value?.lastName && value?.dateOfBirth && !this.disableUniqueValidation) {
            this.personService
              .isNameAndBirthdateUnique(value.firstName, value.lastName, value.dateOfBirth)
              .pipe(first())
              .subscribe((result) => (this.nameAndBirthdayNotUnique = result.isDuplicate));
          }
          else {
            this.nameAndBirthdayNotUnique = false;
          }
        })
    );

    this.setIdentificationNumberValidators(nationality);
    this.subscriptions.push(
      this.formGroup.get('nationality').valueChanges.subscribe((newValue) => {
        this.setIdentificationNumberValidators(newValue);
      })
    );

    this.setOptionalFieldsRequired();
    this._getOptionLists();
  }

  private _getOptionLists(): void {
    this.referenceDataService.getLanguageCodes().pipe(first()).subscribe(languages => {
      this.languages = languages;
      if (this.person?.language) {
        this.languageReadOnly = languages.filter(language => language.data === this.person.language)[0].label;
      }
    });

    this.referenceDataService.getNationalities().pipe(first()).subscribe(nationalities => {
      this.nationalities = nationalities;
      if (this.person?.nationality) {
        this.nationalityReadOnly = nationalities.filter(nationality => nationality.data === this.person.nationality)[0].label;
      }
    });
  }

  private setOptionalFieldsRequired(): void {
    const setValidators = (field: string, otherValidators: any[] = []) => {
      this.formGroup.get(field)?.setValidators(this.dataOptional ? otherValidators : [...otherValidators, Validators.required]);
      this.formGroup.get(field)?.updateValueAndValidity();
    };

    ['firstName', 'gender'].forEach((field) => {
      setValidators(field);
    });
    setValidators('dateOfBirth', [dateValid()]);

    if (this.showPersonalContactData) {
      setValidators('email', [emailPattern()]);
    }
    else {
      this.formGroup.get('email')?.setValidators([]);
      this.formGroup.get('email')?.updateValueAndValidity();
    }

    this.formGroup.updateValueAndValidity();
  }

  private setIdentificationNumberValidators(nationality: string | undefined): void {
    const nationalIdentificationNumber = this.formGroup.get('nationalIdentificationNumber');

    nationalIdentificationNumber.clearValidators();
    nationalIdentificationNumber.clearAsyncValidators();
    if (nationality === this.BELGIAN_NATIONALITY) {
      nationalIdentificationNumber.setAsyncValidators(NationalIdValidator.createValidator(this.personService, this.disableUniqueValidation));
    }

    nationalIdentificationNumber.updateValueAndValidity();
  }

  override getData(): Person {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));
    data.dateOfBirth = data.dateOfBirth ? DateMapper.getDateFromDateTimeAsString(data.dateOfBirth) : null;
    data.phoneNumber = data.phoneNumber?.replace(/[./\s]/g, '');

    if (data.address) {
      data.street = data.address.street;
      data.houseNumber = data.address.houseNumber;
      data.mailbox = data.address.mailbox;
      data.city = data.address.city;
      data.postalCode = data.address.postalCode;
      data.country = data.address.country;
      delete data.address;
    }

    return data;
  }

  override writeValue(person: Person | null): void {
    if (person) {
      this.formGroup.patchValue({
        personAlimentoId: person?.personAlimentoId,
        personId: person?.personId,
        firstName: person?.firstName,
        lastName: person?.lastName,
        dateOfBirth: person?.dateOfBirth ? new Date(person?.dateOfBirth) : null,
        gender: person?.gender?.data,
        language: person?.language,
        nationality: person?.nationality,
        nationalIdentificationNumber: person?.nationalIdentificationNumber,
        educationLevel: person?.educationLevel?.data,
        phoneNumber: person?.phoneNumber,
        email: person?.email,
        address: {
          street: person?.street || "",
          houseNumber: person?.houseNumber || "",
          mailbox: person?.mailbox || "",
          city: person?.city || "",
          postalCode: person?.postalCode || "",
          country: person?.country || ""
        }
      });
    }
    else {
      this.formGroup.reset({
        nationality: this.BELGIAN_NATIONALITY
      });

      this.formGroup.get('dateOfBirth')?.markAsUntouched();
    }
  }

  validate() {
    return this.formGroup.valid ? null : { personForm: { valid: false } };
  }

  get nationalIdentificationNumber() {
    return this.formGroup.get('nationalIdentificationNumber');
  }

  get dateOfBirth() {
    return this.formGroup.get('dateOfBirth');
  }

  get phoneNumber() {
    return this.formGroup.get('phoneNumber');
  }

  clearForm() {
    this.formGroup.reset({
      nationality: this.BELGIAN_NATIONALITY
    });

    this.formGroup.get('dateOfBirth')?.markAsUntouched();
  }

  override setFocus(): void {
    setTimeout(() => this.focusElement?.nativeElement?.focus());
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root"
})
export class GlobalMapper {

  setEditedBy(item: any): any {
    if (item.createdBy && !item.editedBy) {
      item.editedBy = item.createdBy;
      item.editedOn = item.createdOn;
      item.editedById = item.createdById;
    }
    return item;
  }

  static getAddress(street: string, houseNumber: string, mailbox: string, postalCode: string, city: string): string {
    return `${street ? street : ''} ${houseNumber ? houseNumber : ''}${mailbox ? ' ' + mailbox : ''}, ` +
      `${postalCode ? postalCode : ''} ${city ? city : ''}`;
  }
}

import { InjectionToken } from '@angular/core';

export type Note = {
  id: string;
  content: string;
  createdBy: string;
  createdById?: string;
  createdOn: Date;
  editedBy?: string;
  editedById?: string;
  editedOn?: Date;
}

export type NoteUpdateEvent = {
  note: Note;
  setLoading: (state: boolean) => void;
};

export type DataLabelType = {
  data: string;
  label: string;
};

export type HistoryItem = {
  id: string,
  actionId?: any,
  actionName: any;
  historyType: string,
  actionExecutedById?: string,
  actionExecutedBy: string;
  actionExecutedOn: Date,
  comments: string,
  data?: any
}

export type IFile = {
  id: string,
  fileName: string;
  loading: boolean
  data?: any;
  url?: string;
}

export interface SelectionChangedEvent {
  item: any,
  selected: boolean
}

export interface AppConfig {
  readApiUrl: string;
  writeApiUrl: string;
}

export const APP_CONFIG_TOKEN = new InjectionToken<AppConfig>('app.config');
